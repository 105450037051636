import { lazy, useContext } from "react";

const HistoriqueDetail = lazy(() => import("./HistoriqueDetail"));

const historiqueRoutes = [
    {
      path: "/historique/edit/:id",
      component: HistoriqueDetail
    },
  ];
  
  export default historiqueRoutes;