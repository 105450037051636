export const articleListErp = [
    {dataField:'name',text:'tableName',formatter:false,editable:true,editType:'input',sort:true, allowAccessName:'article_name_@!'},
    {dataField:'sku',text:'SKU',formatter:false,editable:true,editType:'input',sort:true, allowAccessName:'article_sku_@!'},
    {dataField:'parent_name',text:'parent_name',formatter:false,editable:true,editType:'input',sort:true, allowAccessName:'article_parentname_@!'},
    {dataField:'reference',text:'reference',formatter:false,editable:true,editType:'input',sort:true, allowAccessName:'article_reference_@!'},
    {dataField:'reference2',text:'reference2',formatter:false,editable:true,editType:'input',sort:true, allowAccessName:'article_reference2_@!'},
    // {dataField:'pr',text:'pr',formatter:'price',editable:true,editType:'input',sort:false, allowAccessName:'article_pr_@!'},
    {dataField:'prmp',text:'prmp',formatter:'price',editable:true,editType:'input',sort:false, allowAccessName:'article_prmp_@!'},
    {dataField:'ht',text:'priceHtB2c',formatter:'price',editable:false,editType:false,sort:false, allowAccessName:'article_priceht_@!'},
    {dataField:'price_b2b',text:'priceHtB2b',formatter:'price',editable:true,editType:'input',sort:false, allowAccessName:'article_priceb2b_@!'},
    {dataField:'margeN',text:'margeB2c',formatter:'price',editable:false,editType:false,sort:false, allowAccessName:'article_margenet_@!'},
    {dataField:'margeP',text:'margePB2c',formatter:'percent',editable:false,editType:false,sort:false, allowAccessName:'article_margepercent_@!'},
    {dataField:'margeNB2b',text:'margeNB2b',formatter:'price',editable:false,editType:false,sort:false, allowAccessName:'article_margeb2b_@!'},
    {dataField:'margePB2b',text:'margePB2b',formatter:'percent',editable:false,editType:false,sort:false, allowAccessName:'article_margepercentb2b_@!'},
    {dataField:'price',text:'priceTtcB2c',formatter:'price',editable:true,editType:'input',sort:true, allowAccessName:'article_pricettc_@!'},
    {dataField:'ttcb2b',text:'priceTtcB2b',formatter:'price',editable:false,editType:false,sort:true, allowAccessName:'article_pricettcb2b_@!'},
    {dataField:'tgc',text:'tgc',formatter:'percent',editable:true,editType:'input',sort:true, allowAccessName:'article_tgc_@!'},
    {dataField:'fournisseur',text:'fournisseur',formatter:false,editable:true,editType:'select',sort:true, allowAccessName:'article_fournisseur_@!'},
    {dataField:'marque',text:'marque',formatter:false,editable:true,editType:'select',sort:true, allowAccessName:'article_marque_@!'},
    {dataField:'firstlevelcat',text:'Classification',formatter:false,editable:true,editType:'select',sort:true, allowAccessName:'article_firstlevelcat_@!'},
    {dataField:'secondlevelcat',text:'secondlevelcat',formatter:false,editable:true,editType:'select',sort:true, allowAccessName:'article_secondlevelcat_@!'},
    {dataField:'thirdlevelcat',text:'thirdlevelcat',formatter:false,editable:true,editType:'select',sort:true, allowAccessName:'article_thirdlevelcat_@!'},
    {dataField:'fourthlevelcat',text:'fourthlevelcat',formatter:false,editable:true,editType:'select',sort:true, allowAccessName:'article_fourthlevelcat_@!'},
    {dataField:'fifthlevelcat',text:'fifthlevelcat',formatter:false,editable:true,editType:'select',sort:true, allowAccessName:'article_fifthlevelcat_@!'},
    {dataField:'image',text:'Image',formatter:false,editable:false,editType:false,sort:true, allowAccessName:'article_image_@!'},
    {dataField:'havestock',text:'itemHaveStock',formatter:false,editable:true,editType:'select',sort:false, allowAccessName:'article_havestock_@!'},
    {dataField:'provisoire',text:'temporary',formatter:false,editable:false,editType:false,sort:false, allowAccessName:'article_provisoire_@!'},
    {dataField:'categorie_douane',text:'categorie_douane',formatter:false,editable:true,editType:'input',sort:false, allowAccessName:'article_categorie_douane_@!'},
    {dataField:'ecotaxe',text:'ecotaxe',formatter:'price',editable:true,editType:'input',sort:false, allowAccessName:'article_ecotaxe_@!'},
    {dataField:'last_pr',text:'last_pr',formatter:'price',editable:true,editType:'input',sort:false, allowAccessName:'article_last_pr_@!'},
    {dataField:'last_date_buy',text:'last_date_buy',formatter:'date',editable:false,editType:false,sort:false, allowAccessName:'article_last_date_buy_@!'},
];