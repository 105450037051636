import React,{useState,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { Modal, Button} from "react-bootstrap";
import axios from 'axios'
import swal from "sweetalert2";
import {constructionDocument} from "@gull";

const NoteModal = (props) =>{
    const t =useTranslate()
    const [validButton,setValidButton]=useState(false)
    const [noteValue,setNoteValue]=useState('')
    const idsLocalStorage=(props.module=='pos' ) ? localStorage.getItem("pos").split(',') : [0,0]

    useEffect(function () {
        if(props.module=='facturation'){
            if(props.item.description!=null){
                setNoteValue(props.item.description)
            }
        }else{
            if(props.item.note!=null){
                setNoteValue(props.item.note)
            }
        }
    }, [props.item])

    const handleChange = (e) =>{
        setNoteValue(e.target.value)
    }

    const callBackConstruct = (response) => {
        props.closeModal('note')
    };

    const handleSubmit = async (e) =>{
        e.preventDefault();

        if(props.module=='pos'){
            setValidButton(true)
            const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0], {
                id:props.cookies.current_ticket,
                detail_id:props.lineId,
                note:noteValue
            }).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                }
                else{   
                    props.setCurrentTicket(response.data.data)
                    props.closeModal('note')
                    setValidButton(false)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            })
            setValidButton(false)
        }else{
            let data = {
                description:noteValue,
                detail_id:props.item.id
            }
            constructionDocument(setValidButton,props.id,callBackConstruct,t,data,props.setItemsBilling,props.setAllInfos)
        }
    }

    const convertHtmlToText = (html) => {
        return html.replaceAll('<br />', '').replaceAll('<br>', '');
    };

    return(
        <Modal size="md" show={props.showNote} onHide={() => props.closeModal('note')}>
            <Modal.Header closeButton>
                <Modal.Title>{t('addNote')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={(e)=>handleSubmit(e)}>
                    <div className="row">
                        <div className="col">
                            <textarea className="form-control" value={convertHtmlToText(noteValue)} rows="8" onChange={(e)=>handleChange(e)}></textarea>
                        </div>
                    </div>
                    <button disabled={validButton} className="btn btn-primary mt-3">
                        {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default NoteModal;