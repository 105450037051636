import React from "react";
import { useTranslate } from 'react-redux-multilingual';

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKeyboard } from '@fortawesome/free-solid-svg-icons';

const KeyBoardBtn = (props) => {
    const t = useTranslate();

    let styles = (props.state=='none') ? "fs-4 pointer" : "fs-4 pointer text-warning"

    return (
        <div className="input-group-prepend" onClick={()=>props.actionClavier()}>
            <div className="input-group-text rounded-0" >
                <FontAwesomeIcon 
                    icon={faKeyboard} 
                    className={styles}
                /> 
            </div>
        </div>
    )
}

export default KeyBoardBtn;