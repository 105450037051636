import React, {useState,lazy,Suspense} from 'react';
import {Loading} from '@gull'
import { useTranslate } from 'react-redux-multilingual'
import { Modal } from "react-bootstrap";

const IndiceCalculationForm = lazy(() => import('../form/IndiceCalculationForm'));

const BtnIndiceCalculation = (props) => {
    const t = useTranslate();
    const [show,setShow] = useState(false)

    return(
        <>
            <Modal size='lg' show={show} onHide={()=>setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('indiceCalcul')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Suspense fallback={<Loading></Loading>} >
                        <IndiceCalculationForm 
                            allDevises={props.allDevises} 
                            allCustomsCategories={props.allCustomsCategories} 
                            poId={props.poId} 
                            fournisseur={props.fournisseur} 
                            setReload={props.setReload}
                            setShow={setShow}
                            po={props.po}
                        />
                    </Suspense>
                </Modal.Body>
            </Modal>
            <button className='btn btn-sm btn-warning' onClick={()=>setShow(true)}>{t('indiceCalcul')}</button>
        </>
    )
}

export default BtnIndiceCalculation;