import React, {useState,useEffect,useContext} from "react";
import { IsAuthContext } from 'app/App';
import { useTranslate } from 'react-redux-multilingual'
import { useForm } from "react-hook-form";
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBirthdayCake } from '@fortawesome/free-solid-svg-icons'
import {Form} from 'react-bootstrap'

const useLoadCompany = (clientid,setValue) => {
    const { authParam } = useContext(IsAuthContext);
    const [loading,setLoading]=useState(true)
    const [error,setError]=useState(false)
    const [clientForm,setClientForm]=useState(false)
    const [company,setCompany]=useState(false)
    const [exonerationTaxe,setExonerationTaxe]=useState(false) 

    useEffect(function () {
        (async function() {
            if(!clientid){
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/company/"+authParam.company.id
                ).then((response) => {
                    setCompany(response.data)
                    setLoading(false)
                    setClientForm(response.data.clientform)
                }).catch((error) => {
                    if(error=='end_session') return;
                    setLoading(false)
                    setError(true)
                })
            }
            else{
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/client/"+clientid
                ).then((response) => {
                    setCompany(response.data.company)
                    setLoading(false)
                    setClientForm(response.data.company.clientform)
                    setValue('name',response.data.name)
                    setValue('firstname',response.data.firstname)
                    setValue('email',response.data.email)
                    setValue('tel',response.data.tel)
                    setValue('type',response.data.type)
                    if(response.data.exoneration_tgc!=0){
                        setExonerationTaxe(true)
                    }else{
                        setExonerationTaxe(false)
                    }
                    
                    if(response.data.infos){
                        let keys = Object.keys(response.data.infos);
                        for(var i = 0; i < keys.length; i++){
                            setValue(keys[i],response.data.infos[keys[i]])
                        }
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    setLoading(false)
                    setError(true)
                })
            }
        })()
    }, [])
    return [loading,error,clientForm,company,exonerationTaxe,setExonerationTaxe]
}

const ClientForm = (props) => {
    const t = useTranslate()
    const { register, formState: { errors }, handleSubmit, setValue } = useForm();
    const [loading,error,clientForm,company,exonerationTaxe,setExonerationTaxe]=useLoadCompany(props.clientid,setValue);
    const [validButton,setValidButton] = useState(false)
    const [apiError,setApiError] = useState(false)
    const [maxPhoneLength, setMaxPhoneLength] = useState({
        tel: false,
        autretel:false
    })

    const [showClientForm,setShowClientForm] = useState(false)
    const [formFieldCustomer,setFormFieldCustomer] = useState({})
    const formField = ["civilite","firstname","datedenaissance","email","mail_relance","tel","autretel","mode_reglement","newsletter","adresse","quartier","ville","pays","codepostal","note",]

    useEffect(function () {
        let indexShow = (props.b2b) ? 2 : 0
        let indexRequired = (props.b2b) ? 3 : 1

        if(clientForm){
            let obj ={
                civilite:{show:false, required:false},
                firstname:{show:true, required:false},
                datedenaissance:{show:false, required:false},
                email:{show:true, required:false},
                mail_relance:{show:false, required:false},
                tel:{show:true, required:false},
                autretel:{show:false, required:false},
                mode_reglement:{show:false, required:false},
                newsletter:{show:false, required:false},
                adresse:{show:false, required:false},
                quartier:{show:false, required:false},
                ville:{show:false, required:false},
                pays:{show:false, required:false},
                codepostal:{show:false, required:false},
                note:{show:false, required:false},
            }
            if(props.module && props.module=='erp'){
                !Array.isArray(clientForm) && formField.map(key=>{
                    if(clientForm.hasOwnProperty(key)){
                        let erpShow = (clientForm[key][0] || clientForm[key][2]) ? true : false //Pour l'erp si au moins 1 conf entre b2c ou b2b a l'affichage sélectionné, on l'affiche
                        let erpRequired = (clientForm[key][1] && clientForm[key][3]) ? true : false //Pour le champs required, on met le champ required seulement si sur la configuration il est requis pour le b2c et b2b
                        obj[key] = {show:erpShow, required:erpRequired}
                    }
                })
                setShowClientForm(true)
            }else{
                !Array.isArray(clientForm) && formField.map(key=>{
                    if(clientForm.hasOwnProperty(key)){
                        obj[key] = {show:clientForm[key][indexShow], required:clientForm[key][indexRequired]}
                    }
                })
                setShowClientForm(true)
            }
            setFormFieldCustomer(obj)
        }else{
            setShowClientForm(false)
        }
    }, [clientForm])
    
    const onSubmit = async (data) => {
        setValidButton(true)
        setApiError(false)

        let obj = {}
        Object.entries(data).map(([key,value])=>{
            obj[key]=value
        })
        obj['exoneration']=exonerationTaxe

        const response  = await axios.post(process.env.REACT_APP_API_URL+"api/client", obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                setApiError(messageError)
            }
            else{
                if(props.setListSearchIdClient){
                    props.setListSearchIdClient({[response.data.data.name+' '+response.data.data.firstname+' - '+response.data.data.email+' - '+response.data.data.tel]:response.data.data.id})
                }
                if( props.setClientNameInput){
                    props.setClientNameInput(response.data.data.name+' '+response.data.data.firstname+' - '+response.data.data.email+' - '+response.data.data.tel)
                }
                if(props.setInvalidInput){
                    props.setInvalidInput({...props.invalidInput,searchClient:false})
                }
                if(props.setValue){
                    props.setValue(response.data.data)
                }
                if(props.from == 'barLeft'){
                    if(props.setClientId){
                        props.setClientId(response.data.data.id)
                    }
                    if(props.cookies && props.cookies.current_ticket){
                        props.updateCurentTicket('searchClient',response.data.data.id)
                    }
                    if(props.otherModule && props.otherModule=='erp'){
                        props.updateCurentTicket('searchClient',response.data.data.id)
                    }
                } 
                if(props.clientid){
                    if(props.closeModal) props.closeModal('edit') 
                }else{
                    if(props.closeModal) props.closeModal('add') 
                }   
                if(props.module && props.module=='facturation'){
                    if(props.setClientDetail) props.setClientDetail(response.data.data)
                }
                if(props.module && props.module=='facturationEdit'){
                    if(props.editPlusFacture) props.editPlusFacture({client_id:response.data.data.id})
                }
                if(props.callBackClientForm) props.callBackClientForm(response.data.data) //Si cette fonction est passée en props, elle exécutera toutes les actions apres création du client 
            }
        }).catch((error) => {
            if(error=='end_session') return;
            if(error.response==undefined){
                setApiError(t('unknowError'))
            }
            else if(error.response.status==403){
                setApiError(t('forbiddenGeneral'))
            }
            else {
                setApiError(t('unknowError'))
            }
        })
        setValidButton(false)
    }

    console.log('formFieldCustomer',formFieldCustomer);

    const handleChange = (e,name) => {
        setValue(name,e.target.value.replace(/\D/g,''));
    }

    const handleChangePhonenumber = (e,field) => {
        setMaxPhoneLength({...maxPhoneLength,[field]: e.target.value.length > 6})
        setValue(field,e.target.value.replace(/\D/g,'').substring(0,6));
    }

    if(loading){
        return (
            <div className="col-12 text-center">
                <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm mt-2"></div></span>
            </div>
        );
    }
    if(error){
        return (
            <div className="col-12 text-center">
                <p className="text-danger"><strong>{t('unknowError')}</strong></p>
            </div>
        )
    }

    return(<>
        {(!showClientForm) ?
            <div className="col-12 text-center">
                <p className="text-danger">{t('noFormClient')}</p>
            </div>
            :
            <div className="col-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                    {(props.clientid) &&
                        <input type="hidden" defaultValue={props.clientid} {...register('clientid')} />
                    }
                    <div className="row">
                        <div className="col-6 d-flex flex-column">
                            <div className="row">
                                {(formFieldCustomer.civilite.show) && <>
                                    <div className="form-group mb-2 input-group">
                                        <div className="row">
                                            <div className="col-4">
                                                <label>{t('civilite')}{(formFieldCustomer.civilite.required) && '*'} :</label>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" value="femme" {...register('civilite',{required: formFieldCustomer.civilite.required})}/>
                                                    <label className="form-check-label">{t('femme')}</label>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" value="homme" {...register('civilite',{required: formFieldCustomer.civilite.required})}/>
                                                    <label className="form-check-label">{t('homme')}</label>
                                                </div>
                                            </div>
                                            {errors.civilite?.type === 'required' && (
                                            <div className="text-danger mt-1 ml-2">
                                                {t('civiliteRequired')}
                                            </div>
                                            )}
                                        </div>
                                    </div>
                                </>}
                            </div>
                            {(props.module && props.module=='facturation' || props.module && props.module=='facturationEdit') &&
                                <div className="row">
                                    <div className="form-group mb-2 input-group">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" disabled={props.module && props.module=='facturationEdit'} checked={exonerationTaxe} type="checkbox" role="switch" onChange={() => setExonerationTaxe(!exonerationTaxe)}/>
                                            <label className="form-check-label" >{t('exonerateFromTaxes')}</label>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="form-group mb-2">
                                    <label>{t('tableName')+' '+t('or').toLowerCase()+' '+t('company').toLowerCase()+'*'}</label>
                                    <input type="text" placeholder={t('tableName')+' '+t('or').toLowerCase()+' '+t('company').toLowerCase()+'...*'} className="form-control" {...register('name',{required: true,minLength:2,maxLength:191})}/>
                                    {errors.name?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('fieldRequired')}
                                    </div>
                                    )}
                                    {errors.name?.type === 'minLength' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('nameMin2')}
                                    </div>
                                    )}
                                    {errors.name?.type === 'maxLength' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('nameMax191')}
                                    </div>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-2">
                                    <label>{t('tableFirstName')}{(formFieldCustomer.firstname.required) ? '*' : ''} :</label>
                                    <input type="text" placeholder={t('tableFirstName')+'...'} className="form-control" {...register('firstname',{required: (formFieldCustomer.firstname.required) ? true : false,minLength:2,maxLength:191})}/>
                                    {errors.firstname?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('firstnameRequired')}
                                    </div>
                                    )}
                                    {errors.firstname?.type === 'minLength' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('firstnameMin2')}
                                    </div>
                                    )}
                                    {errors.firstname?.type === 'maxLength' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('firstnameMax191')}
                                    </div>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                {(formFieldCustomer.datedenaissance.show) &&
                                    <div className="form-group mb-2 input-group">
                                        <div className="input-group ">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text rounded-0" style={{height:'100%'}}><FontAwesomeIcon icon={faBirthdayCake} /></div>
                                            </div>
                                            <input type="date" placeholder={(formFieldCustomer.datedenaissance.required) ? t('dateDeNaissance')+'...*' : t('dateDeNaissance')+'...'} className="form-control" {...register('datedenaissance',{required: formFieldCustomer.datedenaissance.required})}/>
                                        </div>
                                        {errors.datedenaissance?.type === 'required' && (
                                        <div className="text-danger mt-1 ml-2">
                                            {t('dateDeNaissanceRequired')}
                                        </div>
                                        )}
                                    </div>
                                }
                            </div>
                            <div className="row">
                                <div className="form-group mb-2">
                                    <label>Email{(formFieldCustomer.email.required) ? '*' : ''} :</label>
                                    <input type="email" placeholder="Email..." className="form-control" {...register('email',{required: (formFieldCustomer.email.required) ? true : false})}/>
                                    {errors.email?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('emailRequired')}
                                    </div>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                {(formFieldCustomer.mail_relance.show) &&
                                    <div className="form-group mb-2">
                                        <label>{t('labelMailrelance')}{(formFieldCustomer.mail_relance.required) ? '*' : ''} {'('+t('emailsCumul')+')'}:</label>
                                        <textarea rows="2" placeholder={(formFieldCustomer.mail_relance.required) ? t('labelMailrelance')+'...*' : t('labelMailrelance')+'...'} className="form-control" {...register('mail_relance',{required: (formFieldCustomer.mail_relance.required) ? true : false})}/>
                                        {errors.mail_relance?.type === 'required' && (
                                        <div className="text-danger mt-1 ml-2">
                                            {t('mailRelanceRequired')}
                                        </div>
                                        )}
                                    </div>
                                }
                            </div>
                            <div className="row">
                                <div className="form-group mb-2">
                                    <label>{t('tablePhone')}{(formFieldCustomer.tel.required) ? '*' : ''} :</label><br />
                                    <span className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text rounded-0" style={{height:'100%'}}>+687{(formFieldCustomer.tel.required) ? '*' : ''}</div>
                                        </div>
                                        <input type="text" placeholder={t('tablePhone')+'...'} className="form-control" {...register('tel', {required: (formFieldCustomer.tel.required) ? true : false, onChange :(e) => handleChangePhonenumber(e,'tel')})} />
                                    </span>
                                    {errors.tel?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('fieldRequired')}
                                    </div>
                                    )}
                                    {(maxPhoneLength.tel) && 
                                        <div className="text-danger mt-1 ml-2">
                                            {t('maxPhoneLength6')}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                {(formFieldCustomer.autretel.show) &&
                                    <>
                                        
                                        <div className="form-group mb-2">
                                            <label>{t('tableOtherPhone')}{(formFieldCustomer.autretel.required) ? '*' : ''} :</label><br />
                                            <span className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text rounded-0" style={{height:'100%'}}>+687</div>
                                                </div>
                                                <input type="text" placeholder={(formFieldCustomer.autretel.required) ? t('tableOtherPhone')+'...*' : t('tableOtherPhone')+'...'} className="form-control" {...register('autretel',{required: formFieldCustomer.autretel.required, onChange :(e) => handleChangePhonenumber(e,'autretel')})} />
                                            </span>
                                            {errors.autretel?.type === 'required' && (
                                            <div className="text-danger mt-1 ml-2">
                                                {t('autretelRequired')}
                                            </div>
                                            )}
                                        </div>
                                    </>
                                }
                                {(maxPhoneLength.autretel) && 
                                    <div className="text-danger mt-1 ml-2">
                                        {t('maxPhoneLength6')}
                                    </div>
                                }
                            </div>
                            <div className="row">
                                {(formFieldCustomer.mode_reglement.show) &&
                                    <>
                                        
                                        <div className="form-group mb-2">
                                            <label>{t('labelDelaiReglement')}{(formFieldCustomer.mode_reglement.required) ? '*' : ''} :</label>
                                            <select className="form-control" {...register('mode_reglement',{required: (formFieldCustomer.mode_reglement.required) ? true : false})}>
                                                <option value="">-- Select --</option>
                                                <option value="comptant">{t('cashPayment')}</option>
                                                <option value="7days">{t('days7Payment')}</option>
                                                <option value="15days">{t('days15Payment')}</option>
                                                <option value="30days">{t('days30Payment')}</option>
                                                <option value="45days">{t('days45Payment')}</option>
                                                <option value="60days">{t('days60Payment')}</option>
                                                <option value="90days">{t('days90Payment')}</option>
                                            </select>
                                            {/* <input type="number" min="0" placeholder={(clientForm.mode_reglement[b2cOrB2b.required]) ? t('labelDelaiReglement')+'...*' : t('labelDelaiReglement')+'...'} className="form-control" {...register('mode_reglement',{required: (clientForm.mode_reglement && clientForm.mode_reglement[b2cOrB2b.required]) ? true : false})}/>
                                            <div className="input-group-prepend">
                                                <div className="input-group-text rounded-0" style={{height:'100%'}}>jours</div>
                                            </div> */}
                                            {errors.mode_reglement?.type === 'required' && (
                                            <div className="text-danger mt-1 ml-2">
                                                {t('delaiReglementRequired')}
                                            </div>
                                            )}
                                        </div>
                                    </>
                                }
                            </div>
                            {(formFieldCustomer.newsletter.show) &&
                                <Form>
                                    <Form.Check
                                        type="checkbox"
                                        label={(formFieldCustomer.newsletter.required) ? t('inscriptionNewsletter')+'*' : t('inscriptionNewsletter')}
                                        {...register('newsletter',{required: formFieldCustomer.newsletter.required})}
                                    />
                                </Form>
                            }
                           
                        </div>
                        <div className="col-6 d-flex flex-column">
                            <div className="col">
                                <div className='form-group mb-2 input-group'>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text rounded-0" style={{height:'100%'}}>Type</div>
                                    </div>
                                    <select className="form-control" type="text" {...register('type',{required: true})}>
                                        <option value="Local">Local</option>
                                        {(company.clienttype!=null) ? 
                                            company.clienttype.map((clienttype,index)=>
                                                <option key={index} value={clienttype}>{clienttype}</option>
                                            )
                                            :
                                            false
                                        }
                                    </select>
                                </div>
                                
                                {errors.type?.type === 'required' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('typeRequired')}
                                </div>
                                )}
                            </div>
                            {(formFieldCustomer.adresse.show) &&
                                <div className="col">
                                    <div className="form-group mb-2">
                                        <label>{(formFieldCustomer.adresse.required) ? t('labelAdress')+'*' : t('labelAdress')}</label>
                                        <textarea rows={5} placeholder={(formFieldCustomer.adresse.required) ? t('labelAdress')+'...*' : t('labelAdress')+'...'} className="form-control" {...register('adresse',{required: formFieldCustomer.adresse.required})}/>
                                        {errors.adresse?.type === 'required' && (
                                        <div className="text-danger mt-1 ml-2">
                                            {t('adresseRequired')}
                                        </div>
                                        )}
                                    </div>
                                </div>
                            }
                            {(formFieldCustomer.quartier.show) &&
                                <div className="col">
                                    <div className="form-group mb-2">
                                        <label>{(formFieldCustomer.quartier.required) ? t('quartier')+'*' : t('quartier')}</label>
                                        <input type="text" placeholder={(formFieldCustomer.quartier.required) ? t('quartier')+'...*' : t('quartier')+'...'} className="form-control" {...register('quartier',{required: formFieldCustomer.quartier.required})}/>
                                        {errors.quartier?.type === 'required' && (
                                        <div className="text-danger mt-1 ml-2">
                                            {t('quartierRequired')}
                                        </div>
                                        )}
                                    </div>
                                </div>
                            }
                            {(formFieldCustomer.ville.show) &&
                                <div className="col">
                                    <div className="form-group mb-2">
                                        <label>{(formFieldCustomer.ville.required) ? t('labelVille')+'*' : t('labelVille')}</label>
                                        <input type="text" placeholder={(formFieldCustomer.ville.required) ? t('labelVille')+'...*' : t('labelVille')+'...'} className="form-control" {...register('ville',{required: formFieldCustomer.ville.required})}/>
                                        {errors.ville?.type === 'required' && (
                                        <div className="text-danger mt-1 ml-2">
                                            {t('villeRequired')}
                                        </div>
                                        )}
                                    </div>
                                </div>
                            }
                            {(formFieldCustomer.pays.show) &&
                                <div className="col">
                                    <div className="form-group mb-2">
                                        <label>{(formFieldCustomer.pays.required) ? t('labelPays')+'*' : t('labelPays')}</label>
                                        <input type="text" placeholder={(formFieldCustomer.pays.required) ? t('labelPays')+'...*' : t('labelPays')+'...'} className="form-control" {...register('pays',{required: formFieldCustomer.pays.required})}/>
                                        {errors.pays?.type === 'required' && (
                                        <div className="text-danger mt-1 ml-2">
                                            {t('paysRequired')}
                                        </div>
                                        )}
                                    </div>
                                </div>
                            }
                            {(formFieldCustomer.codepostal.show) &&
                                <div className="col">
                                    <div className="form-group mb-2">
                                        <label>{(formFieldCustomer.codepostal.required) ? t('labelCodePostal')+'*' : t('labelCodePostal')}</label>
                                        <input type="text" placeholder={(formFieldCustomer.codepostal.required) ? t('labelCodePostal')+'...*' : t('labelCodePostal')+'...'} className="form-control" {...register('codepostal',{required: formFieldCustomer.codepostal.required, onChange :(e) => handleChangePhonenumber(e,'codepostal')})}/>
                                        {errors.codepostal?.type === 'required' && (
                                        <div className="text-danger mt-1 ml-2">
                                            {t('codePostalRequired')}
                                        </div>
                                        )}
                                    </div>
                                </div>
                            }
                            {(formFieldCustomer.note.show) &&
                                <div className="row">
                                    <div className="form-group mb-2">
                                        <label>Note{(formFieldCustomer.note.required) ? '*' : ''} :</label>
                                        <textarea rows="8" placeholder={(formFieldCustomer.note.required) ? 'Note*...' : 'Note...'} className="form-control" {...register('note',{required: (formFieldCustomer.note.required) ? true : false})}/>
                                        {errors.note?.type === 'required' && (
                                        <div className="text-danger mt-1 ml-2">
                                            {t('noteRequired')}
                                        </div>
                                        )}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {(apiError) ? <div className="alert alert-danger mt-3" dangerouslySetInnerHTML={{ __html: apiError }}></div> : false}
                    <button disabled={validButton} className="btn btn-primary mt-3">
                        {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                    </button>
                </form> 
            </div>  
        }
    </>)
}

export default ClientForm