import React, {useState,useEffect,useRef} from 'react';
import { CardPerso } from '@gull';
import { useTranslate } from 'react-redux-multilingual'
import SignatureCanvas from 'react-signature-canvas'
import { PDFViewer, Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer';

const SignatureForm = (props) => {
    const t = useTranslate();
    const sigRef = useRef('')
    const [disabled,setDisabled] = useState(false)
    const [signatureStart,setSignatureStart] = useState(false)

    useEffect(() => {
        // Charger la signature par défaut au montage du composant
        if(props.signatureImg!=null){
            if (sigRef.current) {
                sigRef.current.fromDataURL(props.signatureImg);
            }
        }

    }, [props.signatureImg]);

    const handleClick = (isFor) => {
        if(isFor=='clear'){
            if(sigRef.current) sigRef.current.clear()

        }else if(isFor=='save'){
            if(sigRef.current){ 
                props.submitSignature(sigRef.current.toDataURL())
            }

        }else if(isFor=='reset'){
            props.deleteSignature()
            if(sigRef.current) sigRef.current.clear()
        }
    };

    const handleEndSignature = () => {
        setDisabled(false)
        setSignatureStart(true)
    };

    return(
        <div>
            <CardPerso header={t('signHere')} >
                <div className='d-flex justify-content-center'>
                    <SignatureCanvas 
                        onBegin={()=>setDisabled(true)}
                        onEnd={()=>handleEndSignature()}
                        ref={sigRef}
                        penColor='black'
                        canvasProps={{
                            height: 300, 
                            className: 'signatureCadre'
                        }} 
                    />
                </div>
            </CardPerso>
            <div>
                <button disabled={disabled || props.validButton} className='btn btn-sm btn-warning' onClick={()=>handleClick('clear')}>{t('btnEffacer')}</button>&nbsp;&nbsp;&nbsp;
                <button disabled={disabled || props.validButton || props.signatureImg!=null || !signatureStart} className='btn btn-sm btn-primary' onClick={()=>handleClick('save')}>{t('btnSave')}</button>&nbsp;&nbsp;&nbsp;
                {props.signatureImg!=null && <button disabled={disabled || props.validButton} className='btn btn-sm btn-danger' onClick={()=>handleClick('reset')}>{t('btnDelete')}</button>}
            </div>
        </div>
    )
}
export default SignatureForm;