import React, {useState,useEffect,useContext,Suspense,lazy} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {Loading} from '@gull'
import axios from 'axios'
import swal from "sweetalert2";
import { IsAuthContext} from 'app/App';
import { PDFViewer} from '@react-pdf/renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle,faEye,faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import {Badge} from "react-bootstrap";
import Facturation from '../../../../app/views/Pdf/Facturation'

// const Facturation = lazy(() => import("../../../../app/views/Pdf/Facturation"));

const SelectedFraisManuelsLine = (props) => {
    const t = useTranslate();

    const removeMethod = (name,douaneCatSelected) => {
        if(name=='line'){
            //On retire la ligne et on réaffiche la nouvelle liste
            const updatedArray = props.fraisManuels.filter((_, index) => index !== props.ind);
            props.setFraisManuels(updatedArray); // Mettre à jour l'état avec le tableau mis à jour
        }
        else{
            let line = { ...props.fraisManuels[props.ind] };  // Clone de l'objet sélectionné

            // Supprimer l'élément d'un certain index dans 'associate'
            line.customs = line.customs.filter(value => value !== douaneCatSelected);  // Filtrer l'index que vous voulez supprimer

            let newData = [...props.fraisManuels];  // Clone du tableau global
            newData[props.ind] = line;  // Remplacer l'élément mis à jour dans le tableau

            props.setFraisManuels(newData);  // Mettre à jour l'état avec les nouvelles données
        }
    };

    const handleMontant = (e) =>{
        let value = e.target.value.replace(/[^\d.]/g, '')
        if(value>=0){
            const updatedFraisManuels = [...props.fraisManuels];
            updatedFraisManuels[props.ind].montant = value;
            props.setFraisManuels(updatedFraisManuels);
        }
    }

    const handleNote = (e) => {
        let value = e.target.value
        const updatedFraisManuels = [...props.fraisManuels];
        updatedFraisManuels[props.ind].note = value;
        props.setFraisManuels(updatedFraisManuels);
    }

    const linkToCustomsCat = (e) => {
        let line = { ...props.fraisManuels[props.ind] };  // Clone de l'objet sélectionné

        line.customs = [...line.customs, e.target.value]; // Ajoute la nouvelle valeur à 'associate'

        let newData = [...props.fraisManuels];
        newData[props.ind] = line; // Remplacer l'élément mis à jour dans le tableau

        props.setFraisManuels(newData);
    }
    
    return(<>
        <li className={props.style}>
            <div className="row">
                <div className="col-10">
                    <div className="col-12">
                        <div className="input-group">
                            <input type="text" className="form-control form-control-sm" value={props.fraisManuels[props.ind].montant} onChange={(e)=>handleMontant(e)} />
                            <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon1">{props.devise}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-1">
                        <textarea className="form-control" rows={3} placeholder='Note...' value={props.fraisManuels[props.ind].note} onChange={(e)=>handleNote(e)}></textarea>
                    </div>
                    <div className="form-group col-12">
                        <label>{t('linkToDouaneCat')}</label>
                        <select disabled={props.validButton} className='form-control form-control-sm' onChange={(e)=>linkToCustomsCat(e)}>
                            <option value="">-- Select --</option>
                            {props.allCustomsCategories.map(douaneCat=>{
                                if(!props.fraisManuels[props.ind].customs.includes(douaneCat)){
                                    return(
                                        <option key={douaneCat} value={douaneCat}>{douaneCat}</option>
                                    )
                                }
                            })}
                        </select>
                    </div>
                    <div className="col-12">
                        {props.fraisManuels[props.ind].customs.length==0 ? 
                            <Badge bg='warning'>{t('linkTo')+" "+t('allItems').toLowerCase()}</Badge>
                        :
                            props.fraisManuels[props.ind].customs.map((douaneCatSelected)=>
                                <span key={douaneCatSelected}>
                                    <Badge bg="primary" text="white">
                                        <span className='d-flex align-items-center'>
                                            {douaneCatSelected}&nbsp;&nbsp;
                                            {props.validButton ?
                                                <span className="text-muted">
                                                    {<FontAwesomeIcon icon={faTimesCircle} />}
                                                </span>
                                            :
                                                <span className="text-danger pointer" onClick={()=>removeMethod('douaneCat',douaneCatSelected)}>
                                                    {<FontAwesomeIcon icon={faTimesCircle} />}
                                                </span>
                                            }
                                        </span>
                                    </Badge>&nbsp;&nbsp;
                                </span>
                            )
                        }
                    </div>
                </div>
                <div className="col-2">
                    <button disabled={props.validButton} className='btn btn-sm btn-danger' onClick={()=>removeMethod('line',false)} >
                        {t('btnRemove')}
                    </button>
                </div>
            </div>
        </li>
    </>
    )
}

export default SelectedFraisManuelsLine;