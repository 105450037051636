import React from "react";
import {FormatedPrice} from "@gull";
import {Type} from 'react-bootstrap-table2-editor';

const articleDataGenerator = (obj, article,t,openModalCarousel) => {

    obj.internalid = article.internalid;
    obj.marque = (article.marque) ? article.marque.name : '-';
    obj.fournisseur = (article.fournisseur) ? article.fournisseur.name : '-';
    obj.provisoire  = (article.provisoire == 0) ? t('btnNo') : t('btnYes');
    obj.havestock = (article.havestock) ? t('yes') :  t('no');
    obj.name = article.name;
    obj.sku = article.sku;
    obj.parent_name = article.parent_name;
    obj.reference = (article.reference) ? article.reference : '';
    obj.reference2 = (article.reference2) ? article.reference2 : '';
    obj.image = (article.image!=null) ? <img className="vignetteBaseArticle" src={article.image.split(';')[0]} alt={article.name} onClick={()=>openModalCarousel(article.image,article.name)}/> : '';
    // obj.pr = article.pr;
    obj.margeN  = (article.ht!=null && article.ht!=0) ? article.ht-article.prmp : 0;
    obj.margeP  = (article.ht!=null && article.ht!=0) ? Math.round((article.ht-article.prmp)/article.ht*100) : 0;
    obj.tgc = article.tgc;
    obj.article_id = article.id;
    obj.prmp  = article.prmp;
    obj.ht  = article.ht;
    obj.price = article.price;
    obj.price_b2b = article.price_b2b!=null ? article.price_b2b : '';
    obj.ttcb2b = article.price_b2b_ttc!=null ? article.price_b2b_ttc : '';
    obj.margeNB2b  = (article.price_b2b!=null && article.price_b2b!=0) ? article.price_b2b-article.prmp : 0;
    obj.margePB2b  = (article.price_b2b!=null && article.price_b2b!=0) ? Math.round((article.price_b2b-article.prmp)/article.price_b2b*100) : 0;
    obj.firstlevelcat = article.firstlevelcat && article.firstlevelcat.name != null ? article.firstlevelcat.name : '';
    obj.secondlevelcat = article.secondlevelcat && article.secondlevelcat.name != null ? article.secondlevelcat.name : '';
    obj.thirdlevelcat = article.thirdlevelcat && article.thirdlevelcat.name != null ? article.thirdlevelcat.name : '';
    obj.fourthlevelcat = article.fourthlevelcat && article.fourthlevelcat.name != null ? article.fourthlevelcat.name : '';
    obj.fifthlevelcat = article.fifthlevelcat && article.fifthlevelcat.name != null ? article.fifthlevelcat.name : '';
    obj.archive = article.archive;
    obj.categorie_douane = article.categorie_douane!=null ? article.categorie_douane : '';
    obj.ecotaxe = article.ecotaxe!=null ? article.ecotaxe : '';
    obj.last_date_buy = article.last_date_buy!=null ? article.last_date_buy : '';
    obj.last_pr = article.last_pr!=null ? article.last_pr : '';
};

export default articleDataGenerator;