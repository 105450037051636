import React,{useState} from "react";
import { FormatedPrice,CarouselImage } from "@gull"
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentAlt,faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import NoteModal from "./Component/NoteModal"
import SelectSeller from "../SelectSeller"
import { Modal } from "react-bootstrap";
import DeleteLineButton from './Component/DeleteLineButton'

const LineItemRetour = (props) =>{
    const t =useTranslate()
    const [showNote,setShowNote]=useState(false)
    const [loading,setLoading]=useState(false)
    const idsLocalStorage=localStorage.getItem("pos").split(',')
    const [showCarousel,setShowCarousel]=useState(false) 

    const showModal = () =>{
        setShowNote(true)
    }

    const closeModal = (name) =>{
        if(name == 'note'){
            setShowNote(false)
        }
        props.setRelanceFocus(Date.now())
    }

    return(
        <>
            <Modal show={showCarousel} onHide={()=>setShowCarousel()}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.item.article && props.item.article.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(props.item.article && props.item.article.image) ?
                        <CarouselImage images={props.item.article.image} />
                    :
                        <div></div>
                    }
                </Modal.Body>
            </Modal>
            <NoteModal 
                showNote={showNote}
                closeModal={closeModal}
                posid={props.posid}
                cookies={props.cookies}
                currentTicket={props.currentTicket}
                setCurrentTicket={props.setCurrentTicket}
                lineId={props.lineId}
                item={props.item}
                module='pos'
            />
            <div className='articleRetour px-2'>
                <div className="row">
                    <div className="col-8">
                        <strong className="fsArticlePos">{props.item.article_retour.sku+' '+props.item.article_retour.name}</strong> 
                    </div>
                    <div className="col-4">
                        {(props.item.article && props.item.article.image) ?
                            <div className='text-end mb-1' style={{maxHeight:'60px'}}>
                                <img className="pointer" style={{maxHeight:'50px', maxWidth:'70px'}} src={props.item.article.image.split(';')[0]} alt={props.item.article.name} onClick={()=>setShowCarousel(true)}/>
                            </div>
                        :
                            <div className='text-end mb-1' style={{maxHeight:'60px'}}>
                                <img style={{maxHeight:'50px', maxWidth:'70px'}} src="/assets/images/no-image.png" alt="No image"/>
                            </div>
                        }
                    </div>
                </div>
                <div className="row marginLeftArticle">
                    <div className="col-2 m-0 p-0">
                        <input disabled className='form-control' value={props.item.quantity} type="text" />
                        {/* <div className="row ">
                            <div className="col-3 m-0 p-0 input-group-sm z-index1000">
                                <input disabled className='form-control' value={props.item.quantity} type="text" />
                            </div>
                            <div className="col"><strong className="fsArticlePos">{props.item.ticket_retour.bar}</strong></div>
                        </div> */}
                    </div>
                    <div className="col-1">
                        <button className="btn btn-primary btn-sm sizeBtnPos" onClick={()=>showModal()}><FontAwesomeIcon icon={faCommentAlt} /></button>
                        {/* <div className="row">
                            <div className="col-1 m-0 p-0 zIndexBtnNotePos">
                                <button className="btn btn-primary btn-sm sizeBtnPos" onClick={()=>showModal()}><FontAwesomeIcon icon={faCommentAlt} /></button>
                            </div>
                        </div> */}
                    </div>&nbsp;
                    <div className="col-4">
                        <SelectSeller 
                            section={props.section} 
                            cookies={props.cookies}
                            item={props.item}
                            lineId={props.item.id}
                            posid={props.posid}
                            currentTicket={props.currentTicket}
                            setCurrentTicket={props.setCurrentTicket} 
                            setRelanceFocus={props.setRelanceFocus}
                            from='pos'//à passer pour obligatoirement pour connaitre les options de son module 
                        />
                    </div>
                    <div className="col-4 m-0 p-0">
                        <div className="text-end m-0 p-0 priceArticlePos totalPricePosition">
                            <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.item.prix_remise} />
                        </div>
                        <span className="m-0 p-0 crossPosition">
                            {(loading) ?
                                    <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span>
                                :
                                    <DeleteLineButton
                                        setLoading={setLoading}
                                        posid={props.posid}
                                        idsLocalStorage={idsLocalStorage}
                                        item={props.item}
                                        cookies={props.cookies}
                                        setCurrentTicket={props.setCurrentTicket}
                                        setRelanceFocus={props.setRelanceFocus}
                                        from='lineItemRetour'
                                        restrictionDeleteLineItem={props.restrictionDeleteLineItem}
                                        setRestrictionDeleteLineItem={props.setRestrictionDeleteLineItem}
                                        section={props.section} 
                                    />
                            }
                        </span>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-8">
                        <strong className="fsArticlePos">{props.item.ticket_retour.bar}</strong>
                    </div>
                    {(props.section && props.section.advanceparams.hasOwnProperty('note_retour_article') && props.section.advanceparams.note_retour_article && (props.item.note==null || props.item.note=='')) &&
                        <div className="col-8 text-danger">
                            <FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;
                            <strong className="fsArticlePos">{t('noteRequiredMsg')}</strong>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default LineItemRetour;