import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import Moment from 'react-moment';
import {espace_mille} from 'app/espace_mille'
import {numberToWordsFr} from 'app/numberToWords'
import FontRubikRegular from './fonts/Rubik-Regular.ttf';
import FontRubikItalic from './fonts/Rubik-Italic.ttf';
import FontRubikBold from './fonts/Rubik-Bold.ttf';
import FontRubikBoldItalic from './fonts/Rubik-BoldItalic.ttf';
var JsBarcode = require('jsbarcode');

Font.register({
    family: 'Rubik',
    fonts: [
        {
            src: FontRubikRegular,
        },
        {
            src: FontRubikBold,
            fontWeight: 'bold',
        },
        {
            src: FontRubikItalic,
            fontStyle: 'italic',
        },
        {
            src: FontRubikBoldItalic,
            fontWeight: 'bold',
            fontStyle: 'italic',
        },
    ],
});

// Create styles
const styles = StyleSheet.create({
    body:{
        paddingTop: 35,
        paddingBottom: 90,
        paddingHorizontal: 35,
    },
    titleBar: {
        padding:'15',
        marginBottom:'25',
        backgroundColor:'rgba(239, 239, 240, 1)',
        flexDirection:'row',
        justifyContent:'space-between',
        fontFamily:'Rubik'
    },
    titleFooter: {
        padding:'7',
        backgroundColor:'rgba(239, 239, 240, 1)',
        left:'10%',
        fontFamily:'Rubik',
        width: '90%',
        position: 'absolute',
        fontSize: 9,
        bottom: 30,
        textAlign: 'center',
    },
    numCompte:{
        fontFamily:'Rubik',
        position: 'absolute',
        left:0,
        right:0,
        fontSize: 9,
        bottom: 70,
        textAlign: 'center',
    },
    titleContain: {
        fontSize:'10',
    },
    infosCompany: {
        marginRight:'40',
        flexDirection:'row',
        justifyContent:'space-between',
        fontFamily:'Rubik'
    },
    descriptionCompany:{
        fontSize:'10',
        flexDirection:'column',
        width:'70%',
        marginBottom:'20'
    },
    logocontain:{
        width:'30%',
    },
    logo:{
        width:60,
        height:60,
        marginBottom:8
    },
    tab: {
        borderTop:1, 
        borderColor:'#d3d3d3',
        width:'9cm',
        paddingTop:5, 
        paddingBottom: 5,
        fontSize:'8',
    },
    tab2: {
        borderTop:1, 
        borderColor:'#d3d3d3',
        width:'1.5cm',
        paddingTop:5, 
        paddingBottom: 5,
        textAlign: 'center',
        fontSize:'8'
    },
    tab3: {
        borderTop:1, 
        borderColor:'#d3d3d3',
        width:'2.5cm',
        paddingTop:5, 
        paddingBottom: 5,
        textAlign: 'center',
        fontSize:'8'
    },
    tab4: {
        borderTop:1, 
        borderColor:'#d3d3d3',
        width:'4cm',
        paddingTop:5, 
        paddingBottom: 5,
        fontSize:'8'
    },
    tab5: {
        paddingTop:5, 
        paddingBottom: 5,
        fontSize:'8'
    },
    tabContainer:{
        flexDirection: 'row',
        justifyContent: 'center',
        marginRight:'40',
        fontFamily:'Rubik'
    },
    tabContainer2:{
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    hr:{
        borderTop:1.5,
        borderColor:'#d3d3d3',
        width:'70%',
        color:'#d3d3d3',
        margin:'auto'
    },
    textBottom:{
        marginRight:'40',
        marginTop:'20',
        fontSize:'8'
    },
    codebar: {
        width: 150,
        height: 20,
    }
  });

  var now = new Date();
  
const DevisA4 = (props) => {
    let canvas;
    // For Barcode
    canvas = document.createElement('canvas');
    JsBarcode(canvas, props.ticketFacture.bar, {
        displayValue: false,
    });
    const barcode = canvas.toDataURL();

    let logo = (props.companyInfos) ? process.env.REACT_APP_API_URL+'images/'+props.companyInfos.logo : false

    let clientName = (props.ticketFacture.ticket.client!=null && props.ticketFacture.ticket.client.name!=null && props.ticketFacture.ticket.client.firstname!=null) ? props.ticketFacture.ticket.client.name+' '+props.ticketFacture.ticket.client.firstname : (props.ticketFacture.ticket.client!=null && props.ticketFacture.ticket.client.name!=null) ? props.ticketFacture.ticket.client.name : ''

    return(<Document>
        <Page size="A4" style={styles.body}>
            <View style={styles.titleBar}>
                <Text style={styles.titleContain}>{props.t(props.documentType)+' '+props.ticketFacture.bar}</Text>
                <Text style={styles.titleContain}>
                    <Text>{props.t('emisLe')+' : '}</Text>
                    <Text><Moment format="DD/MM/YYYY">{props.ticketFacture.created_at}</Moment></Text>
                </Text>
            </View>
            <View style={styles.infosCompany}>
                <View style={styles.descriptionCompany}>
                    {(props.companyInfos.infos_superieur_ticket!=null) ? 
                        props.companyInfos.infos_superieur_ticket.map((value,ind)=>{
                            return(
                                <Text key={ind} style={{marginBottom:'4'}}>{value}</Text>
                            )
                        })
                    :
                        (props.companyInfos.name != null) ?
                            <Text style={{marginBottom:'4'}}>{props.companyInfos.name}</Text> 
                        : 
                            false
                    }
                </View>
                {(logo && props.companyInfos.logo && props.companyInfos.logo != null) ? 
                    <View style={styles.logocontain}>
                        <Image style={styles.logo}  src={logo} />
                        <Image style={styles.codebar} src={barcode}/>
                    </View>
                :
                    false
                }
            </View>

            <View style={{marginBottom:'20'}}>
                <Text style={styles.hr}></Text>
            </View>
            {(props.ticketFacture.ticket.client!=null) ? 
                <View style={styles.infosCompany}>
                    <View style={{width:'50%'}}>
                        <Text style={{marginBottom:'4',fontSize:'11'}}>{props.t('recipient')+' :'}</Text>
                        <Text style={{marginBottom:'4',fontSize:'9'}}>{clientName}</Text>
                        {(props.ticketFacture.ticket.client !=null && props.ticketFacture.ticket.client.tel!=null && props.ticketFacture.ticket.client.tel!='') ? <Text style={{marginBottom:'4',fontSize:'9'}}><Image src="/assets/images/phone.png" />{' '+props.ticketFacture.ticket.client.tel}</Text>:false}
                        {(props.ticketFacture.ticket.client !=null && props.ticketFacture.ticket.client.email!=null && props.ticketFacture.ticket.client.email!='') ? <Text style={{marginBottom:'4',fontSize:'9'}}><Image src="/assets/images/email.png" />{' '+props.ticketFacture.ticket.client.email}</Text>:false}
                    </View>
                    <View style={{width:'50%'}}>
                        <Text style={{marginBottom:'4',fontSize:'9'}}>{(props.ticketFacture.ticket.client.infos && props.ticketFacture.ticket.client.infos.adresse!=null) ? props.ticketFacture.ticket.client.infos.adresse : false}</Text>
                        <Text style={{marginBottom:'4',fontSize:'9'}}>{(props.ticketFacture.ticket.client.infos && props.ticketFacture.ticket.client.infos.quartier!=null) ? props.ticketFacture.ticket.client.infos.quartier : false}</Text>
                        <Text style={{marginBottom:'4',fontSize:'9'}}>{(props.ticketFacture.ticket.client.infos && props.ticketFacture.ticket.client.infos.ville!=null) ? props.ticketFacture.ticket.client.infos.ville : false}</Text>
                        <Text style={{marginBottom:'4',fontSize:'9'}}>{(props.ticketFacture.ticket.client.infos && props.ticketFacture.ticket.client.infos.codepostal!=null) ? props.ticketFacture.ticket.client.infos.codepostal : false}</Text>
                        <Text style={{marginBottom:'4',fontSize:'9'}}>{(props.ticketFacture.ticket.client.infos && props.ticketFacture.ticket.client.infos.pays!=null) ? props.ticketFacture.ticket.client.infos.pays : false}</Text>
                    </View>
                </View>
            :
                false
            }

            <View style={styles.tabContainer}>
                <Text style={styles.tab}>{props.t('designation')}</Text>
                <Text style={styles.tab3}>{props.t('priceTtc')}</Text>
                <Text style={styles.tab3}>{props.t('discount')}</Text>
                <Text style={styles.tab2}>{props.t('tgc')}</Text>
                <Text style={styles.tab2}>{props.t('quantity')}</Text>
                <Text style={styles.tab3}>{props.t('total')}</Text>
            </View>

            {props.ticketFacture.ticket.ticket_detail.map((value,ind)=>{
                let bg = (ind%2 == 0) ? {backgroundColor:'rgba(239, 239, 240, 1)'} : {backgroundColor:'white'};
            
                return(
                    <View key={ind} style={[styles.tabContainer,bg]}>
                        <View style={styles.tab}>
                            <Text style={{marginBottom:'1'}}>{(value.article!=null) ? value.article.sku+' '+value.article.name : false}</Text>
                            <Text style={{marginBottom:'1'}}>{(value.special=='gift_card') ? props.t('giftcard')+' '+value.details.reference : false}</Text>
                            <Text style={{marginBottom:'1'}}>{(value.special=='retour') ? value.article_retour.name : false}</Text>
                            
                            <Text>{
                                (value.note != null && value.note != '' && value.ecotaxe != null && value.ecotaxe != '' && +value.ecotaxe > 0) ? props.t('dont')+' '+props.t('ecotaxe').toLowerCase()+': '+espace_mille(Math.round(value.ecotaxe))+props.devise+'\n'+value.note : 
                                (value.ecotaxe != null && value.ecotaxe != '' && +value.ecotaxe > 0) ? props.t('dont')+' '+props.t('ecotaxe').toLowerCase()+': '+espace_mille(Math.round(value.ecotaxe))+props.devise : 
                                (value.note != null && value.note != '') ? value.note : false
                            }</Text>
                        </View>
                        <Text style={styles.tab3}>{espace_mille(value.unitaire)+' '+props.devise}</Text>
                        <Text style={styles.tab3}>{espace_mille(value.remise)+' '+props.devise}</Text>
                        <Text style={styles.tab2}>{value.tgc+'%'}</Text>
                        <Text style={styles.tab2}>{value.quantity}</Text>
                        <Text style={styles.tab3}>{espace_mille(value.prix_remise)+' '+props.devise}</Text>
                    </View>
                )
            })}

            <View style={styles.infosCompany}>
                <View style={{width:'50%', marginTop:'15', padding:'5'}}>
                    <View style={{flexDirection: 'column'}}>
                        {(props.ticketFacture.note!=null && props.ticketFacture.note!='') && <Text style={{marginBottom:'4',fontSize:'10'}}>{'Note : '}</Text>}
                        {(props.ticketFacture.note!=null && props.ticketFacture.note!='') && <Text style={{marginBottom:'6',fontSize:'10'}}>{props.ticketFacture.note}</Text>}
                        {(props.companyInfos) ? <Text style={{marginBottom:'4',fontSize:'9',fontWeight:'bold'}}>{props.t('devisValidite')+': '+props.companyInfos.maxusemoisproforma+' '+props.t('month').toLowerCase()}</Text>:false}
                    </View>
                </View>
                <View style={{width:'50%'}}>
                    <View style={styles.tabContainer2}>
                        <Text style={[styles.tab4,{textAlign:'left',paddingLeft:'7'}]}>{props.t('totalHt')}</Text>
                        <Text style={[styles.tab4,{textAlign:'right',paddingRight:'7'}]}>{espace_mille(props.totalAchat - +props.totalTgc)+' '+props.devise}</Text>
                    </View>
                    {Object.entries(props.tgcForTicketPdf).map(([key,value])=>{
                        return(
                            <View key={key} style={styles.tabContainer2}>
                                <Text style={{textAlign:'left',paddingLeft:'7',width:'4cm',paddingTop:5, paddingBottom: 5,fontSize:'8'}}>{'TGC '+key+'%'}</Text>
                                <Text style={{textAlign:'right',paddingRight:'7',width:'4cm',paddingTop:5, paddingBottom: 5,fontSize:'8'}}>{espace_mille(value)+' '+props.devise}</Text>
                            </View>
                        )
                    })}
                    <View style={styles.tabContainer2}>
                        <Text style={[styles.tab4,{textAlign:'left',paddingLeft:'7'}]}>{props.t('total')}</Text>
                        <Text style={[styles.tab4,{textAlign:'right',paddingRight:'7'}]}>{espace_mille(props.totalAchat)+' '+props.devise}</Text>
                    </View>
                    <View style={[styles.tabContainer2,{fontSize:'9', marginTop:'10',fontFamily:'Rubik'}]}>
                        {/* <Text style={{paddingLeft:'7'}}>{numberToWordsFr(Math.round(+props.totalAchat), props.devise)}</Text> */}
                        <Text style={{paddingLeft:'7'}}>{numberToWordsFr(+props.totalAchat, props.devise)}</Text>
                    </View>
                </View>
            </View>

            {(props.companyInfos.other_company.length!=0) ?
                <Text style={styles.numCompte} fixed> 
                    {(props.companyInfos.other_company[0].advanceparams.banque != null) ? <Text style={{marginBottom:'4'}}>{props.t('labelBank')+' : '+props.companyInfos.other_company[0].advanceparams.banque+' - '}</Text> : false}
                    {(props.companyInfos.other_company[0].advanceparams.code_banque != null && props.companyInfos.other_company[0].advanceparams.code_guichet != null && props.companyInfos.other_company[0].advanceparams.numero_compte != null && props.companyInfos.other_company[0].advanceparams.cle_rib != null) ? <Text style={{marginBottom:'4'}}>{props.t('labelNumeroDeCompte')+' : '+props.companyInfos.other_company[0].advanceparams.code_banque+' '+props.companyInfos.other_company[0].advanceparams.code_guichet+' '+props.companyInfos.other_company[0].advanceparams.numero_compte+' '+props.companyInfos.other_company[0].advanceparams.cle_rib}</Text> : false}
                </Text>
            :
                <Text style={styles.numCompte} fixed> 
                    {(props.authParam.company.advanceparams.banque != null) ? <Text style={{marginBottom:'4'}}>{props.t('labelBank')+' : '+props.authParam.company.advanceparams.banque+' - '}</Text> : false}
                    {(props.authParam.company.advanceparams.code_banque != null && props.authParam.company.advanceparams.code_guichet != null && props.authParam.company.advanceparams.numero_compte != null && props.authParam.company.advanceparams.cle_rib != null) ? <Text style={{marginBottom:'4'}}>{props.t('labelNumeroDeCompte')+' : '+props.authParam.company.advanceparams.code_banque+' '+props.authParam.company.advanceparams.code_guichet+' '+props.authParam.company.advanceparams.numero_compte+' '+props.authParam.company.advanceparams.cle_rib}</Text> : false}
                </Text>
            }

            <View style={styles.titleFooter} fixed> 
                <Text render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )}  />
                <Text>{props.t('generateFreebird')}</Text>
            </View>
            
            
        </Page>
    </Document>
    )
};

export default DevisA4;
