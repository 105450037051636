import React,{useState,useEffect}  from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import Moment from 'react-moment';
import {espace_mille} from 'app/espace_mille'
import { FormatedPrice} from "@gull";
import {getImageDimensions} from 'app/getImageDimensions'
import {numberToWordsFr} from 'app/numberToWords'
import FontRubikRegular from './fonts/Rubik-Regular.ttf';
import FontRubikItalic from './fonts/Rubik-Italic.ttf';
import FontRubikBold from './fonts/Rubik-Bold.ttf';
import FontRubikBoldItalic from './fonts/Rubik-BoldItalic.ttf';
import { convertHtmlToText } from 'app/htmlToTextPdf'
import {column_correspondance_facture,recap_correspondance_facture} from '../facturation/correspondance_facture'
var JsBarcode = require('jsbarcode');

Font.register({
    family: 'Rubik',
    fonts: [
        {
            src: FontRubikRegular,
        },
        {
            src: FontRubikBold,
            fontWeight: 'bold',
        },
        {
            src: FontRubikItalic,
            fontStyle: 'italic',
        },
        {
            src: FontRubikBoldItalic,
            fontWeight: 'bold',
            fontStyle: 'italic',
        },
    ],
});

// Create styles
const styles = StyleSheet.create({
    body:{
        paddingTop: 35,
        paddingBottom: 85,
        paddingHorizontal: 35,
    },
    titleBar: {
        padding:'15',
        marginBottom:'25',
        backgroundColor:'rgba(239, 239, 240, 1)',
        flexDirection:'row',
        justifyContent:'space-between',
        fontFamily:'Rubik'
    },
    titleFooter: {
        padding:'7',
        backgroundColor:'rgba(239, 239, 240, 1)',
        left:'10%',
        fontFamily:'Rubik',
        width: '90%',
        position: 'absolute',
        fontSize: 9,
        bottom: 30,
        textAlign: 'center',
    },
    numCompte:{
        fontFamily:'Rubik',
        position: 'absolute',
        left:0,
        right:0,
        fontSize: 9,
        bottom: 70,
        textAlign: 'center',
    },
    titleContain: {
        fontSize:'10',
    },
    infosCompany: {
        marginRight:'40',
        flexDirection:'row',
        justifyContent:'space-between',
        fontFamily:'Rubik',
        width:'100%'
    },
    descriptionCompany:{
        fontSize:'10',
        flexDirection:'column',
        width:'70%',
        marginBottom:'20'
    },
    logocontain:{
        width:'30%',
    },
    tab: {
        borderTop:1, 
        borderColor:'#d3d3d3',
        width:'9cm',
        paddingTop:5, 
        paddingBottom: 5,
        fontSize:'8',
    },
    tab2: {
        borderTop:1, 
        borderColor:'#d3d3d3',
        width:'1.5cm',
        paddingTop:5, 
        paddingBottom: 5,
        textAlign: 'center',
        fontSize:'8'
    },
    tab3: {
        borderTop:1, 
        borderColor:'#d3d3d3',
        width:'3.5cm',
        paddingTop:5, 
        paddingBottom: 5,
        textAlign: 'center',
        fontSize:'8',
        paddingLeft: 2,
        paddingRight: 2,
    },
    tab4: {
        borderTop:1, 
        borderColor:'#d3d3d3',
        width:'4cm',
        paddingTop:5, 
        paddingBottom: 5,
        fontSize:'8'
    },
    tab5: {
        paddingTop:5, 
        paddingBottom: 5,
        fontSize:'8'
    },
    tabContainer:{
        flexDirection: 'row',
        justifyContent: 'center',
        marginRight:'40',
        fontFamily:'Rubik',
        width:'100%'
    },
    tabContainer2:{
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    hr:{
        borderTop:1.5,
        borderColor:'#d3d3d3',
        width:'70%',
        color:'#d3d3d3',
        margin:'auto'
    },
    textBottom:{
        marginRight:'40',
        marginTop:'20',
        fontSize:'8'
    },
    codebar: {
        width: 150,
        height: 20,
    },
    leftPartInfo: {
        width:'50%', 
        marginTop:'15', 
        border:'1.5',
        padding:'5',
        borderColor:'#ff0000'
    }
  });

  var now = new Date();
  
const Facturation = React.memo((props) => {
    const [renderKey, setRenderKey] = useState(0);
    let canvas;
    // For Barcode
    canvas = document.createElement('canvas');
    JsBarcode(canvas, props.allInfos.bar, {
        displayValue: false,
    });
    const barcode = canvas.toDataURL();

    let logo = process.env.REACT_APP_API_URL+'images/'+props.companyInfos.logo

    const convertHtmlToTextBr = (html) => {
        return html.replaceAll('<br />', '').replaceAll('<br>', '');
    };

    const [formatLogo,setFormatLogo]=useState({}) 
    useEffect(function () {
        if(props.companyInfos.logo && props.companyInfos.logo != null){
            getImageDimensions(logo, (err, img) => {
                if(img!=undefined){
                    if(+img.naturalWidth > +img.naturalHeight){
                        //L'image est rectangulaire en paysage 
                        setFormatLogo({ 
                            width:150,
                            height:60,
                            marginBottom:8,
                        })
                    }else if(+img.naturalWidth < +img.naturalHeight){
                        //L'image est rectangulaire en portrait
                        let width = Math.round((+img.naturalWidth * 80) / +img.naturalHeight);
                        setFormatLogo({ 
                            width:width,
                            height:80,
                            marginBottom:8,
                            margin:'auto',
                        })
                    }else if(+img.naturalWidth == +img.naturalHeight){
                        //L'image est carrée
                        setFormatLogo({ 
                            width:60,
                            height:60,
                            marginBottom:8,
                            margin:'auto',
                        })
                    }
                }
            });
        }
    }, [props.companyInfos])

    useEffect(() => {
        setRenderKey(prevKey => prevKey + 1);
    }, [props.columnShow,props.recapShow]);

    let clientName = (props.clientDetail!=null && props.clientDetail.name!=null && props.clientDetail.firstname!=null) ? props.clientDetail.name+' '+props.clientDetail.firstname : (props.clientDetail!=null && props.clientDetail.name!=null) ? props.clientDetail.name : ''

    return(<Document key={renderKey}>
        <Page size="A4" style={styles.body}>
            <View style={styles.titleBar}>
                <Text style={styles.titleContain}>{(props.allInfos.status==1) ? props.t(props.documentType)+' '+props.allInfos.bar : props.t(props.documentType) }</Text>
                <Text style={styles.titleContain}>
                    <Text>{props.t('emisLe')+' : '}</Text>
                    <Text><Moment format="DD/MM/YYYY">{(props.allInfos.date_finalisation) ? props.allInfos.date_finalisation : now}</Moment></Text>
                </Text>
            </View>
            <View style={styles.infosCompany}>
                {props.allInfos.type!='billingBuy' ?
                    <View style={styles.descriptionCompany}>
                        {(props.companyInfos.name != null) ? <Text style={{marginBottom:'4'}}>{props.companyInfos.name}</Text> : false}
                        <Text style={{marginBottom:'4'}}>
                            {/* {(props.companyInfos.forme != null) ? <Text>{props.companyInfos.forme}</Text> : false} */}
                            {(props.companyInfos.advanceparams.capital) ? <Text>{' Capital : '+espace_mille(Math.round(props.companyInfos.advanceparams.capital))+props.devise}</Text> : false}
                        </Text>
                        {(props.companyInfos.rcs != null) ? <Text style={{marginBottom:'4'}}>{props.companyInfos.rcs}</Text> : false}
                        <Text style={{marginBottom:'4'}}>
                            {(props.companyInfos.advanceparams.adress && props.companyInfos.advanceparams.adress != null) ? <Text>{props.companyInfos.advanceparams.adress}</Text> : false}
                            {(props.companyInfos.advanceparams.ville && props.companyInfos.advanceparams.ville != null) ? <Text>{' - '+props.companyInfos.advanceparams.ville}</Text> : false}
                            {(props.companyInfos.advanceparams.code_postal && props.companyInfos.advanceparams.code_postal != null) ? <Text>{' - '+props.companyInfos.advanceparams.code_postal}</Text> : false}
                        </Text>
                        {(props.companyInfos.tel != null) ? <Text style={{marginBottom:'4'}}><Image src="/assets/images/phone.png" />{' '+props.companyInfos.tel}</Text> : false}
                        {(props.companyInfos.email != null) ? <Text style={{marginBottom:'4'}}><Image src="/assets/images/email.png" />{' '+props.companyInfos.email}</Text> : false}
                        {/* {(props.companyInfos.adresse2 != null) ?
                            <Text style={{marginBottom:'4'}}>
                                {(props.companyInfos.adresse2 != null) ? <Text><Image src="/assets/images/courrier.png" />{' '+props.companyInfos.adresse2}</Text> : false}
                                {(props.companyInfos.city2 != null) ? <Text>{' - '+props.companyInfos.city2}</Text> : false}
                                {(props.companyInfos.codepostal2 != null) ? <Text>{' - '+props.companyInfos.codepostal2}</Text> : false}
                            </Text>
                        :
                            false
                        } */}
                        {(props.companyInfos.site != null) ? <Text style={{marginBottom:'4'}}>{props.companyInfos.site}</Text> : false}
                        
                    </View>
                :
                    <View style={styles.descriptionCompany}>
                        {(props.clientDetail.name != null) ? <Text style={{marginBottom:'4'}}>{props.clientDetail.name}</Text> : false}
                    </View>
                }
                {(props.allInfos.type!='billingBuy' && props.companyInfos.logo && props.companyInfos.logo != null) ? 
                    <View style={styles.logocontain}>
                        <Image style={formatLogo}  src={logo} />
                        {(props.allInfos.status==1) ? <Image style={styles.codebar} src={barcode}/> : false}
                    </View>
                :
                    false
                }
            </View>

            <View style={{marginBottom:'20'}}>
                <Text style={styles.hr}></Text>
            </View>

            {props.allInfos.type!='billingBuy' ?
                <View style={styles.infosCompany}>
                    <View style={{width:'50%'}}>
                        <Text style={{marginBottom:'4',fontSize:'11'}}>{props.t('recipient')+' :'}</Text>
                        <Text style={{marginBottom:'4',fontSize:'9'}}>{clientName} - {(props.clientDetail.old_code_client!=null) ? props.clientDetail.old_code_client : 'C'+props.clientDetail.id}</Text>
                        <Text style={{ marginBottom: 4, fontSize: 9 }}>
                            {props.clientDetail.tel && (
                                <>
                                <Image src="/assets/images/phone.png" />
                                {' ' + props.clientDetail.tel}
                                </>
                            )}
                        </Text>
                        <Text style={{ marginBottom: 4, fontSize: 9 }}>
                            {props.clientDetail.email && (
                                <>
                                <Image src="/assets/images/email.png" />
                                {' ' + props.clientDetail.email}
                                </>
                            )}
                        </Text>
                    </View>
                    <View style={{width:'50%'}}>
                    <Text style={{marginBottom:'4',fontSize:'9'}}>{(props.clientDetail.divers && props.clientDetail.divers.adresse!=null) ? props.clientDetail.divers.adresse : (props.clientDetail.infos && props.clientDetail.infos.adresse!=null) ? props.clientDetail.infos.adresse : false}</Text>
                        <Text style={{marginBottom:'4',fontSize:'9'}}>{(props.clientDetail.divers && props.clientDetail.divers.quartier!=null) ? props.clientDetail.divers.quartier+' ' : (props.clientDetail.infos && props.clientDetail.infos.quartier!=null) ? props.clientDetail.infos.quartier+' ' : false}{(props.clientDetail.divers && props.clientDetail.divers.ville!=null) ? props.clientDetail.divers.ville : (props.clientDetail.infos && props.clientDetail.infos.ville!=null) ? props.clientDetail.infos.ville : false}</Text>
                        <Text style={{marginBottom:'4',fontSize:'9'}}>{(props.clientDetail.divers && props.clientDetail.divers.codepostal!=null) ? props.clientDetail.divers.codepostal : (props.clientDetail.infos && props.clientDetail.infos.codepostal!=null) ? props.clientDetail.infos.codepostal : false}</Text>
                        <Text style={{marginBottom:'4',fontSize:'9'}}>{(props.clientDetail.divers && props.clientDetail.divers.pays!=null) ? props.clientDetail.divers.pays : (props.clientDetail.infos && props.clientDetail.infos.pays!=null) ? props.clientDetail.infos.pays : false}</Text>
                    </View>
                </View>
            :
                <View style={styles.descriptionCompany}>
                    {(props.companyInfos.name != null) ? <Text style={{marginBottom:'4'}}>{props.companyInfos.name}</Text> : false}
                    <Text style={{marginBottom:'4'}}>
                        {/* {(props.companyInfos.forme != null) ? <Text>{props.companyInfos.forme}</Text> : false} */}
                        {(props.companyInfos.advanceparams.capital) ? <Text>{' Capital : '+espace_mille(Math.round(props.companyInfos.advanceparams.capital))+props.devise}</Text> : false}
                    </Text>
                    {(props.companyInfos.rcs != null) ? <Text style={{marginBottom:'4'}}>{props.companyInfos.rcs}</Text> : false}
                    <Text style={{marginBottom:'4'}}>
                        {(props.companyInfos.advanceparams.adress && props.companyInfos.advanceparams.adress != null) ? <Text>{props.companyInfos.advanceparams.adress}</Text> : false}
                        {(props.companyInfos.advanceparams.ville && props.companyInfos.advanceparams.ville != null) ? <Text>{' - '+props.companyInfos.advanceparams.ville}</Text> : false}
                        {(props.companyInfos.advanceparams.code_postal && props.companyInfos.advanceparams.code_postal != null) ? <Text>{' - '+props.companyInfos.advanceparams.code_postal}</Text> : false}
                    </Text>
                    {(props.companyInfos.tel != null) ? <Text style={{marginBottom:'4'}}><Image src="/assets/images/phone.png" />{' '+props.companyInfos.tel}</Text> : false}
                    {(props.companyInfos.email != null) ? <Text style={{marginBottom:'4'}}><Image src="/assets/images/email.png" />{' '+props.companyInfos.email}</Text> : false}
                    {/* {(props.companyInfos.adresse2 != null) ?
                        <Text style={{marginBottom:'4'}}>
                            {(props.companyInfos.adresse2 != null) ? <Text><Image src="/assets/images/courrier.png" />{' '+props.companyInfos.adresse2}</Text> : false}
                            {(props.companyInfos.city2 != null) ? <Text>{' - '+props.companyInfos.city2}</Text> : false}
                            {(props.companyInfos.codepostal2 != null) ? <Text>{' - '+props.companyInfos.codepostal2}</Text> : false}
                        </Text>
                    :
                        false
                    } */}
                    {(props.companyInfos.site != null) ? <Text style={{marginBottom:'4'}}>{props.companyInfos.site}</Text> : false}
                    
                </View>
            }
            {(props.allInfos.divers_infos!=null && props.allInfos.divers_infos.hasOwnProperty('superieur_infos') && props.allInfos.divers_infos.superieur_infos!=null && props.allInfos.divers_infos.superieur_infos!='') &&
                <View style={{marginBottom:'10',marginRight:'40',fontSize:'8'}}>
                    {convertHtmlToText(props.allInfos.divers_infos.superieur_infos)}
                </View>
            }
            {/* <View style={{marginBottom:'10',marginLeft:'40',marginRight:'40',fontSize:'8'}}>
                <Text>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda magnam adipisci veniam libero est corrupti, fuga quod voluptates et? Pariatur, temporibus aut? Velit corrupti quas eligendi voluptates quos similique veritatis autem beatae dolorem dolor quia voluptas harum, iste temporibus magnam magni perferendis corporis consequuntur</Text>
            </View> */}

            {/* Zone Designation articles, tarif, quantité,... */}
            {props.allInfos.type!='billingDeposit' &&
                <>
                    <View style={styles.tabContainer}>
                        {props.columnShow.map(value=>
                            <Text key={value} style={{...styles[column_correspondance_facture[value].stylePdf],textAlign:column_correspondance_facture[value].align}}>{props.t(column_correspondance_facture[value].name)}</Text>
                        )}
                    </View>

                    {props.billing.map((value,ind)=>{
                        let bg = (ind%2 == 0) ? {backgroundColor:'rgba(239, 239, 240, 1)'} : {backgroundColor:'white'};
                        
                        return(
                            <View key={ind} style={[styles.tabContainer,bg]} wrap={false}>
                                {props.columnShow.map(valeur=>{
                                    switch(column_correspondance_facture[valeur].format) {
                                        case 'designation':
                                            return(
                                                <View key={valeur} style={styles[column_correspondance_facture[valeur].stylePdf]}>
                                                    <Text style={{marginBottom:'1'}}>{value[valeur]}</Text>
                                                    <Text>{
                                                        (value.description != null && value.description != '' && value.ecotaxe != null && value.ecotaxe != '' && +value.ecotaxe > 0) ? props.t('dont')+' '+props.t('ecotaxe').toLowerCase()+': '+espace_mille(Math.abs(value.ecotaxe))+props.devise+'\n'+value.description : 
                                                        (value.ecotaxe != null && value.ecotaxe != '' && +value.ecotaxe > 0) ? props.t('dont')+' '+props.t('ecotaxe').toLowerCase()+': '+espace_mille(Math.abs(value.ecotaxe))+props.devise : 
                                                        (value.description != null && value.description != '') ? convertHtmlToTextBr(value.description) : false
                                                    }</Text>
                                                </View>
                                            )
                                        case 'price':
                                            return(<Text key={valeur} style={{...styles[column_correspondance_facture[valeur].stylePdf],textAlign:column_correspondance_facture[valeur].align}}>{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={value[valeur]} />}</Text>)
                                        case 'devise':
                                            return(<Text key={valeur} style={{...styles[column_correspondance_facture[valeur].stylePdf],textAlign:column_correspondance_facture[valeur].align}}>{<FormatedPrice langRegion={'fr-FR'} devise={(props.allInfos.devise) ? props.allInfos.devise : ''} digit={2} priceToFormat={value[valeur]} />}</Text>)
                                        case 'remise':
                                            return(<Text key={valeur} style={{...styles[column_correspondance_facture[valeur].stylePdf],textAlign:column_correspondance_facture[valeur].align}}>{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={-value[valeur]} />}</Text>)
                                        case 'tgc':
                                            return(<Text key={valeur} style={{...styles[column_correspondance_facture[valeur].stylePdf],textAlign:column_correspondance_facture[valeur].align}}>{(props.allInfos.tgc && !Array.isArray(props.allInfos.tgc)) ? value[valeur]+'%' : 0+'%'}</Text>)
                                        default:
                                            return(<Text key={valeur} style={{...styles[column_correspondance_facture[valeur].stylePdf],textAlign:column_correspondance_facture[valeur].align}}>{value[valeur]}</Text>)
                                    }
                                })}
                            </View>
                        )
                    })}
                </>
            }
            {/* END Zone Designation articles, tarif, quantité,... */}

            <View style={styles.infosCompany}>
                {
                props.allInfos.facture_paiement_records.length!=0 ? 
                    <View style={{width:props.allInfos.link_with_parent==null ? '50%' : '100%', marginTop:'15', border:'1.5',padding:'5',borderColor:'#ff0000'}}>
                        <View style={{flexDirection: 'row', alignItems:'center'}}>
                            <Text style={{marginBottom:'4',fontSize:'10'}}>{(props.allInfos.type=='billing' || props.allInfos.type=='billingBuy') ? props.t('payment') : props.t('depositLabel')} :</Text>
                        </View>
                        
                        {props.allInfos.facture_paiement_records.map(value=>{
                            return(
                                <View key={value.id}>
                                    <View style={{flexDirection: 'row', alignItems:'center', justifyContent:'space-between'}}>
                                        <Text style={{marginBottom:'4',fontSize:'8'}}>{props.t(value.mode)}</Text>
                                        <Text style={{marginBottom:'4',fontSize:'8'}}><Moment format="DD/MM/YYYY">{value.paiement_date}</Moment></Text>
                                        <Text style={{marginBottom:'4',fontSize:'8'}}>{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={+value.montant} />}</Text>
                                    </View>
                                </View>
                            )
                        })}
                    </View>
                :
                props.allInfos.type=='quotation' && props.allInfos.link_with_child!=null && props.allInfos.link_with_child.length!=0 ?
                    <View style={styles.leftPartInfo}>
                        <View style={{flexDirection: 'row', alignItems:'center'}}>
                            <Text style={{marginBottom:'4',fontSize:'10'}}>{props.t('depositLabel')+' : '}</Text>
                        </View>
                        {props.allInfos.link_with_child.map(value=>{
                            if(value.type=='billingDeposit'){
                                return(
                                    <View key={value.id}>
                                        <View style={{flexDirection: 'row', alignItems:'center', justifyContent:'space-between'}}>
                                            <Text style={{marginBottom:'4',fontSize:'8'}}>{props.t(value.facture_paiement_records[0].mode)}</Text>
                                            <Text style={{marginBottom:'4',fontSize:'8'}}><Moment format="DD/MM/YYYY">{value.facture_paiement_records[0].paiement_date}</Moment></Text>
                                            <Text style={{marginBottom:'4',fontSize:'8'}}>{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={+value.facture_paiement_records[0].montant} />}</Text>
                                        </View>
                                        <Text style={{marginBottom:'4',fontSize:'8'}}>{props.t('linkTo')+' '+props.t('billingDeposit').toLowerCase()+' : '+value.bar}</Text>
                                    </View>
                                )
                            }
                        })}
                    </View>
                :
                props.allInfos.date_limit!=null ?
                    <View style={styles.leftPartInfo}>
                        <View style={{flexDirection: 'row', alignItems:'center'}}>
                            <Text style={{marginBottom:'4',fontSize:'10'}}>{props.documentType=='billing' ? props.t('limitDayPayment')+' : ' : props.t('limitDateValidity')+' : '}</Text>
                            <Text style={{marginBottom:'4',fontSize:'10'}}><Moment format="DD/MM/YYYY">{props.allInfos.date_limit}</Moment></Text>
                        </View>
                        {props.documentType=='billing' && props.allInfos.infos_date_limit!=null &&
                            <View>
                                <Text style={{marginBottom:'4',fontSize:'10'}}>{props.t('caseLatePayment')+' :'}</Text>
                                <Text style={{marginBottom:'4',fontSize:'8'}}>{props.allInfos.infos_date_limit}</Text>
                            </View>
                        }
                    </View>
                :
                    <View style={{width:'50%', marginTop:'15', padding:'5'}}></View>
                }
                {props.allInfos.type!='billingDeposit' &&
                    <View style={{width:'50%'}}>
                        {props.recapShow.map(value=>{
                            switch(recap_correspondance_facture[value].format) {
                                case 'price':
                                    return(
                                        <View key={value} style={styles.tabContainer2}>
                                            <Text style={[styles[recap_correspondance_facture[value].stylePdf],{textAlign:'left'}]}>{props.t(recap_correspondance_facture[value].name)}</Text>
                                            <Text style={[styles[recap_correspondance_facture[value].stylePdf],{textAlign:'right'}]}>{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={+props.allInfos[value]} />}</Text>
                                        </View>
                                    )
                                case 'devise':
                                    return(
                                        <View key={value} style={styles.tabContainer2}>
                                            <Text style={[styles[recap_correspondance_facture[value].stylePdf],{textAlign:'left'}]}>{props.t(recap_correspondance_facture[value].name)}</Text>
                                            <Text style={[styles[recap_correspondance_facture[value].stylePdf],{textAlign:'right'}]}>{<FormatedPrice langRegion={'fr-FR'} devise={(props.allInfos.devise) ? props.allInfos.devise : ''} digit={2} priceToFormat={+props.allInfos[value]} />}</Text>
                                        </View>
                                    )
                                case 'remise':
                                    return(
                                        <View key={value} style={styles.tabContainer2}>
                                            <Text style={[styles[recap_correspondance_facture[value].stylePdf],{textAlign:'left'}]}>{props.t(recap_correspondance_facture[value].name)}</Text>
                                            <Text style={[styles[recap_correspondance_facture[value].stylePdf],{textAlign:'right'}]}>{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={-props.allInfos[value]} />}</Text>
                                        </View>
                                    )
                                case 'tgc':
                                    if (props.allInfos.tgc && !Array.isArray(props.allInfos.tgc)) {
                                        return Object.entries(props.allInfos.tgc).map(([key, valeur]) => {
                                            return (
                                                <View key={key} style={styles.tabContainer2}>
                                                    <Text style={{ textAlign: 'left', width: '4cm', paddingTop: 5, paddingBottom: 5, fontSize: '8' }}>
                                                        {'TGC ' + key + '%'}
                                                    </Text>
                                                    <Text style={{ textAlign: 'right', width: '4cm', paddingTop: 5, paddingBottom: 5, fontSize: '8' }}>
                                                        {<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={valeur} />}
                                                    </Text>
                                                </View>
                                            );
                                        });
                                    } else {
                                        return null;
                                    }
                                default:
                                    return(<Text key={value}></Text>)
                            }
                        })}
                        <View style={[styles.tabContainer2,{fontSize:'9', marginTop:'10',fontFamily:'Rubik'}]}>
                            <Text style={{paddingLeft:'7'}}>{numberToWordsFr(Math.round(+props.allInfos.ttc), props.devise)}</Text>
                        </View>
                    </View>
                }
            </View>

            {/* <View style={styles.infosCompany}>
                {(props.optionText.tampon != null) ? 
                    <Text style={[styles.logo,{textAlign:'center',marginTop:'20'}]}>
                        <Image  src="https://www.tailorbrands.com/wp-content/uploads/2020/06/mcdonalds-logo-a-1.png" />
                    </Text>
                :
                    false
                }
            </View> */}

            {(props.allInfos.divers_infos!=null && props.allInfos.divers_infos.hasOwnProperty('inferieur_infos') && props.allInfos.divers_infos.inferieur_infos!=null && props.allInfos.divers_infos.inferieur_infos!='') &&
                <View style={styles.textBottom}>
                    {convertHtmlToText(props.allInfos.divers_infos.inferieur_infos)}
                </View>
            }

            {(props.allInfos.signature!=null) &&
                 <View style={{marginTop:'20px',maxWidth:'250px',alignSelf: 'flex-end'}}>
                    <Image src={props.allInfos.signature} />
                </View>
            }

            <View style={styles.numCompte} fixed> 
                {/* <Text> {(props.companyInfos.advanceparams.banque != null) ? <Text style={{marginBottom:'4'}}>{props.t('labelBank')+' : '+props.companyInfos.advanceparams.banque}</Text> : false}</Text>
                <Text> {(props.companyInfos.advanceparams.iban != null) ? <Text style={{marginBottom:'4'}}>{'IBAN : '+props.companyInfos.advanceparams.iban}</Text> : false}</Text>
                <Text> {(props.companyInfos.advanceparams.bic != null) ? <Text style={{marginBottom:'4'}}>{'BIC : '+props.companyInfos.advanceparams.bic}</Text> : false}</Text> */}
                <Text> {(props.companyInfos.advanceparams.code_banque != null && props.companyInfos.advanceparams.code_guichet != null && props.companyInfos.advanceparams.numero_compte != null && props.companyInfos.advanceparams.cle_rib != null) ? <Text style={{marginBottom:'4'}}>{props.t('labelNumeroDeCompte')+' : '+props.companyInfos.advanceparams.code_banque+' '+props.companyInfos.advanceparams.code_guichet+' '+props.companyInfos.advanceparams.numero_compte+' '+props.companyInfos.advanceparams.cle_rib}</Text> : false}</Text>
            </View>

            <View style={styles.titleFooter} fixed> 
                <Text render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )}  />
                <Text>{props.t('generateFreebird')}</Text>
            </View>
            
            
        </Page>
    </Document>
    )
});

export default Facturation;
