import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {Form} from 'react-bootstrap'

const AddMarkExportForm = (props) => {
    const t = useTranslate();

    return(
        <Form>
            <Form.Check
                type="checkbox"
                label={t('apostropheBeginningCell')}
                checked={props.addMark}
                onChange={()=>props.setAddMark(!props.addMark)}
            />
        </Form>
    )
}

export default AddMarkExportForm;