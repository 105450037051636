import React, { useState, useEffect } from "react";
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";
import { promotionsDescription,promotions2Inputs,promotionsPrefixe } from 'app/allPromotions';
import { useForm } from "react-hook-form";
import CheckBoxShop from './Comp/CheckBoxShop'
import CheckBoxCompany from './Comp/CheckBoxCompany'

const useLoad = (modalEditId,setValue,setShowInputPromo,promoProfilId)=>{
    const t = useTranslate();
    const [loading,setLoading]=useState(false)
    const [promoOne,setPromoOne]=useState({})
    const [disabled,setDisabled]=useState(false)

    const [check,setCheck] = useState({
        all:false,
        list:false,
        exclude_article:false
    })

    //En cas d'édition, on récupère les infos de la promo concerné pour les attribuer aux champs 
    useEffect(function () {
        (async function() {
            if(modalEditId || promoProfilId){
                let idPromo = (modalEditId) ? modalEditId : promoProfilId
                setLoading(true)
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/promotion/"+idPromo
                ).then((response) => {
                    setPromoOne(response.data.data)
                    setShowInputPromo(response.data.data.type)
                    setValue('name',response.data.data.name)
                    setValue('poids',response.data.data.poids)
                    setValue('ticket_name',response.data.data.ticket_name)
                    setValue('type',response.data.data.type)

                    if(response.data.data.on_client==1){
                        setCheck({...check,exclude_article:response.data.data.exclude_article,list:true})
                    }else{
                        setCheck({...check,exclude_article:response.data.data.exclude_article,all:true})
                    }

                    if(promotions2Inputs.includes(response.data.data.type)){
                        setValue('value.0',response.data.data.value[0])
                        setValue('value.1',response.data.data.value[1])

                    }else{
                        let promoStr = ''
                        response.data.data.value.map(promoAmount=>{
                            if(promoStr == ''){
                                promoStr += promoAmount
                            }else{
                                promoStr += ','+promoAmount
                            }
                            
                        })
                        setValue('value',promoStr)
                    }
                    if(response.data.data.begin!=null){
                        let beginFormated = response.data.data.begin.split(/[-\s]+/)
                        setValue('begin',beginFormated[0]+'-'+beginFormated[1]+'-'+beginFormated[2])

                    }
                    if(response.data.data.end!=null){
                        let endFormated = response.data.data.end.split(/[-\s]+/)
                        setValue('end',endFormated[0]+'-'+endFormated[1]+'-'+endFormated[2])

                    }

                    setDisabled(true)
                    setLoading(false)
                }).catch((error) => {
                    if(error=='end_session') return;
                    let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                    swal.fire(t(errorName), "", "error");
                    setLoading(false)
                })
            }
        })()
    }, [])
    return [loading,promoOne,disabled,setDisabled,check,setCheck]
}


const CreatePromoForm = (props) =>{
    const t = useTranslate();
    const { register, formState: { errors }, handleSubmit, setValue, reset} = useForm();
    const [showInputPromo, setShowInputPromo]=useState(false)
    const [loading,promoOne,disabled,setDisabled,check,setCheck]=useLoad(props.modalEditId,setValue,setShowInputPromo,props.promoProfilId)
    const [validButton,setValidButton] = useState(false)
    const [apiError,setApiError] = useState(false)
    const [selectedCompany,setSelectedCompany] = useState([])
    
    const [error,setError] = useState({
        attribuer:false,
        client_list:false
    })
    
    let prefixePercent = (showInputPromo && showInputPromo=='percent') ? true : false
    let prefixePrixfixe = (showInputPromo && showInputPromo=='prixfixe') ? true : false

    let addOn = (prefixePercent) ? '%' : (prefixePrixfixe) ? 'XPF' : '-';

    let placeHolder2 = (showInputPromo == 'xpoury') ? t('qteItems') : t('discountPrice');

    const handleChange = (e) =>{
        setShowInputPromo(e.target.value)
        if(e.target.value!='percent' && e.target.value!='franche' && e.target.value!='prixfixe'){
            setCheck({
                ...check,
                exclude_article:false
            })
        } 
    }

    let editPromo = props.from=='profilPromo' ? props.promos.id : props.promos.find(value=>value.id==props.modalEditId)

    if(props.modalEditId){
        //On détermine l'index à éditer sur la modal edit 
        let refPromo = props.promos.map(value=>value.id)
        var indexToEdit = refPromo.indexOf(editPromo.id)
    }

    const handleChangeCheck = (name) => {
        if(name=='exclude_article'){
            setCheck({
                ...check,
                exclude_article:!check.exclude_article
            })
        }else if(name=='all'){
            setCheck({
                all:!check.all,
                list:false,
                exclude_article:check.exclude_article
            })
        }else{
            setCheck({
                all:false,
                list:!check.list,
                exclude_article:check.exclude_article
            })
        }
    };

    const onSubmit = async (data) => {
        let idPromo = (props.modalEditId) ? props.modalEditId : props.promoProfilId
        setValidButton(true)
        setApiError(false)
        setError({
            attribuer:false,
            client_list:false
        })
        
        // let arrayOtherCompany = (Array.isArray(data.other_company) && data.other_company.includes(props.company.id+'-company')) ? data.other_company.filter(value=>value!=props.company.id+'-company') : (Array.isArray(data.other_company) && !data.other_company.includes(props.company.id+'-company')) && data.other_company

        let companyPost = (Array.isArray(data.other_company) && data.other_company.includes(props.company.id+'-company')) ? props.company.id : data.other_company == props.company.id+'-company' ? props.company.id : null
        
        let otherCompanyPost 
        // = (!Array.isArray(data.other_company) && data.other_company!=props.company.id+'-company') ? [data.other_company] : (!Array.isArray(data.other_company) && data.other_company==props.company.id+'-company') ? [] : (data.other_company==false) ? [] : arrayOtherCompany

        if(Array.isArray(data.other_company) && data.other_company.includes(props.company.id+'-company')){
            //Si data.other_company est un array et que ce [] inclut l'id de la company
            //on retourne un [] sans l'id de la company
            otherCompanyPost = data.other_company.filter(value=>value!=props.company.id+'-company')

        }else if(Array.isArray(data.other_company) && !data.other_company.includes(props.company.id+'-company')){
            //Si data.other_company n'inclut pas l'id de la company
            otherCompanyPost = data.other_company

        }else if(!Array.isArray(data.other_company) && data.other_company!=props.company.id+'-company' && data.other_company!=false){
            //Si une seule other company et que ce n'est pas la company
            otherCompanyPost = [data.other_company]

        }else if((!Array.isArray(data.other_company) && data.other_company==props.company.id+'-company') || data.other_company==false){
            //Si la company a été choisi ou que aucune other company ni la company n'a été choisi
            otherCompanyPost = []
        }

        let obj={
            begin: data.begin,
            end: data.end,
            name: data.name,
            poids:data.poids,
            section: (data.section==false) ? [] : Array.isArray(data.section) ? data.section : [data.section],
            company: companyPost,
            other_company: otherCompanyPost,
            type: data.type,
            value: data.value,
            ticket_name:data.ticket_name,
            on_client: (check.all) ? "0" : "1",
            exclude_article:check.exclude_article
        }

        if(!check.all && !check.list){
            setError({...error,attribuer:true})

        }else{
            if(props.modalEditId || props.promoProfilId){//Edition d'une promotion
                const response  = await axios.put(process.env.REACT_APP_API_URL+"api/erp/promotion/edit/"+idPromo,obj
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach((interm)=>{
                            let finalMessage=interm.split('/!');
                            if(finalMessage.length==2){
                                messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                            }
                            else{
                                messageError+=t(interm)+'<br/>'
                            }
                        })
                        messageError=messageError.replace('undefined','');
                        setApiError(messageError)
                    }
                    else{
                        props.closeModal()
                        if(props.from=='profilPromo'){
                            props.setPromos(response.data.data)
                        }else{
                            let newData = props.promos.filter(value=>value.id!=props.modalEditId)
                            newData.splice(indexToEdit,0, response.data.data)
                            props.setPromos(newData)
                        }
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                    swal.fire(t(errorName), "", "error");
                })

            }else{//Création d'une promotion
                const response  = await axios.post(process.env.REACT_APP_API_URL+"api/erp/promotion/create",obj
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach((interm)=>{
                            let finalMessage=interm.split('/!');
                            if(finalMessage.length==2){
                                messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                            }
                            else{
                                messageError+=t(interm)+'<br/>'
                            }
                        })
                            messageError=messageError.replace('undefined','');
                            setApiError(messageError)
                    }
                    else{
                        props.closeModal()
                        props.setPromos([response.data.data,...props.promos])

                        // Ancien code
                        // if(props.currentPromo == showInputPromo){
                        //     props.setPromos([response.data.data,...props.promos])
                        // }
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                    swal.fire(t(errorName), "", "error");
                })
            }
        }
        setValidButton(false)
        setDisabled(false)
    }

    return(
        <>
            {loading ? 
                <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm"></div></span>
            :
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label htmlFor="nomcategorie">{t('labelName')+'* :'}</label>
                        <input id="promotion name"  className="form-control" type="text" {...register('name', {required: true})}/>
                        {errors.name?.type === 'required' && (  
                        <div className="text-danger mt-1 ml-2">
                            {t('nameRequired')}
                        </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label>{t('attribuerA')+'* :'}</label>
                        <div className="d-flex">
                            <div className="form-check">
                                <input checked={check.all} type="checkbox" className="form-check-input" onChange={() => handleChangeCheck('all')}/>
                                <label title="" className="form-check-label">{t('allCustomer')}</label>
                            </div>&nbsp;&nbsp;&nbsp;&nbsp;
                            <div className="form-check">
                                <input checked={check.list} type="checkbox" className="form-check-input"  onChange={() => handleChangeCheck('list')}/>
                                <label title="" className="form-check-label">{t('listOfCustomer')}</label>
                            </div>
                        </div>
                        {error.attribuer && (  
                        <div className="text-danger mt-1 ml-2">
                            {t('fieldRequired')}
                        </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="nomcategorie">{t('promoWeight')+' :'}</label>
                        <input placeholder={t('valueBetween')+' (1 - 100)'} className="form-control" type="number" {...register('poids', {min:1,max:100})}/>
                        {errors.poids?.type === 'min' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('poidsMin1')}
                        </div>
                        )}
                        {errors.poids?.type === 'max' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('poidsMax100')}
                        </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="nomcategorie">{t('titleOnReceipt')+' :'}</label>
                        <input id="promotion name"  className="form-control" type="text" {...register('ticket_name')}/>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="email">{t('shops')}</label>
                        {(Object.keys(props.company).length!=0 && props.company.section.length!=0) && props.company.section.map((section)=>
                            <div key={section.id} className="col-3">
                                <CheckBoxShop section={section} register={register} promoOne={promoOne} modalEditId={editPromo} />
                            </div>
                        )}
                    </div>
                    <div className="form-group row">
                        <label htmlFor="email">{t('company')}</label>
                        <div className="col-3">
                            <CheckBoxCompany otherCompany={props.company} register={register} promoOne={promoOne} modalEditId={editPromo} selectedCompany={selectedCompany} setSelectedCompany={setSelectedCompany} showInputPromo={showInputPromo} setShowInputPromo={setShowInputPromo} reset={reset} isCompany="true"/>
                        </div>
                        {(Object.keys(props.company).length!=0 && props.company.other_company.length!=0) && props.company.other_company.map((otherCompany)=>
                            <div key={otherCompany.id} className="col-3">
                                <CheckBoxCompany otherCompany={otherCompany} register={register} promoOne={promoOne} modalEditId={editPromo} selectedCompany={selectedCompany} setSelectedCompany={setSelectedCompany} showInputPromo={showInputPromo} setShowInputPromo={setShowInputPromo} reset={reset} isCompany="false"/>
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="type de promotion">Type* :</label>
                        <select  disabled={disabled} className="form-control" type="text"  {...register('type', {required: true, onChange: (e) => handleChange(e)})}>
                            <option value="">-- Select --</option>
                            {
                                props.allPromotions.map((value,ind)=>{
                                    if(selectedCompany.length!=0){
                                        if(value=='percent' || value=='franche' || value=='prixfixe'){
                                            return(
                                                <option key={ind} value={value}>{t(value)}</option>
                                            )
                                        }
                                    }else{
                                        return(
                                            <option key={ind} value={value}>{t(value)}</option>
                                        )
                                    }
                                })
                            }                               
                        </select>
                        {errors.type?.type === 'required' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('typeRequired')}
                        </div>
                        )}
                    </div>
                    {(!showInputPromo) ?
                        false
                    :
                        <div className="alert alert-warning mt-3" role="alert">
                            {t(promotionsDescription[showInputPromo])}
                        </div> 
                    }
                    {(!showInputPromo) ?
                        false
                    :
                        <>
                            {(showInputPromo=='percent' || showInputPromo=='franche' || showInputPromo=='prixfixe') &&
                                <div className="form-group">
                                    <div>
                                        <div className="form-check">
                                            <input checked={check.exclude_article} type="checkbox" className="form-check-input" onChange={() => handleChangeCheck('exclude_article')}/>
                                            <label title="" className="form-check-label">{t('itemsFromWillBeExcluded')}</label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {(promotions2Inputs.includes(showInputPromo)) ?
                                <>
                                    <label htmlFor="description de la promotion">{t('promotion')+'* :'}</label>
                                    <input  placeholder={t('qteItems')} className='form-control mb-2'  type="text" {...register('value.0', {required: true})} />
                                    <span className="mb-2 d-flex justify-content-center">{t('for')}</span>
                                    <input  placeholder={placeHolder2} className='form-control mb-2'  type="text" {...register('value.1', {required: true})} />
                                    {errors.value?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('fieldRequired')}
                                    </div>
                                    )}
                                    <div className="form-group">
                                        <label htmlFor="date de transfert">{t('beginPromo')+' :'}</label>
                                        <input  type='date' className="form-control" {...register('begin')}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="date de transfert">{t('endPromo')+' :'}</label>
                                        <input  type='date' className="form-control" {...register('end')}/>
                                    </div>
                                </>
                            :
                                <>
                                    <label htmlFor="description de la promotion">{t('promotion')+' :'}</label>
                                    <div className="input-group">
                                        {(promotionsPrefixe.includes(showInputPromo)) ?
                                            <div className="input-group-prepend">
                                                <div className="input-group-text rounded-0" >{addOn}</div>
                                            </div>
                                        :
                                            false
                                        }
                                        <input  className='form-control mb-2' type="text" aria-describedby="basic-addon1" {...register('value', {required: true})} />
                                    </div>
                                    {(showInputPromo=='suppercent') &&
                                        <div className="text-info ml-2">
                                        {t('suppercentInstruction')}
                                        </div>
                                    }
                                    {errors.value?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('fieldRequired')}
                                    </div>
                                    )}
                                    <div className="form-group">
                                        <label htmlFor="date de transfert">{t('beginPromo')+' :'}</label>
                                        <input  type='date' className="form-control" {...register('begin')}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="date de transfert">{t('endPromo')+' :'}</label>
                                        <input  type='date' className="form-control" {...register('end')}/>
                                    </div>
                                </>
                            }
                            {(apiError) ? <div className="alert alert-danger mt-3" dangerouslySetInnerHTML={{ __html: apiError }}></div> : false}
                        </>
                    }  
                    <button disabled={validButton} className="btn btn-primary mt-3">
                        {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                    </button>
                </form>
            }
        </>
    )
}

export default CreatePromoForm;