import React from 'react';
import { useTranslate } from 'react-redux-multilingual';
import { CardPerso} from "@gull";

const CustomInterfaceDetails = (props) => {
    const t = useTranslate();

    let dataLength = props.customInterfaceFiche.user_conf ? Object.keys(props.customInterfaceFiche.user_conf.details).length : props.customInterfaceFiche.default_conf && Object.keys(props.customInterfaceFiche.default_conf.details).length;

    let nbCardsDisplayed = (dataLength > 1) ? 'col-6' : 'col';

    let customInterfaceFiche=false;
    if(props.customInterfaceFiche.user_conf){
        customInterfaceFiche = props.customInterfaceFiche.user_conf.details
    }
    else if(props.customInterfaceFiche.default_conf){
        customInterfaceFiche = props.customInterfaceFiche.default_conf.details
    }

    return(
        <div className='row'>
            {(customInterfaceFiche) && 
                Object.entries(customInterfaceFiche).map(([cardName, fields], index) =>{
                    return(
                        <div className={nbCardsDisplayed} key={index}>
                            <CardPerso  header={cardName} >
                               <div className='row'>
                                    {fields.map((value, ind) => (
                                        <div key={ind} className='col-3'>
                                            <span className='text fw-bold'>
                                                {value+' :'}
                                            </span>
                                        {(props.contain.custom_fields && props.contain.custom_fields[value]) ?
                                            <div>{props.contain.custom_fields[value]}</div>
                                        :
                                            <div>--</div>   
                                        }
                                    </div>
                                    ))}    
                                </div>
                            </CardPerso>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default CustomInterfaceDetails;