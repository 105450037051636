import React, {useRef,useContext} from "react";
import { useTranslate } from 'react-redux-multilingual';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CSVDownloader } from 'react-papaparse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { IsAuthContext } from '../../../app/App';


const  CsvActionButton = (props) => {
    const t = useTranslate();
    const downloader = useRef('');
    const {authParam} = useContext(IsAuthContext)

    const goCsv = () =>{
        downloader.current.closest("button").click()
    }

    const constructData = (datas) => {
        let exportData = [];
        datas.map(value=>exportData.push({'data':value}))

        return exportData
    }

    return (<>
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip id={`tooltip-${props.index}`}>
                    {t('listDonnéesCsv')}
                </Tooltip>
            }
        >
            <span className="text-success" style={{cursor:'Pointer'}} onClick={() => goCsv()}><FontAwesomeIcon icon={faFileCsv} /></span>
        </OverlayTrigger> 
        <CSVDownloader
            data={() => constructData(props.listData)}
            type="button"
            filename={props.fieldName}
            bom={true}
            className="btn btn-dark btn-sm d-none"
            config={{
                delimiter:authParam.company.advanceparams.csv_separator,
                encoding:authParam.company.advanceparams.csv_encode,
                header: false
            }}
        >
            <span ref={downloader}>&nbsp;</span>
        </CSVDownloader>
    </>)
}

export default CsvActionButton;