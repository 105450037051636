import React, {useState,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import swal from "sweetalert2";
import { Accordion } from 'react-bootstrap';
import EmailAccountForm from './EmailAccountForm'

const useLoad = (from,id,relance) => {
    const t = useTranslate();
    const [emailList,setEmailList] = useState([]);

    useEffect(function () {
        (async function() {
            const response  = await axios.get(process.env.REACT_APP_API_URL+"api/mail/get_all_mail_config"
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach((interm)=>{
                        let finalMessage=interm.split('/!');
                        if(finalMessage.length==2){
                            messageError+=t(finalMessage[0],{name:finalMessage[1]})+','
                        }
                        else{
                            messageError+=t(interm)+','
                        }
                    })
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                }
                else{
                    if(from=='company'){
                        setEmailList(response.data.datas.filter(value=>value.other_company_id===null && value.section_id===null))

                    }else if(from=='other_company'){
                        setEmailList(response.data.datas.filter(value=>value.other_company_id==id.toString()))
                    }
                    else if(from=='section'){
                        setEmailList(response.data.datas.filter(value=>value.section_id==id.toString()))
                    }
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let messageError = (error.response && error.response.status==403) ? t('forbiddenGeneral') : t('unknowError')
                swal.fire(messageError, "", "error");
            })

        })()
    }, [relance])

    return [emailList,setEmailList,relance]
}

const EmailAccountList = (props) => {
    const t = useTranslate();
    const [relance,setRelance]=useState(Date.now())
    const [emailList,setEmailList] = useLoad(props.from,props.id,relance)

    const addEmailAccount = () => {
        setEmailList([...emailList,{}]);
    }

    return(<>
        <button className="btn btn-success btn-sm mb-2" onClick={() => addEmailAccount()}><FontAwesomeIcon icon={faPlus} /></button>&nbsp;
        <Accordion>
            {emailList.map((value,ind)=>{
                return(
                    <Accordion.Item key={ind} eventKey={ind}>
                        <Accordion.Header>{(value.address) ? value.address : t('newEmailAccount')}</Accordion.Header>
                        <Accordion.Body>
                            <EmailAccountForm 
                                email_account={value}
                                setRelance={setRelance}
                                from={props.from}
                                id={props.id}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                )
            })}
        </Accordion>
    </>
    )
}

export default EmailAccountList;