import React,{useState, useRef, useEffect, useContext} from "react";
import { ClientForm,InpDataList,CustomerRewards} from "@gull";
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus,faEdit,faGift } from '@fortawesome/free-solid-svg-icons'
import { Modal } from "react-bootstrap";
import axios from 'axios'
import SweetAlert from "sweetalert2-react";
import { IsAuthContext } from 'app/App';

const useSearchClients = (searchInputClient,setClientFideliteInfo,setShowBtnFidelite) => {
    const [loadingSearchClient,setLoadingSearchClient]=useState(false)
    const [generalErrorSearchClient,setGeneralErrorSearchClient]=useState(false)
    const [listSearchClient,setListSearchClient]=useState([])
    const [listSearchIdClient,setListSearchIdClient]=useState({})
    const [datas,setDatas]=useState([])
    //const searchInputClient = ('')

    const loadClients = async (toSearch) => {
        setLoadingSearchClient(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/client/name/"+toSearch
        ).then((response) => {
            let constructListSearchClientId = {}
            response.data.datas.map((client,i)=>{
                constructListSearchClientId={...constructListSearchClientId,[client.name+' '+client.firstname+' - '+client.email+' - '+client.tel]:client.id}
                
            })
            setListSearchIdClient(constructListSearchClientId)
            setListSearchClient(response.data.datas)
            let initial = []
            response.data.datas.forEach((value)=>
                initial.push({
                    id: value.id,
                    name: value.name+' '+value.firstname+' - '+value.email+' - '+value.tel
                })
            )
            setListSearchClient(initial)
            let ajour = initial.filter((value)=>value.name.toLowerCase().includes(searchInputClient.current.value.toLowerCase()))
            setDatas(ajour)
            setLoadingSearchClient(false)
        }).catch((error) => {
            if(error=='end_session') return;
            setLoadingSearchClient(false)
            setGeneralErrorSearchClient(true)
        })
    }

    const getFideliteInfo = async (clientId) => {
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/fidelite/get_fidelite_client/"+clientId
        ).then((response) => {
            setLoadingSearchClient(false)
            setClientFideliteInfo(response.data)
            if(response.data.data.length!=0){ 
                setShowBtnFidelite(true)
            }else{
                setShowBtnFidelite(false)
            }
        }).catch((error) => {
            setLoadingSearchClient(false)
            if(error=='end_session') return;
            setGeneralErrorSearchClient(true)
        })
    }

    return [loadingSearchClient,loadClients,generalErrorSearchClient,setGeneralErrorSearchClient,listSearchClient,setListSearchClient,listSearchIdClient,setListSearchIdClient,datas,setDatas,getFideliteInfo]
}

const ActionClientBarLeft = (props) =>{
    const t =useTranslate()
    const [loadingSearchClient,loadClients,generalErrorSearchClient,setGeneralErrorSearchClient,listSearchClient,setListSearchClient,listSearchIdClient,setListSearchIdClient,datas,setDatas,getFideliteInfo]=useSearchClients(props.searchInputClient,props.setClientFideliteInfo,props.setShowBtnFidelite)

    const [showModalAdd,setShowModalAdd] = useState(false)
    const [showModalEdit,setShowModalEdit] = useState(false)
    const [showModalFidelite,setShowModalFidelite]=useState(false) 
    const [clientEdit,setClientEdit] = useState(false)
    const [noClientToEdit,setNoClientToEdit] = useState(false)
    const [inputName,setInputName]=useState(false)
    const [lastSearch,setLastSearch]=useState(false) 
    const { authParam } = useContext(IsAuthContext);
    let currentShop = (props.module && props.module=='pos') ? authParam.sections.find(shop=>shop.id==props.posid) : false
    const moduleOption = authParam.modules.find(value=>value.name==props.module) //props.module qui est soit 'erp' soit 'pos'
    const clientRequired = (moduleOption.params.client.active && !Array.isArray(moduleOption.params.client.params) && moduleOption.params.client.params.hasOwnProperty('client_required') && moduleOption.params.client.params.client_required) ? true : false

    const dataListDropClient = useRef('')

    useEffect(function () {
        //Si un client par défaut est configuré, on le renseinge 
        if(props.section && props.section.default_client!=null){
            setInputName(props.section.default_client.name+' '+props.section.default_client.firstname+' - '+props.section.default_client.email+' - '+props.section.default_client.tel)
            setListSearchIdClient({[props.section.default_client.name+' '+props.section.default_client.firstname+' - '+props.section.default_client.email+' - '+props.section.default_client.tel]:props.section.default_client.id})
        }
    }, [props.section])

    useEffect(function () {
        if(props.from=='barLeft'){
            if(props.cookies.current_ticket){
                if(Object.keys(props.currentTicket).length!=0){
                    if(props.currentTicket.client!=null){
                        setListSearchClient([props.currentTicket.client])
                        setListSearchIdClient({[props.currentTicket.client.name+' '+props.currentTicket.client.firstname+' - '+props.currentTicket.client.email+' - '+props.currentTicket.client.tel]:props.currentTicket.client_id})
                    }
                }
            }
            if(Object.keys(props.currentTicket).length==0){
                if(props.section && props.section.default_client!=null){
                    setInputName(props.section.default_client.name+' '+props.section.default_client.firstname+' - '+props.section.default_client.email+' - '+props.section.default_client.tel)
                }else{
                    setInputName(false)
                }
            }
        }else if(props.from == 'giftcard'){
            if(props.cookies.current_ticket){
                if(Object.keys(props.currentTicket).length!=0){
                    if(props.currentTicket.client!=null){
                        props.setValueInputClient(props.currentTicket.client.name+' '+props.currentTicket.client.firstname+' - '+props.currentTicket.client.email+' - '+props.currentTicket.client.tel)
                        if(props.setInvalidInput) props.setInvalidInput({...props.invalidInput,searchClient:false})
                        setListSearchIdClient({[props.currentTicket.client.name+' '+props.currentTicket.client.firstname+' - '+props.currentTicket.client.email+' - '+props.currentTicket.client.tel]:props.currentTicket.client_id})
                    }
                }
            }
        }else if(props.from=='erp'){
            if(Object.keys(props.currentTicket).length!=0){
                if(props.currentTicket.client!=null){
                    setListSearchClient([props.currentTicket.client])
                    setListSearchIdClient({[props.currentTicket.client.name+' '+props.currentTicket.client.firstname+' - '+props.currentTicket.client.email+' - '+props.currentTicket.client.tel]:props.currentTicket.client_id})
                    setInputName(props.currentTicket.client.name+' '+props.currentTicket.client.firstname+' - '+props.currentTicket.client.email+' - '+props.currentTicket.client.tel)
                }
            }
        }
    }, [props.currentTicket])

    useEffect(function () {
        //Si la page est rechargé alors qu'un ticket existe et qu'un client à été sélectionné, on récupère ses informations de fidélité
        if(props.from=='barLeft' && moduleOption.params.fidelite.active){
            // if(props.cookies.current_ticket){
            //     if(Object.keys(props.currentTicket).length!=0){
            //         if(props.currentTicket.client!=null){
            //             setLoadingSearchClient(true)
            //             getFideliteInfo(props.currentTicket.client_id)
            //         }
            //     }
            // }
            if(props.clientFideliteInfo && props.clientFideliteInfo.data.length!=0){ 
                props.setShowBtnFidelite(true)
            }else{
                props.setShowBtnFidelite(false)
            }
        }
    }, [props.clientFideliteInfo])

    const toggleAlertSearchClient = () => {
        setGeneralErrorSearchClient(false)
    }

    const toggleNoClientToEdit = () => {
        setNoClientToEdit(false)
    }

    const editClientModal = () => {
        if(props.clientId && props.from=="giftcard"){
            //si on edit un client depuis la modal d'ajout de gift card
            setClientEdit(props.clientId);
            setShowModalEdit(true)
        }else if(listSearchIdClient[props.valueInputClient]){
            setClientEdit(listSearchIdClient[props.valueInputClient]);
            setShowModalEdit(true)

        }else{
            setNoClientToEdit(true)
        }
        
    }

    const showModalAddNewCustomer = () => {
        setShowModalAdd(true)
        
    }

    const handleSearch = (e,declenchement) => {
        props.setValueInputClient(e.target.value)

        if(e.target.value.length>=declenchement){
            dataListDropClient.current.classList.remove("d-none");

            if(!lastSearch || e.target.value.substring(0, declenchement) != lastSearch.substring(0, declenchement)){
                loadClients(e.target.value.substring(0, declenchement))
                setLastSearch(e.target.value.substring(0, declenchement))
            }
        }else{
            dataListDropClient.current.classList.add("d-none");
            setLastSearch(false)
        }
        if(e.target.value.length!=declenchement){
            let ajour = listSearchClient.filter((value)=>value.name.toLowerCase().includes(e.target.value.toLowerCase()))
            setDatas(ajour)
        }
    }

    const handleCLick = (id,valeur) => {
        props.setValueInputClient(valeur)
        if(props.setInvalidInput) props.setInvalidInput({...props.invalidInput,searchClient:false})
        if(props.setClientId) props.setClientId(id)
        if(props.from == 'barLeft' || props.from=='ParymentFormLeft'){
            if(props.cookies && props.cookies.current_ticket){
                props.updateCurentTicket('searchClient',id)
            }
            if(moduleOption.params.fidelite.active && props.from == 'barLeft'){
                getFideliteInfo(id)
            }
        }else if(props.from == 'erp'){
            props.updateCurentTicket('searchClient',id)
        }

        setInputName(valeur)
    }

    const handleBlur = () => {
        if(props.cookies && props.cookies.current_ticket){
            if(props.from=='giftcard'){
                //Dans le cas où il y a un ticket en cours et qu'on est dans le formulaire d'ajout d'une gift card
                if(inputName){
                    props.setValueInputClient(inputName)
                    if(props.setInvalidInput) props.setInvalidInput({...props.invalidInput,searchClient:false})
                }else{
                    props.setValueInputClient('')
                    if(props.setInvalidInput) props.setInvalidInput({...props.invalidInput,searchClient:true})
                }
            }else if(Object.keys(props.currentTicket).length!=0){
                if(props.currentTicket.client!=null){
                    props.setValueInputClient(props.currentTicket.client.name+' '+props.currentTicket.client.firstname+' - '+props.currentTicket.client.email+' - '+props.currentTicket.client.tel)
                    if(props.setInvalidInput) props.setInvalidInput({...props.invalidInput,searchClient:false})
                    if(moduleOption.params.fidelite.active && props.clientFideliteInfo && props.clientFideliteInfo.data.length!=0 && props.from=='barLeft') props.setShowBtnFidelite(true)
                }else{
                    props.setValueInputClient('')
                    if(props.setInvalidInput) props.setInvalidInput({...props.invalidInput,searchClient:true})
                }
            }
        }else{
            if(inputName){
                props.setValueInputClient(inputName)
                if(props.setInvalidInput) props.setInvalidInput({...props.invalidInput,searchClient:false})
                if(moduleOption.params.fidelite.active && props.clientFideliteInfo && props.clientFideliteInfo.data.length!=0 &&props.from=='barLeft') props.setShowBtnFidelite(true)
            }else{
                props.setValueInputClient('')
                if(props.setInvalidInput) props.setInvalidInput({...props.invalidInput,searchClient:true})
                if(props.setShowBtnFidelite) props.setShowBtnFidelite(false)
            }
        }

        if(props.from=='barLeft') props.setRelanceFocus(Date.now())
        
        dataListDropClient.current.classList.add("d-none");
    }
    
    const closeModal = (name) =>{
        if(name=='add'){
            setShowModalAdd(false)
        }else{
            setShowModalEdit(false)
        }
        if(props.from=='barLeft')props.setRelanceFocus(Date.now())
    }

    return(
        <>
            <Modal size="xl" show={showModalAdd} onHide={() => closeModal('add')}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('addClient')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ClientForm 
                        setListSearchIdClient={setListSearchIdClient}
                        setClientNameInput={props.setValueInputClient}
                        invalidInput={props.invalidInput}
                        setInvalidInput={props.setInvalidInput}
                        clientid={false}
                        
                        setClientId={props.setClientId}
                        cookies={props.cookies}
                        updateCurentTicket={props.updateCurentTicket}
                        from='barLeft'
                        otherModule={props.from}
                        closeModal={closeModal}
                    />
                </Modal.Body>
            </Modal>
            <Modal size="xl" show={showModalEdit} onHide={() => closeModal('edit')}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('editClient')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ClientForm 
                        setListSearchIdClient={setListSearchIdClient}
                        setClientNameInput={props.setValueInputClient}
                        invalidInput={props.invalidInput}
                        setInvalidInput={props.setInvalidInput}
                        clientid={clientEdit}
                        closeModal={closeModal}
                    />
                </Modal.Body>
            </Modal>
            <Modal size="xl" show={showModalFidelite} onHide={() => setShowModalFidelite(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('customerReward')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="text-center fw-bold mb-2">
                            <span className="fs-3">{t('accumulatedPoints')+' : '}</span>
                            <span className="fs-2">{(props.clientFideliteInfo) ? props.clientFideliteInfo.total_point_fidelite_client : ''}</span>
                        </div>
                        <CustomerRewards 
                            clientFideliteInfo={props.clientFideliteInfo}
                            posid={props.posid}
                            setDisabledPay={props.setDisabledPay}
                            setRelanceFocus={props.setRelanceFocus}
                            setDisabledCloudButton={props.setDisabledCloudButton}
                            clientId={props.clientId}
                            cashierId={props.cashierId}
                            cookies={props.cookies}
                            setCookie={props.setCookie}
                            currentTicket={props.currentTicket}
                            setCurrentTicket={props.setCurrentTicket} 
                            affichageClient={props.affichageClient}
                            valueInputClient={props.valueInputClient}
                            setShowModalFidelite={setShowModalFidelite}
                            from='actionClientBarLeft'
                        />
                    </>
                </Modal.Body>
            </Modal>
            <div className={props.style}>
                <InpDataList
                    placeholder={t('customerPlacehlder')}
                    value={props.valueInputClient}
                    onClick={handleCLick}
                    onChange={handleSearch}
                    datas={datas}
                    declenchement={3}
                    listRef={dataListDropClient}
                    inputRef={props.searchInputClient}
                    onBlur={handleBlur}
                    inputName="searchClient"
                    from='clientSearch'
                    padOptionPosition={(currentShop && currentShop.advanceparams.hasOwnProperty('format_pad') && currentShop.advanceparams.format_pad=='bigPad') ? {left:'-30%'} : false}
                    className={(clientRequired && props.invalidInput && props.invalidInput.searchClient) ? "form-control is-invalid" : "form-control"}
                    onFocus={props.noRedInput}
                />
                {(props.load.client) ?
                    <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm topBarLeftPosition"></div></span>
                :
                    false
                }
            </div>
            <div className="col m-0 p-0">
                {(loadingSearchClient) ?
                    <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm mt-2"></div></span>
                    :
                    <>
                        {(props.showBtnFidelite)&&<><button className="btn btn-dark btn-sm sizeBtnPos btnFidelite" onClick={()=>setShowModalFidelite(true)}><FontAwesomeIcon icon={faGift} /></button>&nbsp;</>}
                        <button className="btn btn-success btn-sm sizeBtnPos" onClick={() => showModalAddNewCustomer()}><FontAwesomeIcon icon={faPlus} /></button>&nbsp;
                        <button className="btn btn-warning btn-sm sizeBtnPos" onClick={() => editClientModal()}><FontAwesomeIcon icon={faEdit} /></button>&nbsp;
                    </>
                }
            </div>
            {(generalErrorSearchClient) &&
                <SweetAlert show="true" title={t('unknowError')} type="error" onConfirm={() => toggleAlertSearchClient()}/>
            }
            {(noClientToEdit) &&
                    <SweetAlert show="true" title={t('noClientToEdit')} type="error" onConfirm={() => toggleNoClientToEdit()}/>
            }
        </>
    )
}

export default ActionClientBarLeft;