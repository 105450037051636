import React, {useState, useEffect,useRef} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {InpDataList} from "@gull";
import axios from 'axios';
import swal from "sweetalert2";

const useLoad = (documentType) =>{
    const t = useTranslate();
    const [loadingFournisseur,setLoadingFournisseur]=useState(false)
    const [fournisseur,setFournisseur]=useState([])

    useEffect(function () {
        (async function() {
            if(fournisseur.length==0 && documentType=='billingBuy'){
                setLoadingFournisseur(true)
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/fournisseur/all"
                ).then((response) => {
                    setFournisseur(response.data.datas)
                    
                }).catch((error) => {
                    if(error=='end_session') return;
                    let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                    swal.fire(t(errorName), "", "error");
                    console.log('rgrgr',error)
                })
                setLoadingFournisseur(false)
            }
        })()
    }, [documentType])

    return [loadingFournisseur,fournisseur]
} 

const SelectFournisseur = (props) =>{
    const t = useTranslate();
    const [loadingFournisseur,fournisseur] = useLoad(props.documentType)
    const [valueInputFournisseur,setValueInputFournisseur] = useState()
    const [datas,setDatas]=useState([])

    const searchInputClient = useRef('')
    const dataListDropClient = useRef('')

    const handleSearch = (e,declenchement) => {
        setValueInputFournisseur(e.target.value)
        if(e.target.value!=''){
            dataListDropClient.current.classList.remove("d-none");
            let ajour = fournisseur.filter((value)=>value.name.toLowerCase().includes(e.target.value.toLowerCase()))
            setDatas(ajour)
        }else{
            dataListDropClient.current.classList.add("d-none");
        }
    }

    const handleCLick = async (id,valeur) => {
        setValueInputFournisseur(valeur)
        props.setFournisseurName(valeur)
        dataListDropClient.current.classList.add("d-none");
        setDatas([]) 
        props.setFournisseurId(id)
    }

    return (
        <div>
            {loadingFournisseur ?
                <div className='mt-2'>&nbsp;<div className="spinner-border spinner-border-sm"></div></div>
            :
                <InpDataList
                    placeholder={t('fournisseur')}
                    value={valueInputFournisseur}
                    onClick={handleCLick}
                    onChange={handleSearch}
                    datas={datas}
                    declenchement={3}
                    listRef={dataListDropClient}
                    inputRef={searchInputClient}
                />  
            }
        </div>
    )
}

export default SelectFournisseur;