import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Moment from 'react-moment';
import { modePaiementMustBeTranslate,modePaiementFidelite } from 'app/modePaiementDefaultAll';
var JsBarcode = require('jsbarcode');


// Create styles
const styles = StyleSheet.create({
    logo: {
        margin:'auto',
        height:100,
        width:100,
    },
    shopName: {
        textAlign:'center',
        fontSize: 10,
    },
    infoShop:{
        textAlign:'center',
        fontSize: 8,
    },
    infoTicket:{
        textAlign:'left',
        fontSize: 8,
    },
    detailTicket:{
        textAlign:'left',
        fontSize: 8,
        marginBottom:5,
    },
    detailTaxes:{
        textAlign:'left',
        fontSize: 8,
    },
    codebar: {
        justifyContent: 'center',
        marginTop: 1,
        marginBottom:10
    }
   
  });


  // Create Document Component
const TicketCommande = (props) => {
    let canvas;
    // For Barcode
    canvas = document.createElement('canvas');
    JsBarcode(canvas, props.ticketPdf.bar, {
        width: 2,
        height: 65,
        displayValue: true,
        
    });
    const barcode = canvas.toDataURL();

    let logo = (props.thisShop) ? process.env.REACT_APP_API_URL+'images/'+props.thisShop.logo : false

    let textInferieur = (props.thisShop && props.thisShop.infos_inferieur_ticket!=null && props.thisShop.infos_inferieur_ticket!='') ? props.thisShop.infos_inferieur_ticket.split('\n') : []

    function espace_mille(nombre){
        if(nombre<=999) return nombre.toString();
        if(nombre<=999999) return nombre.toString().slice(0,nombre.toString().length-3)+' '+nombre.toString().slice(-3);
        if(nombre<=999999999) return nombre.toString().slice(0,nombre.toString().length-6)+' '+nombre.toString().slice(-6,-3)+' '+nombre.toString().slice(-3);
    }

    let clientName = (props.ticketPdf.ticket.client!=null && props.ticketPdf.ticket.client.name!=null && props.ticketPdf.ticket.client.firstname!=null) ? props.ticketPdf.ticket.client.name+' '+props.ticketPdf.ticket.client.firstname : (props.ticketPdf.ticket.client!=null && props.ticketPdf.ticket.client.name!=null) ? props.ticketPdf.ticket.client.name : ''
    let clientMoreInfo = (props.ticketPdf.ticket.client!=null && props.ticketPdf.ticket.client.tel!=null) ? ' / '+props.ticketPdf.ticket.client.tel : ''

    return(
        <Document>
            <Page size={[215]} style={{padding:'15px'}}>
                {/* Logo part */}
                {(logo) ? 
                    <View style={styles.logo}>
                        <Image  src={logo}/>
                    </View>
                :
                    false
                }

                {/* Shop infos part */}
                {(props.thisShop && props.thisShop.infos_superieur_ticket!=null) ?
                    <View style={styles.infoShop}>
                        {props.thisShop.infos_superieur_ticket.map((value,ind)=>{
                            return(
                                <Text style={{marginBottom:2}} key={ind}>{value}</Text>
                            )
                        })}                    
                    </View>
                :
                    false
                }

                <View style={styles.infoShop}>
                    <Text style={{marginBottom:6,marginTop:6,fontSize:5}}>___________________________________________________________________</Text>

                    {(props.thisShop.interface=='restauration') ? <Text style={{marginBottom:6,textAlign:'center',fontSize:20}}>{props.ticketPdf.numero}</Text> : false}

                    <Text style={{marginBottom:1,textAlign:'left'}}>{<Moment format="DD/MM/YYYY HH:mm:ss">{props.ticketPdf.created_at}</Moment>}</Text>
                    <Text style={{marginBottom:1,textAlign:'left'}}>{props.t('salesOrder')+' : '+props.ticketPdf.bar}</Text>
                    <Text style={{marginBottom:1,textAlign:'left'}}>{(props.ticketPdf.ticket.rh!=null) ? props.t('cashier')+' : '+props.ticketPdf.ticket.rh.name+' '+props.ticketPdf.ticket.rh.firstname : false}</Text>
                    <Text style={{textAlign:'left'}}>{(props.ticketPdf.ticket.client!=null) ? props.t('customer')+' : '+clientName+clientMoreInfo : false}</Text>
                    <Text style={{textAlign:'left'}}>{(props.ticketPdf.section_collect!=null) ? props.t('collectZone')+' : '+props.ticketPdf.section_collect.name : false}</Text>
                    {(props.ticketPdf.prevu )!= null ?
                        <Text style={{textAlign:'left'}}>{props.t('estimatedDelivery')+' : '}<Moment format="DD/MM/YYYY HH:mm:ss">{props.ticketPdf.prevu}</Moment></Text>
                    :
                        false
                    }

                    <Text style={{marginBottom:6,marginTop:6,fontSize:5}}>___________________________________________________________________</Text>
                </View>

                {props.ticketPdf.ticket.ticket_detail.map(value=>{
                    if(value.block_quantity>0){
                        return(
                            <View key={value.id} style={styles.detailTicket}>
                                {/* Product name part */}
                                <Text style={{textAlign:'left'}}>{(value.article!=null) ? value.article.sku+' '+value.article.name : false}</Text>

                                {/* Detail price and taxes part */}
                                {(value.promotion_intitule && value.promotion_intitule!=null) ? 
                                    <View>
                                        <View style={{justifyContent: 'space-between',flexDirection:'row'}}>
                                            <Text>{(value.article!=null) ? value.tgc+'%' : ' '}</Text>

                                            <Text>{(value.article!=null) ? espace_mille(value.unitaire)+props.devise : false}</Text>

                                            <Text>{'x'+value.quantity}</Text>

                                            <Text style={{color:'white'}}>{(value.remise!=0) ? '-'+value.remise : '0'}</Text>

                                            <Text style={{color:'white'}}>{espace_mille(Math.abs(value.prix_remise))+props.devise}</Text>
                                        </View>
                                        <View style={{justifyContent: 'space-between',flexDirection:'row',marginTop:'2'}}>
                                            <Text>{value.promotion_intitule}</Text>

                                            <Text>{(value.remise!=0) ? '-'+value.remise : '0'}</Text>

                                            <Text>{espace_mille(Math.abs(value.prix_remise))+props.devise}</Text>
                                        </View>
                                    </View>
                                :
                                    <View>
                                        <View style={{justifyContent: 'space-between',flexDirection:'row'}}>
                                            <Text>{(value.article!=null) ? value.tgc+'%' : ' '}</Text>

                                            <Text>{(value.article!=null) ? espace_mille(value.unitaire)+props.devise : false}</Text>

                                            <Text>{'x'+value.quantity}</Text>

                                            <Text>{(value.remise!=0) ? '-'+value.remise : '0'}</Text>

                                            <Text>{espace_mille(Math.abs(value.prix_remise))+props.devise}</Text>
                                        </View> 
                                    </View>
                                }
                                {/* Note part */}
                                <Text style={{textAlign:'left'}}>{
                                    (value.note != null && value.note != '' && value.ecotaxe != null && value.ecotaxe != '' && +value.ecotaxe > 0) ? props.t('dont')+' '+props.t('ecotaxe').toLowerCase()+': '+espace_mille(Math.round(value.ecotaxe))+props.devise+'\n'+value.note.replace(/<[^>]*>/g, '') : 
                                    (value.ecotaxe != null && value.ecotaxe != '' && +value.ecotaxe > 0) ? props.t('dont')+' '+props.t('ecotaxe').toLowerCase()+': '+espace_mille(Math.round(value.ecotaxe))+props.devise : 
                                    (value.note != null && value.note != '') ? value.note.replace(/<[^>]*>/g, '') : false
                                }</Text>
                            </View>
                        )
                    }
                })}
                
                <View>
                    <View>
                        <View style={styles.detailTaxes}>
                            <Text style={{marginBottom:6,marginTop:6,fontSize:5}}>___________________________________________________________________</Text>

                            <View style={{flexDirection:'row',justifyContent:'space-between',marginBottom:3}}>
                                <Text>Total TTC : </Text>
                                <Text>{espace_mille(props.totalAchat)+props.devise}</Text>
                            </View>
                        </View>
                        <View style={styles.detailTaxes}>
                            <Text style={{marginBottom:6,marginTop:6,fontSize:5}}>Taxes _____________________________________________________________</Text>
                            {(Object.keys(props.tgcForTicketPdf).length!=0) ? 
                                Object.entries(props.tgcForTicketPdf).map(([tgcName,tgcAmount])=>(
                                    <View key={tgcName} style={{flexDirection:'row',justifyContent:'space-between',marginBottom:3}}>
                                        <Text>{'TGC '+tgcName+'%'+' :' }</Text>
                                        <Text>{espace_mille(tgcAmount)+props.devise}</Text>
                                    </View>
                                ))
                            :
                                false
                            }
                            <View style={{flexDirection:'row',justifyContent:'space-between',marginBottom:3}}>
                                <Text>Total TGC : </Text>
                                <Text>{espace_mille(props.tgcTotal)+props.devise}</Text>
                            </View>
                        </View>
                    </View>
                    
                    <View style={styles.detailTaxes}>
                        <Text style={{marginBottom:6,marginTop:6,fontSize:5}}>Acompte versé ______________________________________________________</Text>

                        {props.ticketPdf.ticket.paiement_records.length!=0 ? 
                            props.ticketPdf.ticket.paiement_records.map(value=>{
                                // <View key={value.id}>
                                //     <View key={value.id} style={{flexDirection:'row',marginBottom:3,justifyContent:'space-between'}}>
                                //         <Text>{(modePaiementMustBeTranslate.includes(value.mode)) ? props.t(value.mode) : value.mode}</Text>
                                //         <Text>{espace_mille(value.montant)+props.devise}</Text>
                                //     </View>
                                //     {(value.mode=='espece' && +value.rendu!=0) ? 
                                //         <View style={{textAlign:'right',fontSize:7}}>
                                //             <Text>{props.t('deposited')+' : '+espace_mille(value.verse)+props.devise}</Text>
                                //             <Text>{props.t('changeBack')+' : '+espace_mille(value.rendu)+props.devise}</Text>
                                //         </View> 
                                //     :  <Text></Text>}
                                // </View>
                                if(props.t(value.mode)){
                                    if(props.t(value.mode).length<25){
                                        return(
                                        <View key={value.id}>
                                            <View key={value.id} style={{flexDirection:'row',marginBottom:3,justifyContent:'space-between'}}>
                                                <Text>{modePaiementFidelite.includes(value.mode) ? props.t('loyalty') : (modePaiementMustBeTranslate.includes(value.mode)) ? props.t(value.mode) : value.mode}</Text>
                                                <Text>{espace_mille(value.montant)+props.devise}</Text>
                                            </View>
                                            {(value.mode=='espece' && +value.rendu!=0) ? 
                                                <View style={{textAlign:'right',fontSize:7}}>
                                                    <Text>{props.t('deposited')+' : '+espace_mille(value.verse)+props.devise}</Text>
                                                    <Text>{props.t('changeBack')+' : '+espace_mille(value.rendu)+props.devise}</Text>
                                                </View> 
                                            : <Text></Text>}
                                        </View>)
                                    }else{
                                        return(
                                        <View key={value.id}>
                                            <Text>{modePaiementFidelite.includes(value.mode) ? props.t('loyalty') : (modePaiementMustBeTranslate.includes(value.mode)) ? props.t(value.mode) : value.mode}</Text>
                                            <Text style={{textAlign:'right'}}>{espace_mille(value.montant)+props.devise}</Text>
                                        </View>)
                                    }
                                }else{
                                    if(value.mode.length<25){
                                        return(
                                        <View key={value.id} style={{flexDirection:'row',marginBottom:3,justifyContent:'space-between'}}>
                                            <Text>{value.mode}</Text>
                                            <Text>{espace_mille(value.montant)+props.devise}</Text>
                                        </View>)
                                    }else{
                                        return(
                                        <View key={value.id}>
                                            <Text>{value.mode}</Text>
                                            <Text style={{textAlign:'right'}}>{espace_mille(value.montant)+props.devise}</Text>
                                        </View>)
                                    }
                                }
                            })
                        :
                            <View style={{flexDirection:'row',marginBottom:3,textAlign:'right'}}>
                                <Text>{0+props.devise}</Text>
                            </View>
                        }
                    </View>
                </View>
                {(props.ticketPdf.note!=null) ?
                    <View style={styles.detailTaxes}>
                        <Text style={{marginBottom:6,marginTop:6,fontSize:5}}>Note ______________________________________________________________</Text>

                        <Text style={{textAlign:'left',marginBottom:6}}>{props.ticketPdf.note.replace(/<[^>]*>/g, '')}</Text>
                    </View>
                :
                    false
                }

                <View style={styles.detailTaxes}>
                    <Text style={{marginBottom:6,marginTop:6,fontSize:5}}>___________________________________________________________________</Text>

                    {textInferieur.length!=0 &&
                        <View style={{textAlign:'center',marginBottom:6}}>
                            {textInferieur.map((value,ind)=>{
                                if(value!=''){
                                    return(
                                        <Text key={ind}>{value}</Text>
                                    ) 
                                }else{
                                    return(
                                        <Text key={ind} style={{marginBottom:3}}></Text>
                                    )
                                }
                            })}
                        </View>
                    }
                    
                    <Text style={{textAlign:'center'}}>{(props.thisShop && props.thisShop.name!=null) ? props.thisShop.name : false}</Text>
                    <Text style={{textAlign:'center',marginBottom:6}}>{props.t('messageEndReceipt')}</Text>

                    <Image style={styles.codebar} src={barcode}/>

                </View>
                
            </Page>
        </Document>
    )
};

export default TicketCommande;