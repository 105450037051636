import React from 'react';
import { Page, Image, Document, StyleSheet, Text } from '@react-pdf/renderer';

// Convertir les centimètres en points pour le PDF (1 cm = 28.35 points)
const cmToPt = (cm) => cm * 28.35;

const Etiquette = ({ imageData, widthCm, heightCm }) => (
  <Document>
    <Page 
        size={{ width: cmToPt(widthCm), height: cmToPt(heightCm) }} // Utiliser les dimensions en points
        style={{ padding: 0, backgroundColor: '#ffffff' }}
    >
      {imageData ? (
        <Image 
            src={imageData}
            style={{
                width: '100%', // Occuper toute la largeur
                height: '100%', // Occuper toute la hauteur
                objectFit: 'cover', // Adapter l'image pour couvrir toute la surface
              }}
        />
      ) : (
        <Text>Aucune image générée</Text>
      )}
    </Page>
  </Document>
);

export default Etiquette;
