import React, { useState, useContext, useEffect,  useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslate } from 'react-redux-multilingual';
import { IsAuthContext } from 'app/App';
import ExportDatatablePdf from '../../../app/views/Pdf/ExportDatatablePdf';

const GeneratePdfExport = (props) => {
    const t = useTranslate();
    const [orientationPdf, setOrientationPdf] = useState("landscape");
    const { authParam } = useContext(IsAuthContext);
    const iframeRef = useRef(null);

    const filteredColumnShow = props.columnShow.filter(value => !['actions', 'action', 'masque'].includes(value));
    const filteredColumnOrganize = props.columnOrganize.filter(item => !['actions', 'action', 'masque'].includes(item.dataField));
    useEffect(function () {
        (async function() {
            const pdfBlob = await ExportDatatablePdf({
                orientationPdf,
                t,
                dataHeadPdfExport: props.dataHeadPdfExport,
                columnShow: filteredColumnShow,
                columnOrganize: filteredColumnOrganize,
                bodyData: props.bodyData,
                typeDocument: props.typeDocument,
                authParam,
            });
    
            const pdfUrl = URL.createObjectURL(pdfBlob);
            if (iframeRef.current) {
                iframeRef.current.src = pdfUrl;
            }
        })()
    }, [props.columnOrganize,orientationPdf])

    return (
        <div className='mb-2'>
            <div>
                <button
                    className={`btn btn-sm ${orientationPdf === 'landscape' ? 'btn-success' : 'btn-outline-success'} mb-3`}
                    onClick={() => setOrientationPdf("landscape")}>
                    {t('landscape')}
                </button>
                &nbsp;&nbsp;
                <button
                    className={`btn btn-sm ${orientationPdf === 'portrait' ? 'btn-warning' : 'btn-outline-warning'} mb-3`}
                    onClick={() => setOrientationPdf("portrait")}>
                    {t('portrait')}
                </button>
            </div>
            <div style={{ height: '80vh' }}>
                <iframe
                    ref={iframeRef}
                    width="100%"
                    height="97%"
                    title="Aperçu du PDF"
                    style={{ border: 'none', marginTop: '20px' }}
                ></iframe>
            </div>
        </div>
    );
};

export default GeneratePdfExport;
