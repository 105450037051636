import React,{useState, useEffect,useContext,useRef} from "react";
import { useTranslate } from 'react-redux-multilingual'
import {FormatedPrice,PaymentCompleted} from "@gull"
import axios from 'axios'
import { modePaiementCb } from 'app/modePaiementCb';
import { modePaiementDefaultAll } from 'app/modePaiementDefaultAll';
import swal from "sweetalert2";
import DetailGiftCard from "./PaymentLeftComp/DetailGiftCard";
import CheckPayForm from "./PaymentLeftComp/CheckPayForm"
import {askTpe} from 'app/tpe'
import { PeripheriqueContext } from 'app/App';
import {DevpersoMaster} from "@devperso"
const CancelToken = axios.CancelToken;


const PaymentFormLeft =  (props) =>{
    const t =useTranslate()
    const [giftCardVal,setGiftCardVal]=useState('')
    const [giftCardId,setGiftCardid]=useState(false)
    const [infosGiftCard,setInfosGiftCard]=useState({})
    const [noFund,setNoFund]=useState(false)
    const [modePaiementVal,setModePaiementVal]=useState()
    const [amountVal,setAmountVal]=useState('')
    const [checkNumVal,setCheckNumVal]=useState('')
    const [nomTireur,setNomTireur]=useState('')
    const [depositVal,setDepositVal]=useState('')
    const [bankName,setBankName]=useState('')
    const [refoundGiftCard,setRefoundGiftCard]=useState('')
    const [modePay, setModePay]=useState(false)
    const [errorPay,setErrorPay]=useState(false)
    const { peripheriqueParam } = useContext(PeripheriqueContext)  
    const [waitTpe,setWaitTpe]=useState(false)
    const [errorTpe,setErrorTpe]=useState(false)
    const [load,setLoad]=useState({
        btnAdd:false,
        scanGiftCard:false,
        tpeAdd:false,
        tpeCancel:false
    })
    const idsLocalStorage=localStorage.getItem("pos").split(',')
    const cancelTpeReq = useRef(null);

    let currentShop = props.authParam.sections.find(shop=>shop.id == props.posid)
    
    useEffect(()=>{//pour le foccus sur l'input scan giftcard
        const input = document.querySelector('#giftCardScan');
        if(props.isFor == 'giftcard'){
            setTimeout(()=>{
                input.focus()
            },400)
            
        }

        if(props.isFor=='card' || props.isFor=='other'){
            //dans les méthodes de paiement card et other il existe plusieurs modes de paiements
            setModePay(modePaiementVal)
        }else{
            setModePay(props.isFor)
        }

    },[props.isFor,modePaiementVal])

    useEffect(()=>{//si on à commencé à renseigner un mode de paiement et qu'on change de mode paiement, il faut reset les infos indiqué avant 
        setAmountVal('')
        setDepositVal('')
        setGiftCardVal('')
        setInfosGiftCard({})
        setCheckNumVal('')
        setNomTireur('')
        setBankName('')
        setModePaiementVal((props.isFor == 'card' && currentShop.modepaiement != null && currentShop.modepaiement.filter(item => modePaiementCb.includes(item)).length==1) ? currentShop.modepaiement.filter(item => modePaiementCb.includes(item))[0]:'')

    },[props.currentPayment])

    const handleChange = (e,name) =>{
        switch (name) {
            case 'giftcard':
                setGiftCardVal(e.target.value)
                setGiftCardid(false)
                setInfosGiftCard({})
                setAmountVal('')
                setDepositVal('')
            break;
            case 'modepaiement':
                setModePaiementVal(e.target.value)
            break;
            case 'amount':
                setAmountVal(e.target.value.replace(/\D/g,''))
                setDepositVal(e.target.value.replace(/\D/g,''))
                if(props.isFor=='other'){//Si règlement par carte cadeau par exemple et que le montant de celle ci est supérieur à celui à payer, on crédite le reste sur une gift card
                    if(+e.target.value > props.valueDue){
                        props.setGiftCardScan(true)
                    }else{
                        props.setGiftCardScan(false)
                        setRefoundGiftCard('')
                    }
                }
            break;
            case 'checknumber':
                setCheckNumVal(e.target.value.replace(/\D/g,''))
            break;
            case 'bank':
                setBankName(e.target.value)
            break;
            case 'refoundgiftcard':
                setRefoundGiftCard(e.target.value)
            break;
            case 'drawerName':
                setNomTireur(e.target.value)
            break;
        }
    }

    const handleClick = (name) =>{
        if(name=='tpeAdd'){
            cancelTpeReq.current.cancel();//on annule la requete axios en cours qui questionne le tpe
            addPayment('secondaire')//on ajoute un paiement
        }else{
            cancelTpeReq.current.cancel();//on annule la requete axios en cours qui questionne le tpe
            setWaitTpe(false)
            setErrorTpe(false)
            props.setBtnDisabled(false)
            setAmountVal('')
            setModePaiementVal((props.isFor == 'card' && currentShop.modepaiement != null && currentShop.modepaiement.filter(item => modePaiementCb.includes(item)).length==1) ? currentShop.modepaiement.filter(item => modePaiementCb.includes(item))[0]:'')
        }
    }

    function numRoundMultiple(x) {//fonction d'arrondi 
        return Math.round(x / 5) * 5;
    }

    const addPaymentDue = () =>{
        if(props.isFor=='espece'){
            setAmountVal(numRoundMultiple(props.valueDue))
            setDepositVal(numRoundMultiple(props.valueDue))
        }else{
            setAmountVal(props.valueDue)
            setDepositVal(props.valueDue)
        }
    }

    const addPayment = (name) =>{
        props.setBtnDisabled(true)
        let rendu = (props.isFor=='espece' && +depositVal > +props.valueDue) ? +depositVal - +props.valueDue : 0
        let refoundGiftCartAmount = +amountVal - +props.valueDue
        let montant = (props.isFor=='espece') ? (+amountVal > +props.valueDue) ? props.valueDue : +amountVal : +amountVal

        props.setMonnaieRendu(numRoundMultiple(rendu))

        var data={
            id:props.cookies.current_ticket,
            paiement_mode:modePay,
            paiement_montant:montant,
            paiement_verse:+depositVal,
            paiement_rendu:numRoundMultiple(rendu)
        }
        if(props.giftCardScan){//dans le cas d'un remboursement par carte cadeau  
            data['gift_card_reference']=refoundGiftCard
            data['gift_card_montant']=refoundGiftCartAmount
            data['gift_card_client_id']=props.clientId

        }else if(props.isFor=='giftcard'){//si paiement par giftcard
            data['paiement_gift_card_id']=giftCardId

        }

        if(props.isFor=='cheque'){ // l'ajout du nom de la banque et num cheque 
            data['check_infos']={
                tireur:nomTireur,
                nbr:checkNumVal,
                banque:bankName
            }
        }

        if(modePay==='' || depositVal==='' || amountVal===''){
            setErrorPay(t('allFieldsRequired'))
            props.setBtnDisabled(false)

        }else if(props.isFor=='cheque' && props.modulePos && props.modulePos.params.payments.active && props.modulePos.params.payments.params.check_mentions_required){
            if(checkNumVal=='' || bankName=='' || nomTireur==''){
                setErrorPay(t('allFieldsRequired'))
                props.setBtnDisabled(false)
            }else{
                apiAddPayment(data)
            }
        }else if(props.isFor=='giftcard' && +amountVal > +infosGiftCard.disponible){//On vérifie si les fonds sont disponibles sur la carte cadeau 
            swal.fire(t('noEnoughFund'), "", "error");
            props.setBtnDisabled(false)

        }else if(props.isFor=='card'){//si paiement par carte bleue, on affiche le montant sur le tpe si tpe configuré 
            if(peripheriqueParam.tpe && name=='principal'){
                setWaitTpe(true)
                askTpe(peripheriqueParam,amountVal,modePaiementVal,props.devise,apiAddPayment,data,props.setBtnDisabled,setWaitTpe,setErrorTpe,t,cancelTpeReq,CancelToken)
            }else{
                apiAddPayment(data)
            }
            
        }else{
            apiAddPayment(data)
        }
        setTimeout(()=>{
            setErrorPay(false)
        },1500)
    }

    const apiAddPayment = async (obj) =>{
        setLoad({...load,btnAdd:true})

        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0], obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                props.setCurrentTicket(response.data.data)

                setGiftCardVal('')
                setModePaiementVal((props.isFor == 'card' && currentShop.modepaiement != null && currentShop.modepaiement.filter(item => modePaiementCb.includes(item)).length==1) ? currentShop.modepaiement.filter(item => modePaiementCb.includes(item))[0]:'')
                setAmountVal('')
                setCheckNumVal('')
                setDepositVal('')
                setNomTireur('')
                setBankName('')
                setLoad({
                    btnAdd:false
                })
                setGiftCardid(false)
                setInfosGiftCard({})
                setNoFund(false)
                setErrorPay(false)
                props.setBtnDisabled(false)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
        setLoad({...load,btnAdd:false})
        setRefoundGiftCard('')
        setWaitTpe(false)
        setErrorTpe(false)
        props.setBtnDisabled(false)
    }

    const getGiftCardPay = async (e) =>{
        e.preventDefault();

        setLoad({...load,scanGiftCard:true})
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/gift_card/get_by_reference/"+giftCardVal
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                if(response.data.data.sections_avaible.includes(+props.posid)){
                    setGiftCardid(response.data.data.id)
                    if(response.data.data.disponible == 0){//on vérifie si les fonds sont disponibles sur la carte cadeau
                        swal.fire(t('noFundAvailable'), "", "error");
                        setNoFund(true)

                    }else if(+response.data.data.disponible > +props.valueDue){
                        //si les fonds sont supérieur au montant à payer, on retire la somme exact à payer aux fonds de la gift card
                        setAmountVal(props.valueDue)
                        setDepositVal(props.valueDue)

                    }else{
                        //si les fonds sont inférieur au montant à payer, on ajoute le reste des fonds au montant à payer 
                        setAmountVal(response.data.data.disponible)
                        setDepositVal(response.data.data.disponible)
                    }
                    setInfosGiftCard(response.data.data)
                }else{//dans le cas où la gift card n'est pas valid pour ce commerce 
                    swal.fire(t('notValidGiftCard'), "", "error");
                }
            }
            setLoad({...load,scanGiftCard:false})
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoad({...load,scanGiftCard:false})
    }

    return(
        <div className="row">
            <div className="col">
                {(props.currentTicket.status==1) ? 
                    <div className="col-6">
                        <PaymentCompleted />
                    </div>
                :
                    <>
                        {(props.isFor == 'giftcard') ?
                            <form onSubmit={(e)=>getGiftCardPay(e)} className="position-relative">
                                <div className="row input-group mb-2">
                                    <div className="col">
                                        <label htmlFor="giftcard">{t('scanGiftCard')}</label>
                                        <input autoComplete="off" id='giftCardScan' className='form-control' value={giftCardVal} type="text" onChange={(e)=>handleChange(e,'giftcard')}/>
                                    </div>
                                </div>
                                {(Object.keys(infosGiftCard).length!=0) ? 
                                    <div className="text-primary mb-2">
                                        <span className="mb-1">{t('disponible')+': '}</span><strong className="d-inline-block">{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={infosGiftCard.disponible} />}</strong><br />
                                        {infosGiftCard.client!=null && <><span className="mb-2">{t('clientAssocie')+': '}</span><strong>{infosGiftCard.client.name+' '+infosGiftCard.client.firstname}</strong><br /></>}
                                        <DetailGiftCard 
                                            infosGiftCard={infosGiftCard}
                                            devise={props.devise}
                                            digit={props.digit}
                                        />
                                    </div> 
                                : 
                                    false
                                }
                                {load.scanGiftCard ?
                                    <div className="spinnerScanGiftCard"><div className="spinner-border spinner-border-sm"></div></div>
                                :
                                    false
                                }
                            </form>
                        :
                            false
                        }
                        {(props.isFor == 'card' || props.isFor == 'other') ?
                            <div className="row input-group mb-2">
                                <div className="col">
                                    <label htmlFor="modepaiement">{t('modePaiement')}</label>
                                    <select value={modePaiementVal} type="text" className="form-control" onChange={(e)=>handleChange(e,'modepaiement')}>
                                        <option value="">-- Select --</option>
                                        {(props.isFor == 'card') ?
                                            (currentShop.modepaiement != null) ?
                                                currentShop.modepaiement.map((value,index)=>{
                                                    if(modePaiementCb.includes(value)){
                                                        return( 
                                                            <option key={index} value={value}>{t(value)}</option>
                                                        )
                                                    }
                                                })
                                            :
                                                false
                                        :
                                            (currentShop.modepaiement != null) ?
                                                currentShop.modepaiement.map((value,index)=>{
                                                    if(modePaiementDefaultAll.includes(value)){
                                                        return
                                                    }else{
                                                        return( 
                                                            <DevpersoMaster
                                                                dp_direction={'ModePayment'}

                                                                key={index}
                                                                index={index}
                                                                value={value}
                                                                currentTicket={props.currentTicket}
                                                            >
                                                                <option key={index} value={value}>{value}</option>
                                                            </DevpersoMaster>
                                                        )
                                                    }
                                                })
                                            :
                                                false
                                        }
                                    </select>
                                </div>
                            </div>
                        :
                            false
                        }
                        {(props.isFor == 'giftcard') ?
                            <div className="row input-group mb-2">
                                <div className="col">
                                    <label htmlFor="amount">{t('amount')}</label>
                                    <input autoComplete="off" disabled={!giftCardId || noFund} className='form-control'  value={amountVal} type="text" onChange={(e)=>handleChange(e,'amount')} />
                                </div>
                            </div>
                        :
                            <div className="row input-group mb-2">
                                <div className="col">
                                    <label htmlFor="amount">{t('amount')}</label>
                                    <input autoComplete="off" className='form-control'  value={amountVal} type="text" onChange={(e)=>handleChange(e,'amount')} />
                                </div>
                            </div>
                        }
                        {(props.isFor == 'cheque') ?
                            <CheckPayForm 
                                bankName={bankName}
                                checkNumVal={checkNumVal}
                                nomTireur={nomTireur}
                                handleChange={handleChange}
                            />
                        :
                            false
                        }
                        {(props.giftCardScan) ?
                            <>
                                <div className="row input-group mb-2">
                                    <div className="col">
                                        <label htmlFor="checknumber">{t('scanGiftCard')}</label>
                                        <input autoComplete="off" className='form-control'  value={refoundGiftCard} type="text" onChange={(e)=>handleChange(e,'refoundgiftcard')} />
                                    </div>
                                </div>
                            </>
                        :
                            false
                        }
                        {(errorPay) ? <div className="alert alert-danger mb-3">{errorPay}</div> : false}
                        {(waitTpe) ?
                            <div className="alert alert-warning mb-3">
                                {(errorTpe) ?
                                    <strong className="text-danger fs-5">{errorTpe}</strong>
                                :
                                    <div>
                                        {t('waitingEpt')}
                                        <div className="spinner-grow text-dark sizeDotLoad" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                        <div className="spinner-grow text-dark sizeDotLoad" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                        <div className="spinner-grow text-dark sizeDotLoad" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>
                                }
                                <div className="d-flex justify-content-end">
                                    <button disabled={load.tpeAdd || load.tpeCancel} className="btn btn-sm btn-primary" onClick={()=>handleClick('tpeAdd')}>
                                        {load.tpeAdd ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('add')}
                                    </button>&nbsp;
                                    <button disabled={load.tpeAdd || load.tpeCancel} className="btn btn-sm btn-danger" onClick={()=>handleClick('tpeCancel')}>
                                        {load.tpeCancel ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnCancel')}
                                    </button>
                                </div>
                            </div>
                        :
                            false
                        }
                        {(props.isFor != 'giftcard') ?
                            <>
                                <button disabled={props.btnDisabled || load.btnAdd} className="btn btn-dark mt-1" onClick={()=>addPaymentDue()}>
                                    {(props.isFor != 'espece') ?
                                        <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={(props.valueDue < 0) ? 0 : props.valueDue} />
                                    :
                                        <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={(props.valueDue < 0) ? 0 : numRoundMultiple(props.valueDue)} />
                                    }
                                </button>&nbsp;
                            </>
                        :
                            false
                        }
                        <button disabled={props.btnDisabled || load.btnAdd} className="btn btn-primary mt-1" onClick={()=>addPayment('principal')}>
                            {load.btnAdd ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('add')}
                        </button>
                    </>
                }
                {(props.isFor == 'espece') ?
                    <div className="mt-5">
                        <h3 >{t('giveChange')+' : '} <strong className='totalPos2'>{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.monnaieRendu} />}</strong></h3>
                    </div>
                :
                    false
                }
            </div>
        </div>
    )
}

export default PaymentFormLeft;