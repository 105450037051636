import { CategoryInventoryScan } from "@gull";
import { lazy, useContext } from "react";
// import DashboardBilling from "./DashboardBilling";
// import ChiffreAffaires from "./ChiffreAffaires";
const DashboardBilling = lazy(() => import("./DashboardBilling"));
const ChiffreAffaires = lazy(() => import("./ChiffreAffaires"));
const CreateBillingGeneral = lazy(() => import("./CreateComp/CreateBillingGeneral"));
const CreateFormAddItems = lazy(() => import("./CreateComp/CreateFormAddItems"));
const SearchBilling = lazy(() => import("./SearchBilling"));
// const EndBilling = lazy(() => import("./EndBilling"));
const TemplateBilling = lazy(() => import("./TemplateBilling"));
const TemplateEmail = lazy(() => import("./TemplateEmail"));
const RecurrenceBilling = lazy(() => import("./RecurrenceBilling"));
const NotificationListErp = lazy(() => import("./Notification/NotificationListErp"));
const NotificationDetailErp = lazy(() => import("./Notification/NotificationDetailErp"));
const HistoriqueDetail = lazy(() => import("./Historique/HistoriqueDetail"));
const DetailDocument = lazy(() => import("./DetailDocumentComp/DetailDocument"));
const FacturationReport = lazy (()=> import("./ReportPbi/FacturationReport"))
const DetailDossier = lazy (()=> import("./Dossier/DetailDossier"))
const ConvertDocToPo = lazy (()=> import("./ConvertDocToPo/ConvertDocToPo"))
const ConvertDocToCreditNote = lazy (()=> import("./ConvertDocToCreditNote/ConvertDocToCreditNote"))
const ConvertDocToDeliveryNote = lazy (()=> import("./ConvertDocToDeliveryNote/ConvertDocToDeliveryNote"))

const facturationRoutes = [
    {
        path: "/invoice/dashboard",
        component: DashboardBilling
    },
    {
        path: "/invoice/search",
        component: SearchBilling
    },
    {
        path: "/invoice/convert-to-purchase-order/:id",
        component: ConvertDocToPo
    },
    {
        path: "/invoice/convert-to-return-order/:id",
        component: ConvertDocToPo
    },
    {
        path: "/invoice/convert-to-credit-note/:id",
        component: ConvertDocToCreditNote
    },
    {
        path: "/invoice/convert-to-delivery-note/:id",
        component: ConvertDocToDeliveryNote
    },
    {
        path: "/invoice/document/create",
        component: CreateBillingGeneral
    },
    {
        path: "/invoice/document/create-add-items/:id",
        component: CreateFormAddItems
    },
    // {
    //     path: "/invoice/invoice/end/:id",
    //     component: EndBilling
    // },
    {
        path: "/invoice/invoice/recurrence",
        component: RecurrenceBilling
    },
    {
        path: "/invoice/invoice/template",
        component: TemplateBilling
    },
    {
        path: "/invoice/email/template",
        component: TemplateEmail
    },
    {
        path: "/invoice/notifications/list",
        component: NotificationListErp
    },
    {
        path: "/invoice/notifications/detail/:id",
        component: NotificationDetailErp
    },
    {
        path: "/invoice/historique/edit/:id",
        component: HistoriqueDetail
    },
    {
        path: "/invoice/details/:id",
        component: DetailDocument
    },
    {
        path: "/invoice/chiffre_affaires",
        component: ChiffreAffaires
    },
    {
        path: "/invoice/report",
        component: FacturationReport
    },
    {
        path: "/invoice/folder-detail/:id",
        component: DetailDossier
    },
  ];
  
  export default facturationRoutes; 