import React from "react";
import { useTranslate } from 'react-redux-multilingual';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';

const  ContrainteCustomFields = (props) => {
    const t = useTranslate();

    return (<>
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip>
                    {(props.required) ? t('requiredField') : t('notRequiredField')}
                </Tooltip>
            }
        >
            <span style={{marginRight:'10px'}}><FontAwesomeIcon icon={(props.required) ? faExclamationTriangle : faDotCircle} /></span>
        </OverlayTrigger>
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip>
                    {(props.free) ? t('freeField') : t('fieldListData')}
                </Tooltip>
            }
        >
            <span><FontAwesomeIcon icon={(props.free) ? faPencilAlt : faDatabase} /></span>
        </OverlayTrigger>  
    </>)
}

export default ContrainteCustomFields;