import React,{useState} from "react";
import { useTranslate } from 'react-redux-multilingual'
import {FormatedPrice} from "@gull"
import { Accordion, useAccordionButton   } from "react-bootstrap";
import Moment from 'react-moment';

const DetailGiftCard = (props) =>{
    const t =useTranslate()
    const [accordionStatus,setAccordionStatus] = useState(false);

    const CustomToggleAccordion = ({ children, eventKey, accordionStatus, setAccordionStatus }) => {
        const decoratedOnClick = useAccordionButton(eventKey, () =>{
            setAccordionStatus(!accordionStatus);
        });

        let hisClass = (accordionStatus[eventKey]) ? "btn btn-sm btn-secondary mb-3" : "btn btn-sm btn-outline-secondary mb-3"
      
        return (
          <span
            className={hisClass}
            style={{marginRight:'10px'}}
            onClick={decoratedOnClick}
          >
            {children}
          </span>
        );
    }

    return(
        <div className="row">
            <Accordion>
                <CustomToggleAccordion accordionStatus={accordionStatus} setAccordionStatus={setAccordionStatus} eventKey="1">{t('moreDetails')}</CustomToggleAccordion>
                <Accordion.Collapse eventKey="1">
                    <div>
                        {(Object.keys(props.infosGiftCard).length!=0) ? 
                            props.infosGiftCard.gift_card_records.map((value,ind)=>(
                                <div key={ind}>
                                    <span className={(value.is == 'credit') ? "text-success" : "text-danger"}>{t(value.is)+': '}</span>&nbsp;
                                    <span className="d-inline-block">{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={value.montant}/>}</span>
                                    {(value.is == 'credit' && value.expire) ? <><span>{' - '+t('dateExpire')+': '}</span><span className="d-inline-block">{<Moment format="DD/MM/YYYY">{value.expire}</Moment>}</span></> : false}
                                    {(value.is == 'debit') ? <><span>{' - '}</span><span className="d-inline-block">{<Moment format="DD/MM/YYYY">{value.created_at}</Moment>}</span></> : false}
                                </div>
                            ))
                        :
                            false
                        }
                    </div>
                </Accordion.Collapse>
            </Accordion>
        </div>
    )
}

export default DetailGiftCard;