import React,{useState,useContext,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { LineItemToTransform,SubmitForm} from "@gull"
import { IsAuthContext } from '../../../../../../app/App';
import { useForm } from "react-hook-form";
import axios from 'axios'
import swal from "sweetalert2";

const useLoad = () => {
    const t = useTranslate();
    const [loading,setLoading]=useState(true)
    const [customFields,setCustomFields]=useState([])
    const [allSections,setAllSections] = useState([])

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/section"
            ).then((response) => {
                setAllSections(response.data.datas)
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            })
        })()
    }, [])

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/custom_fields/all/to_main"
            ).then((response) => {
                setLoading(false)
                setCustomFields(response.data.datas)
            }).catch((error) => {
                if(error=='end_session') return;
                setLoading(false)
                swal.fire(t('unknowError'), "", "error");
            })
        })()
    }, [])
    return [loading,customFields,allSections]
}

const TransferOrderTransform = (props) =>{
    const t =useTranslate()
    const [loading,customFields,allSections] =useLoad()
    const { authParam } = useContext(IsAuthContext)
    const { register, formState: { errors }, handleSubmit, setValue} = useForm();
    const [articleToList,setArticleToList]=useState({})
    const [validButton,setValidButton] = useState(false)
    const [apiError,setApiError] = useState(false)
    const [success,setSuccess]=useState(false)

    const [fsection,setFsection] = useState(false)
    const [errorFsection,setErrorFsection] = useState(false)

    var now = new Date();
    var annee = now.getFullYear();
    var mois = now.getMonth() +1;
    var jour = now.getDate();

    var moisFormat = (mois>9) ? mois : '0'+mois;
    var jourFormat = (jour>9) ? jour : '0'+jour;

    useEffect(function () {
        let articleData = {}
        props.dataSaleOrder.ticket.ticket_detail.map(value=>{
            if(value.article.havestock) articleData[value.article_id]={quantity:value.block_quantity,name:value.article.name,id:value.article_id}
        })
        setArticleToList(articleData)
        // setValue('fsection',props.dataSaleOrder.section_collect_id)
        setFsection(props.dataSaleOrder.section_collect_id)
        setValue('expected',annee+'-'+moisFormat+'-'+jourFormat)
    }, [])

    const onSubmit = async (data) => {
        let filterQte = Object.entries(articleToList).filter(([key,value])=>value.quantity!=0)

        if(filterQte.length!=0){
            setValidButton(true)
            setApiError(false)
            setSuccess(false)
            setErrorFsection(false)

            if(!fsection){
                setErrorFsection(true)
            }else{

                let obj={
                    expected:data.expected,
                    fsection:fsection,
                    osection:data.osection,
                    memo:data.memo,
                    commande:props.dataSaleOrder.id
                }

                customFields.forEach((item)=>{
                    obj[item.name]=data[item.name]
                })

                let articleIdArr = []
                let quantityArr = []
                
                Object.entries(articleToList).map(([key,value])=>{
                    articleIdArr.push(value.id)
                    quantityArr.push(value.quantity)
                })

                obj['article']=articleIdArr
                obj['tomove']=quantityArr


                const response  = await axios.post(process.env.REACT_APP_API_URL+"api/erp/to",obj
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach((interm)=>{
                            let finalMessage=interm.split('/!');
                            if(finalMessage.length==2){
                                messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                            }
                            else{
                                messageError+=t(interm)+'<br/>'
                            }
                        })
                        messageError=messageError.replace('undefined','');
                        setApiError(messageError)
                    }
                    else{
                        setSuccess(true)
                        props.setShow(false)
                        if(props.setGetNewData) props.setGetNewData(Date.now()) //on relance le tableau de l'historique de commande depuis le POS apres avoir créer un TO lié à une commande client 
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                    swal.fire(t(errorName), "", "error");
                })
                setValidButton(false)
                setTimeout(()=>{
                    setSuccess(false);
                },1000)
            }
        }else{
            setApiError(t('noItems'))
        }
    }

    const handleClick = () =>{
        if(props.setShowToTransform) props.setShowToTransform(false)
        if(props.setModalTitle) props.setModalTitle(t('salesOrder'))
    }

    return(
        <div className="row">
            {(loading) ? 
                <span className="d-flex justify-content-center"><span>&nbsp;</span><div className="spinner-border spinner-border-sm mt-2"></div></span>
            :
                <>
                    {props.from!='erp' &&
                        <div className="col">
                            <button className="btn btn-sm btn-danger mb-2" onClick={()=>handleClick()}>
                                {t('btnBack')}
                            </button>
                        </div>
                    }
                    <div className="col-12 mb-2 form-group">
                        <label htmlFor="date de transfert">{t('items')+' :'}</label>
                        {Object.keys(articleToList).length!=0 ? 
                            Object.entries(articleToList).map(([key,value],ind)=>{
                                let style = (ind%2 == 0) ? 'stripeBg stripePadding px-2' : 'stripePadding px-2';
                                return(
                                    <LineItemToTransform
                                        key={key}
                                        style={style}
                                        value={value}
                                        articleToList={articleToList}
                                        setArticleToList={setArticleToList}
                                        initialInfo={props.dataSaleOrder.ticket.ticket_detail[ind]}
                                    />
                                )
                            })
                        :
                            <div className="fw-bold">{t('noItems')}</div> 
                        }
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label htmlFor="date de transfert">{t('transfert_le')+' :*'}</label>
                            <input  type='date' className="form-control" {...register('expected', {required: true})}/>
                            {errors.expected?.type === 'required' && (
                            <div className="text-danger mt-1 ml-2">
                                {t('expectedRequired')}
                            </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="commerce d'origine">{t('origin_section')+' :*'}</label>
                            <select className="form-control" type="text" {...register('osection', {required: true})} >
                                <option value="">-- Select --</option>
                                {(allSections.length!=0) && allSections.map((value)=>{
                                    if(props.dataSaleOrder.section_collect_id!=value.id){
                                        return( <option key={value.id} value={value.id}>{value.name}</option>)
                                    }
                                })}  
                            </select>
                            {errors.osection?.type === 'required' && (
                            <div className="text-danger mt-1 ml-2">
                                {t('osectionRequired')}
                            </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="commerce de destination">{t('destination_section')+' :*'}</label>
                            <select disabled className="form-control" type="text" value={fsection} onChange={(e)=>setFsection(e.target.value)}>
                                <option value="">-- Select --</option>
                                {(allSections.length!=0) && allSections.map((value)=>{
                                    return( <option key={value.id} value={value.id}>{value.name}</option>)
                                    
                                })}  
                            </select>
                            {errorFsection && (
                            <div className="text-danger mt-1 ml-2">
                                {t('fsectionRequired')}
                            </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="memo">Memo :</label>
                            <textarea rows={5} className="form-control" {...register('memo')}/>
                        </div>                        
                        {(customFields.length!=0) && customFields.map((item)=>(
                            <div className="form-group" key={item.id}>
                                <label htmlFor={item.name}>{item.name}{(item.required) ? '*' : false}</label>
                                {(item.free) ?
                                    <>
                                        <input className="form-control" type="text" {...register(item.name,{required:item.required})}/>
                                        {errors[item.name]?.type === 'required' && (
                                        <div className="text-danger mt-1 ml-2">
                                            {t('youMustIndicateField')+' "'+item.name+'"'}
                                        </div>
                                        )}
                                    </>
                                    :
                                    <>
                                        <select className="form-control" {...register(item.name,{required:item.required})} >
                                            <option value="">-- Select --</option>
                                            {item.list.map((value,index)=>
                                                <option key={index} value={value}>{value}</option>
                                            )}
                                        </select>
                                        {errors[item.name]?.type === 'required' && (
                                        <div className="text-danger mt-1 ml-2">
                                            {t('youMustIndicateField')+' "'+item.name+'"'}
                                        </div>
                                        )}
                                    </>
                                }
                            </div>
                        ))}
                        <SubmitForm error={apiError} success={success} validButton={validButton}/>
                    </form>
                </>
            }
        </div>
    )
}

export default TransferOrderTransform;