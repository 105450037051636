import React, {useState,useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual'

const BtnShowDocSaved = (props) => {
    const t = useTranslate();

    return(
        <button disabled={props.disabled} className={props.btnStyle} onClick={()=>props.setShowModalDocument('saved')}>{t('documentSaved')}</button>
    )
}

export default BtnShowDocSaved;