import React, {useState,useEffect} from 'react';
import {InputSearchClient,SearchFolderByName} from '@gull'
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios';
import swal from "sweetalert2";

const FacturationSelectDossier = (props) => {
    const t = useTranslate();
    const [customerId,setCustomerId] = useState(false)
    const [validButton,setValidButton] = useState(false)
    const [numeroDossier,setNumeroDossier] = useState('')//utilisé pour l'input de recherche par numéro
    const [folderId,setFolderId] = useState(false)//utilisé dans l'input de recherche par nom
    const [existingCustomerFolder,setExistingCustomerFolder] = useState([])
    const [loading,setLoading] = useState(false)
    const [otherCompanyId,setOtherCompanyId] = useState(false)

    useEffect(function () {
        if(!customerId){
            setFolderId(false)
            setExistingCustomerFolder([])
        }
    }, [customerId])

    useEffect(function () {
        //Exécuté que depuis le détail d'un document 
        if(props.clientFacture){ 
            setCustomerId(props.clientFacture.id)
            if(props.folderFacture!=null) setFolderId(props.folderFacture.id)
            if(props.allInfos.other_company_id!=null) setOtherCompanyId(props.allInfos.other_company_id)
        }
    }, [])

    useEffect(function () {
        (async function() {
            if(customerId){
                setLoading(true)
                const response  = await axios.get(process.env.REACT_APP_API_URL+"api/facture/dossier_b2b/get_all_of_client/"+customerId
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                    }else{
                        setExistingCustomerFolder(response.data.data.dossier_b2b)
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    let messageError
                    if(error.response==undefined){
                        messageError='unknowError'
                    }
                    else if(error.response.status==403){
                        messageError='forbiddenGeneral'
                    }
                    else {
                        messageError='unknowError'
                    }
                    swal.fire(t(messageError), "", "error");
                })
                setLoading(false)
            }
        })()
    }, [customerId])

    const handleChange = (e,name) => {
        if(name=='numero'){
            setNumeroDossier(e.target.value.replace(/\D/g,''))
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault()
        
        if(numeroDossier!='' || folderId){
            setValidButton(true)
            let nbr = numeroDossier!='' ? numeroDossier : folderId
            let url = numeroDossier!='' ? "api/facture/dossier_b2b/get_one_by_number/" : "api/facture/dossier_b2b/get_one/"

            const response  = await axios.get(process.env.REACT_APP_API_URL+url+nbr
            ).then((response) => {
                if(!response.data.valid){
                    swal.fire(t('folderNotExist',{numeroDossier: nbr}), "", "error");
                }else{ 
                    props.callbackFolderLink(response.data.data)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let messageError
                if(error.response==undefined){
                    messageError='unknowError'
                }
                else if(error.response.status==403){
                    messageError='forbiddenGeneral'
                }
                else {
                    messageError='unknowError'
                }
                swal.fire(t(messageError), "", "error");
            })
            
            setNumeroDossier('')
            setCustomerId(false)
            setFolderId(false)
            setValidButton(false)
        }
    };

   return(
        <form onSubmit={(e)=>onSubmit(e)}>
            <div className='d-flex justify-content-center flex-wrap'>
                <div className="form-group" style={{width:props.size}}>
                    <label>{t('folder')+' n°'}</label>
                    <input disabled={customerId || folderId || validButton} type="text" value={numeroDossier} className='form-control' onChange={(e)=>handleChange(e,'numero')}/>
                </div>

                <div className='fw-bold mt-1 mb-1 text-center' style={{width:props.size}}>{t('or')}</div>

                <div className="form-group" style={{width:props.size}}>
                    <label>{t('selectCustomer')}</label>
                    <InputSearchClient 
                        setClient={setCustomerId}
                        disabled={numeroDossier!='' || (folderId && !customerId) || validButton}
                        changeDossierClient={props.clientFacture}
                    />
                </div>
                {loading ? <div style={{width:props.size}}><div className="spinner-border spinner-border-sm"></div></div> : false}
                {existingCustomerFolder.length!=0 &&
                    <div className="form-group" style={{width:props.size}}>
                        <label>{t('folder')}</label>
                        <select disabled={validButton} value={folderId} className='form-control' onChange={(e)=>setFolderId(e.target.value)}>
                            <option value="">-- Select --</option>
                            {existingCustomerFolder.map(value=>{
                                if(props.clientFacture && !otherCompanyId){
                                    // Dans le detail d'un document, si on change de dossier
                                    // Si la company est liée au document, on ne retourne que les dossiers liés à la company
                                    if(value.other_company_id==null){
                                        return(
                                            <option key={value.id} value={value.id}>{value.name}</option>
                                        )
                                    }
                                }else if(props.clientFacture && otherCompanyId){
                                    // Si une other company est liée au document, on ne retourne que les dossiers liés à la other company
                                    if(value.other_company_id==otherCompanyId){
                                        return(
                                            <option key={value.id} value={value.id}>{value.name}</option>
                                        )
                                    }
                                }else{
                                    //Dans un cas normal, on retourne tous les documents
                                    return(
                                        <option key={value.id} value={value.id}>{value.name}</option>
                                    )
                                }
                            })}
                        </select>
                    </div>
                }

                <div className='fw-bold mt-1 mb-1 text-center' style={{width:props.size}}>{t('or')}</div>

                <div className="form-group" style={{width:props.size}}>
                    <label>{t('docName')}</label>
                    <SearchFolderByName 
                        setFolderId={setFolderId}
                        disabled={numeroDossier!='' || customerId || validButton}
                    />
                </div>

                <div style={{width:props.size}}>
                    <button disabled={validButton} className="btn btn-primary mt-2">
                        {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')+' '+t('folder').toLowerCase()}
                    </button>
                </div>
            </div>
       </form>
   )
}

export default FacturationSelectDossier;