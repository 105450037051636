import React,{useState} from "react";
import { FormatedPrice} from "@gull";
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt} from '@fortawesome/free-solid-svg-icons'

const GetSaveSaleBtn = (props) =>{
    const t = useTranslate();
    const [loading, setLoading]=useState(false)
    const idsLocalStorage=localStorage.getItem("pos").split(',')

    const getTicket = async (sauvegardeIdTicket) =>{
        setLoading(true)
        if(props.cookies.current_ticket){//si il y aun ticket en cours
            props.saveTicket(true) //On appelle saveTicket qui va enregistrer la vente en cours pour ne pas la perde
        }
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0],{
            id:props.value.ticket_id
        }).then((response) => {
            if(!response.data.valid){
                swal.fire(t('unknowError'), "", "error");
            }
            else{   
                props.setCookie("current_ticket",props.value.ticket_id,{ path: "/", expires: props.expire_date })
                props.setCurrentTicket(response.data.data)
                setLoading(false)
                props.closeModal()

                //On appelle l'url "saved_ticket/retrieve" pour supprimer le ticket sauvegardé que l'on souhaite finaliser 
                const res = axios.get(process.env.REACT_APP_API_URL+"api/pos/saved_ticket/retrieve/"+props.value.id+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0]
                ).then((res) => {
                    if(!res.data.valid){
                        swal.fire(t('unknowError'), "", "error");
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    swal.fire(t('unknowError'), "", "error");
                })
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoading(false)
    }

    return(<>
        {loading ? 
            <span className="spinner-border spinner-border-sm"></span> 
        :
            <FontAwesomeIcon className="fs-5 text-primary pointer" icon={faExchangeAlt} onClick={()=>getTicket(props.value.id)}/>}
        </>
    )

}

const SavedTicketModal = (props) =>{
    const t = useTranslate();

    return(
        <div>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th className="col">{t('caisseIdent')}</th>
                        <th className="col">{t('cashier')}</th>
                        <th className="col">{t('customer')}</th>
                        <th className="col">Total</th>
                        <th className="col text-end">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {(props.savedTicketList.length!=0) ?
                            props.savedTicketList.map((value,ind)=>{
                                let style = (ind%2 == 0) ? 'stripeBg' : '';
                                return(
                                    <tr
                                        key={ind} 
                                        className={style}
                                    >
                                        <td>{<Moment format="DD/MM/YYYY hh:mm:ss">{value.ticket_created_at}</Moment>}</td>
                                        <td>{value.ticket.caisse_ident.code}</td>
                                        <td>{value.ticket.rh!=null ? value.ticket.rh.name+' '+value.ticket.rh.firstname : ''}</td>
                                        <td>{value.ticket.client!=null ? value.ticket.client.name+' '+value.ticket.client.firstname : ''}</td>
                                        <td>{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={value.ticket_montant} />}</td>
                                        <td className='text-end'>
                                            <GetSaveSaleBtn 
                                                value={value}
                                                posid={props.posid}
                                                cookies={props.cookies}
                                                saveTicket={props.saveTicket}
                                                setCookie={props.setCookie}
                                                expire_date={props.expire_date}
                                                setCurrentTicket={props.setCurrentTicket}
                                                closeModal={props.closeModal}
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        :
                            <tr>
                                <td className='text-end border-0'>{t('noSalesSaved')}</td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default SavedTicketModal;
