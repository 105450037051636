import React, { useState,useEffect } from "react";
import {AlertCurrentReceipt} from "@gull"
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";

const SearchDevisModal = (props) =>{
    const t = useTranslate();
    const [inputValue,setInputValue]=useState('')
    const [load,setLoad]=useState({
        ticket:false,
    })

    useEffect(function () {
        const input = document.querySelector('#scanDevis');
        setTimeout(()=>{
            input.focus()
        },300)
        
    }, [])

    const handleChange = (e) =>{
        setInputValue(e.target.value)
    }

    const handleSubmit = async (e)=>{
        e.preventDefault();
        setLoad({...load,ticket:true})

        const test = await axios.get(process.env.REACT_APP_API_URL+"api/pos/devis/get_by_reference/"+inputValue
        ).then((response) => {
            if(!response.data.valid){
                swal.fire(t('devisNotExists'), "", "error");
                setLoad({...load,ticket:false})
            }else{  
                props.setCookie("current_ticket",response.data.data.ticket.id,{ path: "/", expires: props.expire_date }) 
                setTimeout(() => {
                    props.closeModal('onCloseModal')
                    setLoad({...load,ticket:false})
                }, 600);
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
            setLoad({...load,ticket:false})
        })
        setInputValue('')
    }

    return(
        <>
            <div className="row d-flex justify-content-center mb-3">
                <div className="col-8">
                    <form onSubmit={(e)=>handleSubmit(e)}>
                        <input id="scanDevis" disabled={load.ticket || props.ticketEnCours.message} value={inputValue} type="text" className="form-control" autoComplete="off" placeholder={t('scanQuotationHere')} onChange={(e)=>handleChange(e)}/>
                    </form>
                </div>
            </div>
            {(props.ticketEnCours.message) ?
                <AlertCurrentReceipt />
            :
                (load.ticket) ?
                    <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm mt-2"></div></span>
                :   
                    false
            }
        </>
    )
}

export default SearchDevisModal;