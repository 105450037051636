import React, { useState, useEffect } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { SelectDataListSearch } from '@gull';
import swal from "sweetalert2";
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import {Form, Badge} from "react-bootstrap";

const EachNotification = (props) => {
    const t = useTranslate();
    const [loadingAdd,setLoadingAdd] = useState(false)
    const [userIds,setUserIds]=useState([])
    const [valueInput,setValueInput] = useState('')
    const [checked,setChecked]=useState(false) 

    const [usersForSelect,setUsersForSelect]=useState([])

    let notifDetail = props.notifState.find(value=>value.type == props.typeNotif)

    useEffect(function () {
        //Si des utilisateurs ont déja été assignés à cette notification, on les affiche
        if(notifDetail && notifDetail.all==1){
            //si tous les users ont été sélectionnés
            setUserIds(props.allUsers)
            setChecked(true)
        }else{
            if(notifDetail){
                setUserIds(notifDetail.user)
            } 
        } 
    }, [props.notifState])

    useEffect(function () {
        let arr = []
        let filteredUserForSelect = []
        userIds.map(value=>{
            arr.push(value.id)
        })
        props.allUsers.map(value=>{
            if(!arr.includes(value.id)) filteredUserForSelect.push(value)
        })
        setUsersForSelect(filteredUserForSelect)
    }, [userIds])


    const handleChange = async (newUserId) => {
        setLoadingAdd(true)
        props.setDisabled(true);

        let arrIds = []
        userIds.map(value=>{
            arrIds.push(value.id)
        })
        if(newUserId) arrIds.push(newUserId)

        let all = (usersForSelect.length == 1) ? true : (newUserId) ? false : true

        const response  = await axios.post(process.env.REACT_APP_API_URL+"api/send_alert", {
            type: props.typeNotif,
            all: all,
            section: props.sectionId,
            users: arrIds
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(t(messageError), "", "error");
            }
            else{
                if(!newUserId) setUserIds(props.allUsers)

                let arr = []
                props.notifState.map(value=>{
                    if(value.type!=props.typeNotif){
                        arr.push(value)
                    }
                })
                arr.push(response.data.data)

                props.setNotifState(arr)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            if(error.response==undefined){
                swal.fire(t('unknowError'), "", "error");
            }
            else if(error.response.status==403){
                swal.fire(t('forbiddenGeneral'), "", "error");
            }
            else {
                swal.fire(t('unknowError'), "", "error");
            } 
        })
        props.setDisabled(false)
        setLoadingAdd(false)
    }

    //Lorsque retire tous les usitilisateurs de l'attribution de notification, on supprime la notification
    const deleteNotification = async () => {
        const response  = await axios.delete(process.env.REACT_APP_API_URL+"api/send_alert/"+notifDetail.id
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(t(messageError), "", "error");
            }
            else{
                let newData = props.notifState.filter(value=> value.type != props.typeNotif)

                props.setNotifState(newData)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            if(error.response==undefined){
                swal.fire(t('unknowError'), "", "error");
            }
            else if(error.response.status==403){
                swal.fire(t('forbiddenGeneral'), "", "error");
            }
            else {
                swal.fire(t('unknowError'), "", "error");
            } 
        })
    }

    const deleteUserAttribution = async (userIdToDelete) => {
        setLoadingAdd(true)
        props.setDisabled(true);
        setChecked(false)

        let arrIds = []
        if(userIdToDelete){
            userIds.map(value=>{
                if(value.id != userIdToDelete) arrIds.push(value.id)
            })
        }

        const response  = await axios.post(process.env.REACT_APP_API_URL+"api/send_alert", {
            type: props.typeNotif,
            all: false,
            section: props.sectionId,
            users: arrIds
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(t(messageError), "", "error");
            }
            else{
                if(userIdToDelete){
                    if(userIds.length==1){
                        //si il reste plus qu'un utilisateur, on supprime l'abonnement à la notiffication 
                        deleteNotification()
                    }
                }else{
                    //si on retire l'attribution à tous les utilisateurs 
                    deleteNotification()
                }

                let arr = []
                props.notifState.map(value=>{
                    if(value.type!=props.typeNotif){
                        arr.push(value)
                    }
                })
                arr.push(response.data.data)

                props.setNotifState(arr)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            if(error.response==undefined){
                swal.fire(t('unknowError'), "", "error");
            }
            else if(error.response.status==403){
                swal.fire(t('forbiddenGeneral'), "", "error");
            }
            else {
                swal.fire(t('unknowError'), "", "error");
            } 
        })
        props.setDisabled(false)
        setLoadingAdd(false)
    }

    const handleSwith = () =>{
        setChecked(!checked)

        if(checked){
            deleteUserAttribution(false)
        }else{
            handleChange(false)
        }
    }
    
    return(
        <div className="row mb-3">
            <div className="col-12 p-3" style={{backgroundColor:props.bgcolor}}>
                <div className="row">
                    <div className="col text-center">
                        <strong>{t(props.typeNotif)+' :'}</strong>
                    </div>
                </div>
                
                <div className="row mt-3">
                    <div className="col-6">
                        <div className="d-flex justify-content-center">
                            <Form className="mb-4">
                                <Form.Check 
                                    checked={checked}
                                    type="switch"
                                    id="custom-switch"
                                    label={t('allUsers')}
                                    onChange={()=>handleSwith()}
                                />
                            </Form>
                        </div>
                    </div>
                    <div className="col-5">
                        {(props.allUsers.length!=0) ?
                                <SelectDataListSearch 
                                    datas={usersForSelect} 
                                    ids={userIds}
                                    setIds={setUserIds}
                                    valueInput={valueInput}
                                    setValueInput={setValueInput}
                                    callBackAfterSelect={handleChange}
                                    disabled={props.disabled}
                                />
                            :
                                false
                        }
                    </div>
                    <div className="col-1">
                        {(loadingAdd) &&
                            <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm mt-2"></div></span>
                        }
                    </div>
                </div>
                <div className='row'>
                    <strong className='mb-2'>{t('listedUserWillBeNotified')+' :'}</strong>
                    {(userIds.length==0) ? 
                        <div className='text-center'>{t('noUser')}</div>
                    :
                        userIds.map((value)=>
                            <div key={value.id} className="col">
                                <Badge bg="primary" text="white">
                                    {value.name}&nbsp;&nbsp;
                                    {(!props.disabled) && 
                                        <span className="text-danger pointer" onClick={()=>deleteUserAttribution(value.id)}>
                                            {<FontAwesomeIcon icon={faTimesCircle} />}
                                        </span>
                                    }
                                </Badge>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
} 

export default EachNotification;