export let dp_direction = {
    //là on met un commentaire qui indique de quelle entreprise il s'agit: Card
    CARD:{//id de l'entreprise
        Master:'CardMaster',//le master doit obligatoirement être renseigné. Nom du composant Master
        TabTicket:'erp/CardTabTicket',
        CompteurBassin:'pos/CardCompteurBassin'
    },
    INTE:{//id de l'entreprise
        Master:'InterdisMaster',//le master doit obligatoirement être renseigné. Nom du composant Master
        ModePayment:'pos/InterdisModePayment',
    },
}