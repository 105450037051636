export const column_correspondance_facture = {
    item_name:{
        name:'designation',
        align:'left',
        format:'designation',
        stylePdf:'tab'
    },
    unitaire_remise:{
        name:'priceHt',
        align:'right',
        format:'price',
        stylePdf:'tab3'
    },
    remise:{
        name:'discount',
        align:'right',
        format:'remise',
        stylePdf:'tab3'
    },
    tgc:{
        name:'tgc',
        align:'right',
        format:'tgc',
        stylePdf:'tab3'
    },
    quantity:{
        name:'quantity',
        align:'right',
        format:'normal',
        stylePdf:'tab3'
    },
    ht_remise:{
        name:'total',
        align:'right',
        format:'price',
        stylePdf:'tab3'
    },
    ht:{
        name:'totalWithoutDiscount',
        align:'right',
        format:'price',
        stylePdf:'tab3'
    },
    carton:{
        name:'box',
        align:'right',
        format:'normal',
        stylePdf:'tab3'
    },
    unitaire:{
        name:'priceHtWithoutDiscount',
        align:'right',
        format:'price',
        stylePdf:'tab3'
    },
    remise_value:{
        name:'discountType',
        align:'right',
        format:'normal',
        stylePdf:'tab3'
    },
    unitaire_devise:{
        name:'inDevise',
        align:'right',
        format:'devise',
        stylePdf:'tab3'
    },
}

export const recap_correspondance_facture = {
    ht_remise:{
        name:'total',
        align:'right',
        format:'price',
        stylePdf:'tab4'
    },
    tgc:{
        name:'tgc',
        align:'right',
        format:'tgc',
        stylePdf:'tab4'
    },
    ttc:{
        name:'totalTTC',
        align:'right',
        format:'price',
        stylePdf:'tab4'
    },
    ht:{
        name:'totalWithoutDiscount',
        align:'right',
        format:'price',
        stylePdf:'tab4'
    },
    total_remise_ht:{
        name:'discount',
        align:'right',
        format:'remise',
        stylePdf:'tab4'
    },
    total_devise:{
        name:'totalDevise',
        align:'right',
        format:'devise',
        stylePdf:'tab4'
    },

}