import React from "react";
import { useTranslate } from 'react-redux-multilingual'
import { CardPerso } from "@gull";
import ObjectifDetail from "./ObjectifDetail";


const ObjectifDay = (props) => {
    const t = useTranslate()

    return (<>
        <CardPerso header={t('objectifs')+' '+t('shop')+' - '+props.data.day.substr(8,2)+'/'+props.data.day.substr(5,2)+'/'+props.data.day.substr(0,4)}>
            <ObjectifDetail data={props.data} setObjectif={props.setObjectif} objectifId={props.data.id} from={props.from}/>
        </CardPerso>
    </>)
}

export default ObjectifDay;
