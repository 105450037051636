import axios from 'axios';

export const askTpe = (peripheriqueParam,amount,typeCb,devise,apiAddPayment,data,setBtnDisabled,setWaitTpe,setErrorTpe,t,cancelTpeReq,CancelToken) =>{
    if(peripheriqueParam.tpe.model=='ingenico'){
        ingenico(peripheriqueParam,amount,typeCb,devise,apiAddPayment,data,setBtnDisabled,setWaitTpe,setErrorTpe,t,cancelTpeReq,CancelToken)
    }  
}

const ingenico = async (peripheriqueParam,amount,typeCb,devise,apiAddPayment,data,setBtnDisabled,setWaitTpe,setErrorTpe,t,cancelTpeReq,CancelToken) =>{
    cancelTpeReq.current = CancelToken.source() 

    let paymentType = (typeCb!='amex' || typeCb!='jcb') ? 'CARD' : typeCb.toUpperCase()

    const response  = await axios.get("http://127.0.0.1:5000/payment?com="+peripheriqueParam.tpe.host+"&amount="+amount+"&paymentType="+paymentType+"&paymentMode=debit&currency="+devise+"&waitEnd=0&collectInfo=0&forceVerif=0",{cancelToken: cancelTpeReq.current.token}
    ).then((response) => {
        console.log(response.data)
        if(response.data._transaction_result == 0){
            apiAddPayment(data)
            setWaitTpe(false)
        }else{
            setErrorTpe(t('errorEpt'))
        }
    }).catch((error) => {
        console.log('errorCb',error)
        setBtnDisabled(false)
    })

    //"api/test_tpe/"+peripheriqueParam.tpe.host+"/"+amount+"/"+typeCb+"/debit/"+devise+"/0/0/0/5/valid"  adresse de test
    

    // const response  = await axios.get("http://127.0.0.1:5000/payment?com=COM10&amount=100&paymentType=AMEX&paymentMode=debit&currency=XPF&waitEnd=0&collectInfo=0&forceVerif=0"
    // ).then((response) => {
    //     console.log(response.data)
    //     setButtonTestCb(false)
    // }).catch((error) => {
    //     console.log(error,error.response)
    //     setButtonTestCb(false)
    // })
}