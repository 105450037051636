export const articleHardData = [
'reference',
'reference2',
'name',
'sku',
'price',
'price_b2b',
'tgc',
'parent_name',
'devise_achat',
'firstlevelcat',
'secondlevelcat',
'thirdlevelcat',
'fourthlevelcat',
'fifthlevelcat',
'fournisseur',
'marque',
'provisoire',
'ecotaxe',
'categorie_douane',
];