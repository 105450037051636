import React, {useState,useContext,useEffect,useRef} from "react";
import {EtiquetteTemplate,SelectPrinterErp,Loading} from "@gull"
import { useTranslate } from 'react-redux-multilingual';
import {imprimeEtiquette} from 'app/imprimeEtiquette'
import { IsAuthContext } from '../../../app/App';
import { espace_mille } from 'app/espace_mille';
import swal from "sweetalert2";
import axios from 'axios'

const useLoad = (from) => {
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext);
    const [loading,setLoading]=useState(true)
    const [company,setCompany]=useState({})

    useEffect(function () {
        (async function() {
            if(from!='pos'){
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/company/"+authParam.company.id
                ).then((response) => {
                    setLoading(false)
                    setCompany(response.data)
                }).catch((error) => {
                    if(error=='end_session') return;
                    let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                    swal.fire(t(errorName), "", "error");
                    setLoading(false)
                })
            }
        })()
    }, [])

    return [loading,company]
}

const ModalTagPrintForm = (props) =>{
    const t = useTranslate();
    const [loading,company] = useLoad(props.from)
    const [qteTag,setQteTag]=useState(1)
    const [sizeTag,setSizeTag]=useState('') 
    const { authParam } = useContext(IsAuthContext)
    
    const [urlCertificat,setUrlCertificat] = useState('')
    const redirectCertificat = useRef('')
    const [errorCookie,setErrorCookie] = useState(false)

    const [relanceEtiquetteTemplates, setRelanceEtiquetteTemplates] = useState(false);

    const [imprimanteListe, setImprimanteListe] = useState(false);
    useEffect(function () {
        if(props.from=='wms' && Object.keys(company).length!=0 && company.peripherique){
            let listArr = company.peripherique.filter(peripherique => (peripherique.type=='imprimante_etiquette' && peripherique.section_id==props.wmsid))
            setImprimanteListe(listArr)
        }
    }, [company])

    let cookie
    if(props.cookies){
        if(props.cookies.peripherique){
            cookie = props.cookies.peripherique.split('/!')
        }else{
            cookie = []
        }
    }
    
    let devise = authParam.company.devise;
    let formatedPrice = props.articlePrice ? espace_mille(props.articlePrice) : 0

    useEffect(function () {
        if(props.hasOwnProperty('qteToPrint') && props.qteToPrint && +props.qteToPrint!=0 ) setQteTag(props.qteToPrint)
    }, [])

    const handleChange = (e,name) =>{

        if(name=="qte"){
            setQteTag(e.target.value.replace(/\D/g,''))
        }else{
            setSizeTag(e.target.value)
        }
        
    }

    const handleCheckRadio = () => {
        if(props.paramPrinter){
            props.setParamPrinter(false)
        }else{
            props.setParamPrinter({
                model:'image_file'
            })
        }
    
    };

    const handleSubmit = () =>{
        setErrorCookie(false)
        if(sizeTag==''){
            swal.fire(t('sizeTagRequired'), "", "error");
        }else if(qteTag>100){
            swal.fire(t('limitTagPrint100'), "", "error");
        }else if(qteTag<1){
            swal.fire(t('mintTagPrint1'), "", "error");
        }else{
            imprimeEtiquette(props.paramPrinter,devise,formatedPrice,t,props.articleName,props.articleUpc,qteTag,props.from,sizeTag,setUrlCertificat,redirectCertificat)
        }
    }

    const imprimeEtiquetteWms = (imprimante) => {
        props.setParamPrinter(imprimante)
        handleSubmit()
    };

    return(
        <div>
            {props.from=='erp' ?
                <span className='row'>
                    <SelectPrinterErp
                        company={company}
                        paramPrinter={props.paramPrinter}
                        setParamPrinter={props.setParamPrinter}
                        style='form-group col'
                        from={props.from}
                    />
                </span>
            : (props.from=='wms' && !props.listPrint) ?
                <div className="form-check mb-2">
                    <input type="radio" checked={props.paramPrinter} className="form-check-input" onChange={()=>props.setImgFilePrint(!props.imgFilePrint)}/>
                    <label className="form-check-label">{t('customizeTag')}</label>
                </div>
            :
                false
            }
            <a ref={redirectCertificat} href={urlCertificat} className="d-none" target="_blank"></a>
            <div className="row position-relative">
                {!props.listPrint &&
                    <div className={`${((props.from=='wms' && !props.imgFilePrint) || (props.from!='wms' && props.imprimanteType!="image_file")) ? "col-12 form-group" : "col-12 col-lg-6 form-group positionInputPrintTag"}`}>
                        <label>{t('qteTag')}</label>
                        <input type="text" className="form-control mb-3" value={qteTag} onChange={(e)=>handleChange(e,'qte')}/>
                    </div>
                }
                {((props.from=='wms' && !props.imgFilePrint) || (props.from!='wms' && props.imprimanteType!="image_file")) &&
                    <div className="col-12 form-group">
                        <label>{t('tagSize')}</label>
                        <select className="form-control mb-3" value={sizeTag} onChange={(e)=>handleChange(e,'size')}>
                            <option value="">--Select--</option>
                            <option value="300">35x30mm</option>
                            <option value="220">35x22mm</option>
                        </select>
                    </div>
                }
            </div>

            {((props.from=='wms' && props.imgFilePrint) || (props.from!='wms' && props.imprimanteType=="image_file")) &&
                <EtiquetteTemplate 
                    isfor="edit"
                    relanceEtiquetteTemplates={relanceEtiquetteTemplates}
                    setRelanceEtiquetteTemplates={setRelanceEtiquetteTemplates}

                    paramPrinter={props.paramPrinter}
                    setParamPrinter={props.setParamPrinter}//utilisé que pour le WMS
                    articleSelected={props.articleSelected}
                    qteTag={qteTag}
                    from={props.from}
                    imprimanteListe={imprimanteListe}//utilisé que pour le WMS

                    redirectCertificat={redirectCertificat}
                    setUrlCertificat={setUrlCertificat}

                    listPrint={props.listPrint}//Si il est passé à true, le script attendra une liste à imprimer, sinon il imprimera un seul article
                    articleListPrint={props.articleListPrint}//utilisé pour l'impréssion de liste d'article
                    setQteTag={setQteTag}
                    setLoadPrintList={props.setLoadPrintList}
                />
            }

            {(errorCookie) ? <div className="alert alert-danger mb-2 mt-2">{t('noConfiguredPeripheral')}</div> : false}
            {((props.from=='wms' && !props.imgFilePrint) || (props.from!='wms' && props.imprimanteType!="image_file")) ?
                props.from=='wms' ?
                    imprimanteListe && imprimanteListe.map(value=>{
                        return(
                            <span key={value.id}><span className="btn btn-primary" onClick={() => imprimeEtiquetteWms(value)}>{t('btnPrint')+' '+value.name}</span>&nbsp;&nbsp;</span>
                        )
                    })
                : 
                    <button className="btn btn-primary" onClick={() => handleSubmit()}>{t('btnPrint')}</button>
            :
                false
            }
        </div>
    )
}

export default ModalTagPrintForm;