import React from "react";
import { useTranslate } from 'react-redux-multilingual'
import { Toast } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const ToastPopUp = (props) =>{
    const t =useTranslate()

    return(
        <div>
            <Toast onClose={() => props.setShowToast(false)} show={props.showToast} delay={2500} autohide className="toastPosition">
                <Toast.Header className="toastHead">
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faCheckCircle} className="toastIcon"/>&nbsp;&nbsp;
                            <strong className="text-dark">{props.message}</strong>
                        </div>
                </Toast.Header>
            </Toast>
        </div>
    )
}

export default ToastPopUp;