import React,{useState,useEffect,useContext} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { SubmitForm} from "@gull"
import swal from "sweetalert2";
import axios from 'axios'
import { useForm } from "react-hook-form";
import { epson_tm_h6000VOrder } from 'app/imprime';
import { PeripheriqueContext,IsAuthContext } from '../../../../../app/App';

const useLoad = () => {
    const t = useTranslate();
    const [loading,setLoading]=useState(true)
    const [customfields,setCustomFields]=useState(false)
    const [section,setSection]=useState([])

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/custom_fields/all/commande"
            ).then((response) => {
                setLoading(false)
                setCustomFields(response.data.datas)
            }).catch((error) => {
                if(error=='end_session') return;
                setLoading(false)
                swal.fire(t('unknowError'), "", "error");
            })
        })()
    }, [])
    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/section"
            ).then((response) => {
                setSection(response.data.datas)
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            })
        })()
    }, [])
    return [loading,customfields,section]
}

const OrderForm = (props) =>{
    const t =useTranslate()
    const [loading,customfields,section] = useLoad()
    const { register, formState: { errors }, handleSubmit, setValue, reset} = useForm();
    const [validButton,setValidButton] = useState(false)
    const [apiError,setApiError] = useState(false)
    const [success,setSuccess] = useState(false)
    const [section_collect,setSection_collect] = useState(false)
    const [errorSection,setErrorSection] = useState(false)
    const { peripheriqueParam } = useContext(PeripheriqueContext)  
    const {authParam} = useContext(IsAuthContext);

    useEffect(function () {
        setSection_collect(props.currentShop.id)
    }, [])

    var now = new Date();
    var annee = now.getFullYear();
    var mois = now.getMonth() +1;
    var jour = now.getDate();

    var moisFormat = (mois>9) ? mois : '0'+mois;
    var jourFormat = (jour>9) ? jour : '0'+jour;

    const onSubmit = async (data) =>{
        setValidButton(true)
        setApiError(false)
        setSuccess(false)
        setErrorSection(false)

        if(!section_collect){
            setErrorSection(true)
        }else{

            let obj={
                section_collect:section_collect,
                note:data.note,
            }
            if(data.heure!='' && data.date==''){
                obj['heure']=data.heure
                obj['date']=annee+'-'+moisFormat+'-'+jourFormat
            }else{
                if(data.heure==''){
                    obj['heure']=null
                }else{
                    obj['heure']=data.heure
                }

                if(data.date==''){
                    obj['date']=null
                }else{
                    obj['date']=data.date
                }
            }

            customfields.map(item=>{
                obj[item.name]=data[item.name]
            })

            const callapi  = await axios.post(process.env.REACT_APP_API_URL+"api/pos/commande/create/"+props.currentTicket.id, obj)
            .then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach((interm)=>{
                        let finalMessage=interm.split('/!');
                        if(finalMessage.length==2){
                            messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                        }
                        else{
                            messageError+=t(interm)+'<br/>'
                        }
                    })
                    messageError=messageError.replace('undefined','');
                    setApiError(messageError)
                }
                else{
                    setSuccess(true)
                    props.setTicketCommande(response.data.data)
                    props.setCookie("last_sale_order",response.data.data.id,{ path: "/", expires: props.expire_date }) 
                    props.resetSale('salesOrder')
                    epson_tm_h6000VOrder('print',response.data.data.ticket.section_id,response.data.data,t,props.devise,peripheriqueParam,false,authParam)

                    //Dans le cas où l'interface est pour un restaurant, on ajoute la nouvelle commande 
                    if(props.actualShop.interface=='restauration' && props.allCommandes && props.setAllCommandes){ 
                        props.setAllCommandes([response.data.data,...props.allCommandes])
                    }
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            })
            setValidButton(false)
            reset()
            setTimeout(()=>{
                setSuccess(false);
            },1000)
        }
    }

    return(
        <div className="row">
            {(loading) ? 
                <div className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm"></div></div>
            : 
                <>
                    <div className="col mb-3">
                        <button className="btn btn-danger" onClick={()=>props.setOrderForm(false)}>{t('btnBack')}</button>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group col-12 mb-3">
                            <label>{t('collectZone')+'*'}</label>
                            <select className="form-control" type="text" value={section_collect}  onChange={(e)=>setSection_collect(e.target.value)}>
                                {section.map((value)=>
                                    <option key={value.id} value={value.id}>{value.name}</option>
                                )}                                    
                            </select>
                            {errorSection && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('youMustIndicateField')+' "'+t('collectZone')+'"'}
                                </div>
                            )}
                        </div>
                        <div className="form-group col-12 mb-3">
                            <label>{t('expectedCollectDate')}</label>
                            <input autoComplete="off" type="date" className="form-control" {...register('date')}/>
                        </div>
                        <div className="form-group col-12 mb-3">
                            <label>{t('hour')}</label>
                            <input autoComplete="off" type="time" className="form-control" {...register('heure')}/>
                        </div>
                        <div className="form-group col-12 mb-3">
                            <label>Note</label>
                            <textarea autoComplete="off" rows="7" className="form-control" {...register('note')}></textarea>
                        </div>
                        {(customfields) && customfields.map((item,index)=>(
                            <div className="form-group col-12 mb-3" key={index}>
                                <label htmlFor={item.name}>{item.name}{(item.required) ? '*' : false}</label>
                                {(item.free) ?
                                    <>
                                        <input autoComplete="off" className="form-control" type="text" {...register(item.name,{required:item.required})}/>
                                        {errors[item.name]?.type === 'required' && (
                                            <div className="text-danger mt-1 ml-2">
                                                {t('youMustIndicateField')+' "'+item.name+'"'}
                                            </div>
                                        )}
                                    </>
                                    :
                                    <>
                                        <select className="form-control" {...register(item.name,{required:item.required})} >
                                            <option value="">-- Select --</option>
                                            {item.list.map((value,index)=>
                                                <option key={index} value={value}>{value}</option>
                                            )}
                                        </select>
                                        {errors[item.name]?.type === 'required' && (
                                            <div className="text-danger mt-1 ml-2">
                                                {t('youMustIndicateField')+' "'+item.name+'"'}
                                            </div>
                                        )}
                                    </>
                                }
                            </div>
                        ))}
                        <SubmitForm error={apiError} success={success} validButton={validButton}/>
                    </form>
                </>
            }
           
        </div>
    )
}

export default OrderForm;