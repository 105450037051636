import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ReactDOMServer from 'react-dom/server';

const ExportDatatablePdf = ({ orientationPdf, t, dataHeadPdfExport, columnShow, columnOrganize, bodyData, typeDocument, authParam }) => {
    const doc = new jsPDF({
        orientation: orientationPdf === 'landscape' ? 'landscape' : 'portrait',
        unit: 'pt',
        format: 'a4',
    });

    let maxStartPtX = orientationPdf === 'landscape' ? 720 : 470;
    let sizeContainPtX = orientationPdf === 'landscape' ? 800 : 550;

    // // 1. Header Section
    doc.setFontSize(10);
    doc.text(authParam.company.name, 20, 20);
    doc.setFontSize(8);
    doc.text(typeDocument, 20, 30);

    const changeObjetToString = (obj) => {
        obj = ReactDOMServer.renderToStaticMarkup(obj)
        return obj.replace(/<[^>]*>/g, '')
    }

    const formatItemValue = (item) => {
        if (typeof item === 'string') return item;
        else return changeObjetToString(item)
    };

    // // 2. Document Title & Metadata Section
    let yFloorPosition = 20;
    let maxYPosition = 20;
    let xPosition = 140; 
    let yPosition = 20; 
    dataHeadPdfExport.forEach((data, index) => {
        if (Array.isArray(data.value)) {
            data.value.forEach((item, subIndex) => {
                if (subIndex == 0) doc.setFontSize(10);
                else doc.setFontSize(8);
                const fontColor = subIndex === 0 ? [0, 0, 0] : [48, 48, 48]; 
                doc.setTextColor(...fontColor);
                doc.text(formatItemValue(item), xPosition, yPosition);
                yPosition += 10;
                if (yPosition > maxYPosition) maxYPosition = yPosition;
            });
        }
        xPosition += 120;
        yPosition = yFloorPosition;
        if (xPosition > maxStartPtX) {
            xPosition = 20;
            yPosition = maxYPosition + 10;
            yFloorPosition = maxYPosition + 10;
        }
    });

    // 3. Memo Section (if applicable)
    const memo = dataHeadPdfExport.find(data => !Array.isArray(data.value));
    if (memo) {
        maxYPosition += 10;
        const memoText = memo.value.replaceAll('<br />', '').replaceAll('<br>', '');
        doc.text(memoText, 20, maxYPosition, { maxWidth: sizeContainPtX, align: 'justify' });
        let textDimensions = doc.getTextDimensions(memoText, { maxWidth: sizeContainPtX, align: 'justify' });
        maxYPosition += textDimensions.h;
    }

    // // 4. Table Section
    const tableHeaders = columnOrganize
        .filter(col => columnShow.includes(col.dataField))
        .map(col => ({
            title: col.text.replace('*', ''),
            dataKey: col.dataField,
        }));

    const tableData = bodyData.map(row => {
        const filteredRow = {};
        columnShow.forEach(field => {
            filteredRow[field] = row[field];
        });
        return filteredRow;
    });

    maxYPosition += 10;
    doc.autoTable({
        startY: maxYPosition,
        head: [tableHeaders.map(header => header.title)],
        body: tableData.map(row => 
            tableHeaders.map(header => {
                const cellValue = row[header.dataKey];
                // Vérifie si la valeur est un objet
                if (typeof cellValue === 'object' && cellValue !== null) {
                    return changeObjetToString(cellValue);  
                } else if (cellValue !== null) {
                    return cellValue;
                } else {
                    return '';
                }
            })
        ),
        styles: { fontSize: 6, cellPadding: 4 },
        headStyles: { fontSize: 8, fillColor: [242, 242, 242], textColor: 20, fontStyle: 'bold' },
        margin: { left: 20, right: 20 },
    });

    // // 5. Footer Section
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.text(`${i} / ${pageCount}`, doc.internal.pageSize.width - 50, doc.internal.pageSize.height - 30);
        doc.text('Généré par freebird', 40, doc.internal.pageSize.height - 30);
    }

    // Retourne le PDF en tant que Blob
    return doc.output("blob");
};

export default ExportDatatablePdf;
