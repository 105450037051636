import React from "react";
import {Type} from 'react-bootstrap-table2-editor';

const customFieldsColumnGenerate = (customFields, columnShow, headerSortingStyle, articleEditionDatatable,t,authParam) => {

    const columns = [];
    
    customFields.map((value, index)=>{
        let readAccess = authParam.access.find(access=>access.action == 'article_'+value.name+'_@!'+'read')
        let writeAccess = authParam.access.find(access=>access.action == 'article_'+value.name+'_@!'+'write')
        let textAppend = (!writeAccess) ? '*' : ''

        if(!readAccess){
            let dataInit = {
                dataField: value.name,
                text:  value.name+textAppend,
                hidden: (columnShow.includes(value.name)) ? false : true,
                sort: true,
                headerSortingStyle,
            }
            if(!writeAccess){
                if(value.free){
                    dataInit['validator'] = (newValue, row, column, done) => {
                        articleEditionDatatable(row,column.dataField,newValue,done,false,t)
                        return {
                            async: true
                        };
                    }
                }else{
                    let options = [{'value':t('emptyValue'),'label':t('emptyValue')}]
                    let ouroptions = {}
                    ouroptions[t('emptyValue')]=''

                    value.list.map(item=>{
                        options.push({'value':item,'label':item})
                        ouroptions[item]=item
                    })

                    dataInit['editor'] ={
                        type: Type.SELECT,
                        options: options,
                        ouroptions: ouroptions,
                    }
                    dataInit['validator'] = (newValue, row, column, done) => {
                        articleEditionDatatable(row,column.dataField,column.editor.ouroptions[newValue],done,false,t)
                        return {
                            async: true
                        };
                    }
                }
            }
            else dataInit['editable']=false
            columns.push(dataInit)
        }
    }) 
    
    return columns;
};

export default customFieldsColumnGenerate;