import React, {useState, useRef} from 'react';
import { useTranslate } from 'react-redux-multilingual';
import swal from "sweetalert2";

const AddNewSection = (props)=> {
    const t = useTranslate();
    const [inputValue, setInputValue] = useState('')
    const newSection = useRef('')

    let alreadyIn = Object.keys(props.datas).find(keyName => keyName===inputValue)

    const handleChange = () =>{
        setInputValue(newSection.current.value)
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        if(inputValue){
            if(inputValue != alreadyIn){
                if(props.defaultConfig){
                    props.setNoDefaultConf(false)
                    props.setEmptyConf(false)
                }else{
                    props.setEmptyConf(false)
                }
                props.setDatas({...props.datas, [inputValue]:[]})
                setInputValue('')
            }
        }
        if(inputValue === alreadyIn){
            swal.fire(t('sectionUnique'), "", "error");
        }
    }
    
    return(
        <div className="col-5">
            <form onSubmit={(e)=>onSubmit(e)}>
                <div className="input-group">
                    <input ref={newSection} type="text" className="form-control" placeholder={t('addNewSection')} value={inputValue} onChange={()=>handleChange()} />
                    <button className="btn btn-outline-secondary" >{t('add')}</button>
                </div>
            </form>
        </div>
    )
}

export default AddNewSection;
