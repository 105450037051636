import React, { Fragment,lazy,Suspense } from "react";
import Moment from 'react-moment';
import {Loading} from '@gull'
import { useTranslate } from 'react-redux-multilingual';

const LineInfoArticle = lazy(() => import('./Articles/DetailArticle/LineInfoArticle'));

const HeadInfos = (props) => {
  const t = useTranslate();

  return (
    <>
      <div className="breadcrumb">
          <Fragment>
            {(props.from && props.from=='articleDetail') ?
              <Suspense fallback={<Loading></Loading>}>
                <LineInfoArticle 
                    title={props.titre}
                    isFor='name'
                    allowAccessName='article_name_@!'
                    onEdit={props.onEdit} 
                    handleEdit={props.handleEdit} 
                    editable='true'

                    onSubmit={props.onSubmit} 
                    validButton={props.validButton} 
                    defaultValueEdit={props.titre}
                    defaultValue=''
                    form='header'
                    style='width100'
                    titleSize='fs-3'
                /> 
              </Suspense>
            :
              <span className="d-flex align-items-start" style={{width:'100%'}}>
                <h1>{props.titre}</h1>  
              </span>  
            }
          </Fragment>
          <ul>
            {props.otherHeadInfos
              ? props.otherHeadInfos.map((infos, index) =>(
                    <li key={index}>
                      <span className="capitalize text-muted">{infos}</span>
                    </li>
                  )
                )
              : null}
            {(props.date) 
              ? 
              <li>
                <span className="capitalize text-muted"><Moment format="DD/MM/YYYY HH:mm:ss">{props.date}</Moment></span>
              </li>
              : null
            }
          </ul>
      </div>
      {props.titre2 &&
        <div className="breadcrumb">
          <Fragment>
            {(props.from && props.from=='articleDetail') ?
              <Suspense fallback={<Loading></Loading>}>
                <LineInfoArticle 
                    title={'SKU : '+props.titre2}
                    isFor='sku'
                    allowAccessName='article_sku_@!'
                    onEdit={props.onEdit} 
                    handleEdit={props.handleEdit} 
                    editable='true'

                    onSubmit={props.onSubmit} 
                    validButton={props.validButton} 
                    defaultValueEdit={props.titre2}
                    defaultValue=''
                    form='header'
                    style='width100'
                    titleSize='fs-5'
                /> 
              </Suspense>
            :
              <span className="d-flex align-items-start" style={{width:'100%'}}>
                <h4>{props.titre2}</h4>  
              </span>  
            }
          </Fragment>
        </div>
      }
      {props.titre3 &&
        <div className="breadcrumb">
          <Fragment>
            {(props.from && props.from=='articleDetail') ?
              <Suspense fallback={<Loading></Loading>}>
                <LineInfoArticle 
                    title={t('parent_name')+' : '+props.titre3}
                    isFor='parent_name'
                    allowAccessName='article_parentname_@!'
                    onEdit={props.onEdit} 
                    handleEdit={props.handleEdit} 
                    editable='true'

                    onSubmit={props.onSubmit} 
                    validButton={props.validButton} 
                    defaultValueEdit={props.titre3}
                    defaultValue=''
                    form='header'
                    style='width100'
                    titleSize='fs-5'
                /> 
              </Suspense>
            :
              <span className="d-flex align-items-start" style={{width:'100%'}}>
                <h6>{props.titre3}</h6>  
              </span>  
            }
          </Fragment>
        </div>
      }      
      <div className="separator-breadcrumb border-top"></div>
    </>
  );
};

export default HeadInfos;
