import React, { useState } from "react";
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltH } from '@fortawesome/free-solid-svg-icons'
import {stringNumber} from "app/stringNumber";

const MatchCsv = React.forwardRef((props, ref) => {
    let updateField = (props.updateField) ? props.updateField : false;
    let isForUpdate = (props.isForUpdate) ? props.isForUpdate : false;
    let setIsForUpdate = (props.setIsForUpdate) ? props.setIsForUpdate : false;
    const t = useTranslate()
    const [isMatch,setIsMatch] = useState(props.csvHeader.includes(props.toMatch.replace('*','')))
    let hereUpdate = isForUpdate;

    const handleChange = (e) => {
        props.setPreSendError(false)
        props.setShowValidButton(false)
        if(props.setErrorPrice) props.setErrorPrice(false)
        if(props.setErrorEnumere) props.setErrorEnumere(false)
        if(e.target.value=='no'){ setIsMatch(false)}
        else{
            setIsMatch(true);
        }
        let verifDoublon = new Array()
        let valid=true
        ref.current.forEach((r)=>{
            if(r.value!='no'){
                if(!verifDoublon.includes(r.value)){
                    verifDoublon.push(r.value)
                }
                else{
                    valid=false
                }
            }
        })
        if(!valid){
            props.setPreSendError(t('doublonCsv'))
        }
        else{
            if(updateField){
                props.optionMatchCsv.forEach((v,index) =>{
                    if(index==updateField){
                        if(ref.current[index].value!="no"){
                            hereUpdate=true
                            setIsForUpdate(true);
                        } 
                        else{
                            hereUpdate=false
                            setIsForUpdate(false);
                        } 
                    }
                })
            }

            let indexPriceB2b = props.optionMatchCsv.indexOf(t('priceHtB2b'))
            let indexPriceB2c = props.optionMatchCsv.indexOf(t('priceTtcB2c'))

            let priceOk=true
            let enumereOk=true
            let valid=true
            props.optionMatchCsv.forEach((v,index) =>{
                if(v.match(/\*$/)){
                    if(ref.current[index].value=="no") valid=false
                }
            })

            if(!hereUpdate && props.activeB2c && props.activeB2b && ref.current[indexPriceB2b].value=="no" && ref.current[indexPriceB2c].value=="no"){
                priceOk=false
                if(props.setErrorPrice) props.setErrorPrice(true)
            }
            if(props.maxConditionnement!=0){//si il existe des énumérés de conditionnement 
                for (let i=0; i<props.maxConditionnement; i++){
                    let numeroEnumere = i+1
                    let indexEnumereName = props.optionMatchCsv.indexOf(t('packagingName')+' '+numeroEnumere)
                    let indexEnumereBar = props.optionMatchCsv.indexOf(t('barcodePackaginglist')+' '+numeroEnumere)
                    let indexEnumereQte = props.optionMatchCsv.indexOf(t('packagedQuantity')+' '+numeroEnumere)

                    if(enumereOk && +indexEnumereName>=0 && +indexEnumereBar>=0 && +indexEnumereQte>=0){
                        //A chaque tour de boucle, on vérifie d'abord si il est nécessaire de vérifier si les lignes des énumérés sont ok et si le conditionnement fait partie de la liste de nos données à importer
                        if(ref.current[indexEnumereName].value=="no" && ref.current[indexEnumereBar].value=="no" && ref.current[indexEnumereQte].value=="no"){
                            //On vérifie sur les 3 champs du conditionnement ne sont pas renseignés, si c'est le cas il n'y pas d'érreur
                        }else if(ref.current[indexEnumereName].value!="no" && ref.current[indexEnumereBar].value!="no" && ref.current[indexEnumereQte].value!="no"){
                            //Dans le cas où on renseigne un champ de conditionnement, il faut que les 2 autres soient renseignés 
                        }else{
                            enumereOk=false
                            props.setErrorEnumere(true)
                            break;
                        }
                    }
                }
            }
            
            if((valid && priceOk && enumereOk) || (hereUpdate && enumereOk)){
                props.setShowValidButton(true)
            }
        }
    }

    return(
        <div className="col-12 mb-2">
            <div className="row">
                <div className="col-5" style={{textAlign:"right"}}>
                    <small><span className={(isMatch) ? 'text-success' : (props.toMatch.match(/\*$/) && !isForUpdate) ? 'text-danger' : 'text-light'}><strong>{props.toMatch}</strong></span></small>
                </div>
                <div className="col-2 text-center">
                    <small><FontAwesomeIcon className={(isMatch) ? 'text-success' : (props.toMatch.match(/\*$/) && !isForUpdate) ? 'text-danger' : 'text-light'} icon={faArrowsAltH} /></small>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-4 col-5">
                    <select className="form-control form-control-sm"  defaultValue={(props.csvHeader.includes(props.toMatch.replace('*',''))) ? props.csvHeader.indexOf(props.toMatch.replace('*','')) : 'no'} onChange={(e) => handleChange(e)} ref={(element) => (!ref.current[props.index]) && ref.current.splice(props.index,1,element)}>
                        <option value="no">-- Select --</option>
                        {props.csvHeader.map((value,index)=>
                            <option key={index} value={index}>{stringNumber[index]+' - '+value}</option>
                        )}
                    </select>
                </div>
            </div>
        </div>
    )
})

export default MatchCsv