import React, {useState,useEffect,useContext} from "react";
import { useTranslate } from 'react-redux-multilingual'
import {CardPerso,ColumDataTableReorganize} from "@gull";
import axios from 'axios'
import swal from "sweetalert2";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Moment from 'react-moment';
import { IsAuthContext} from 'app/App';
import { useCookies} from 'react-cookie';
import {scrollXDataTable} from 'app/scrollXDataTable';
import {Link} from 'react-router-dom'

const useSearchClients = (clientId,tabKey) => {
    const t =useTranslate()
    const [allDevis,setAllDevis]=useState([])
    const [load,setLoad]=useState({
        allTicket:false,
    })
    const [sizeDatas,setSizeDatas]=useState(0)

    useEffect(function () {
        (async function() {
            if(tabKey=='devis'){
                setLoad({...load,allTicket:true})
                const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/devis/get_by_period/1/50/0/0",{
                    expire:0,
                    client:clientId
                }).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                    }
                    else{   
                        setAllDevis(response.data.datas)
                        setSizeDatas(response.data.count)
                        setLoad({...load,allTicket:false})
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    swal.fire(t('unknowError'), "", "error");
                })
                setLoad({...load,allTicket:false})
            }
        })()
    }, [clientId,tabKey])

    return [load,allDevis,setAllDevis,sizeDatas,setSizeDatas]
}

const CustomDataTable = (props) => {
    const t = useTranslate();
    
    useEffect(() => {
        scrollXDataTable(window.screen.width,props.columns.length)
    }, [window.screen.width,props.columns.length]);
    
    return (
        <BootstrapTable
            {...props.propst.baseProps}
            bootstrap4
            remote
            pagination={ paginationFactory(props.paginationOptions) }
            onTableChange={ props.handleTableChange }
            noDataIndication={props.load.allTicket ? <div className="spinner-border spinner-border-sm"></div> : t('tableNoData')}
            defaultSorted={props.defaultSorted}
            rowStyle={ props.rowStyle }
        />
    );
};  

const HistoDevisClient = (props) => {
    const t =useTranslate()
    const [load,allDevis,setAllDevis,sizeDatas,setSizeDatas]=useSearchClients(props.clientId,props.tabKey)
    const [perPage,setPerPage] = useState(50)
    const [currentPage,setCurrentPage] = useState(1)

    const [cookies, setCookie] = useCookies(['client_histo_devis_reorganize']);
    const [columnOrganize,setColumnOrganize]=useState([])
    const [showColumnOrganize,setShowColumnOrganize]=useState(false) 

    let current_date = new Date()
    let timestampdate = current_date.setDate(current_date.getDate() + 90)
    let expire_date = new Date(timestampdate)

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={ text }
            role="presentation"
            className="dropdown-item"
            onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
            } }
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'black' } }
            >
                { text }
            </a>
        </li>
    );

    let paginationOptions = {
        page:currentPage,
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        sizePerPageOptionRenderer,
        sizePerPage: perPage,
        showTotal: true,
        totalSize: sizeDatas
    };

    let dataTable = new Array
    allDevis.length!=0 && allDevis.map((value,ind)=>{
        let obj = {
            ticket: value.bar,
            masque:'',
            client: (value.ticket.client!=null) ? value.ticket.client.name+' '+value.ticket.client.firstname : '',
            originShop:value.ticket.section.name,
            dateExpire: <Moment format="DD/MM/YYYY">{value.validite}</Moment>,
            dateCreation: <Moment format="DD/MM/YYYY">{value.created_at}</Moment>,
            expire:value.expire,
            id: value.id,
            action: 
                <span className="d-flex justify-content-between align-items-center mt-1" style={{marginLeft:'0.2em'}}>
                    <Link className="btn btn-light btn-sm" to={'/erp/caisse/devis/'+value.id}>{t('detail')}</Link>
                </span>,
            index: ind + 1,
        }
        dataTable.push(obj)
    })

    let defaultSorted = [
        {
          dataField: "date",
          order: "desc"
        }
    ];

    let columns = [
        {
            dataField: "action",
            text: 'Action',
            editable: false,
            headerClasses: 'head-action-row',
            classes: 'body-action-row',
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    padding:'0',
                };
            },
        },
        {
            dataField: "masque",
            text: '',
            editable: false,
            formatter: (rowContent, row) => {
                return (    
                    <div style={{display:"block",width:"82px"}}></div>
                )
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    width:'99px',
                    backgroundColor:'white'
                };
            },
        },
        {
            dataField: "ticket",
            text: t('quotation')+' n°',
            editable: false,
        },
        {
            dataField: "client",
            text: t('customer'),
            editable: false,
        },
        {
            dataField: "originShop",
            text: t('origin_section'),
            editable: false,
        },
        {
            dataField: "dateCreation",
            text: t('createdAt'),
            editable: false,
        },
        {
            dataField: "dateExpire",
            text: t('dateExpire'),
            editable: false,
        },
    ];

    useEffect(function () {
        let columnCookie
        let arrCol = []
            
        if(cookies.client_histo_devis_reorganize){
            let newValueToIncludeInCookie
            let cookieLecture = cookies.client_histo_devis_reorganize.split(',')

            let already_done=[];
            cookieLecture.map(value=>{
                columns.map(item=>{
                    if(item.dataField==value){
                        arrCol.push(item)
                        already_done.push(value);
                    }
                })
            })
            columns.map(item=>{
                if(!already_done.includes(item.dataField)){ 
                    //si une nouvelle à été ajouté aux customsField, elle n'est pas ajoutée desuite au cookie de reorganisation si celui ci existe
                    //dans le cas où il n'existerait pas, on va créer un nouveau cookie afin de l'inclure pour ne pas avoir d'erreur sur les export 
                    newValueToIncludeInCookie=true
                    arrCol.push(item);
                }
            })

            columnCookie = arrCol
            if(newValueToIncludeInCookie==true){ 
                let arr = []
                arrCol.map(value=>
                    arr.push(value.dataField)
                )
                setCookie("client_histo_devis_reorganize",arr.join(),{ path: "/", expires: expire_date })
            }
        }else{
            columnCookie = columns
        } 
        setColumnOrganize(columnCookie)
    }, [])

    const handleTableChange = async (type, { page, sizePerPage }) => {
        setPerPage(sizePerPage)
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/devis/get_by_period/"+page+"/"+sizePerPage+'/0/0',{
            expire:0,
            client:props.clientId
        }).then((response) => {
            setAllDevis(response.data.datas)
            setSizeDatas(response.data.count)
            setCurrentPage(page)
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
    }

    const rowStyle = (row, rowIndex) => {
        const style = {};

        if(row.expire){
            style.backgroundColor = '#d9fcd2';//finalisé
        }else{
            style.backgroundColor = '#ffffbb';//en cours
        }

        return style;
    };

    return(<>
        <CardPerso saClass='position-relative'>
            <div className="mb-2">
                <ColumDataTableReorganize 
                    columnOrganize={columnOrganize}
                    setColumnOrganize={setColumnOrganize}
                    setCookie={setCookie}
                    cookieName='client_histo_devis_reorganize'
                    showColumnOrganize={showColumnOrganize}
                    setShowColumnOrganize={setShowColumnOrganize}
                    btnSwitch={false}//pour affiche ou non le btn swith
                    initSwith={true}//initialier à false il affichera par défaut le toggle des colonnes, sur true il affichera le drag and drop pour changer la position des colonnes
                />
            </div>
            {(load.allTicket) ? 
                <div className="d-flex justify-content-center"><span className="spinner-border spinner-border-sm"></span></div> 
            :
                columnOrganize.length!=0 && 
                    <small>
                        <ToolkitProvider
                            keyField="index"
                            data={dataTable}
                            columns={ columnOrganize }
                        >
                            {(propst) => (
                                <div className="position-relative">
                                    <CustomDataTable
                                        propst={propst}
                                        paginationOptions={paginationOptions}
                                        handleTableChange={handleTableChange}
                                        load={load}
                                        defaultSorted={defaultSorted}
                                        rowStyle={rowStyle}
                                        columns={columns}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                    </small>
            }
        </CardPerso>
        </>
    )
    
}

export default HistoDevisClient;