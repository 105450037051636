import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { Alert } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';

const PaymentCompleted = () =>{
    const t = useTranslate();
    
    return(<>
        <Alert variant="success">
            <div style={{fontSize:'3em'}}><FontAwesomeIcon className='mx-auto d-flex justify-content-center' icon={faCheckCircle} /></div>
            <hr />
            <Alert.Heading className='text-center'>{t('paid')}</Alert.Heading>
        </Alert>
    </>
    )

}

export default PaymentCompleted;