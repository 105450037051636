import React, { useState,useRef } from 'react';
import {InpDataList} from '@gull'
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";
import ClientDetails from './ClientDetails'

const useLoad = () => {
    const t = useTranslate();

    const [loadingSearchClient,setLoadingSearchClient]=useState(false)
    const [listSearchClient,setListSearchClient]=useState([])
    const [datas,setDatas]=useState([])
    const searchInputClient = useRef('')

    const loadClients = async (toSearch) => {
        setLoadingSearchClient(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/client/name/"+toSearch
        ).then((response) => {
            setListSearchClient(response.data.datas)
            let initial = []
            response.data.datas.forEach((value)=>
                initial.push({
                    id: value.id,
                    name: value.name+' '+value.firstname+' - '+value.email+' - '+value.tel
                })
            )
            setListSearchClient(initial)
            let ajour = initial.filter((value)=>value.name.toLowerCase().includes(searchInputClient.current.value.toLowerCase()))
            setDatas(ajour)
            setLoadingSearchClient(false)
        }).catch((error) => {
            if(error=='end_session') return;
            setLoadingSearchClient(false)
            swal.fire(t('unknowError'), "", "error");
        })
    }

    return [loadingSearchClient,loadClients,listSearchClient,datas,setDatas,searchInputClient]
}

const FusionClientForm = (props) =>{
    const t = useTranslate();
    const [loadingSearchClient,loadClients,listSearchClient,datas,setDatas,searchInputClient] = useLoad()
    const [clientId,setClientId]=useState(false) 
    const [valueInputClient,setValueInputClient]=useState('')
    const [lastSearch,setLastSearch]=useState(false) 
    const dataListDropClient = useRef('')
    const [validButton,setValidButton]=useState() 
    const [success,setSuccess] = useState(false)

    const [loading,setLoading]=useState(false) 
    const [infoClient,setInfoClient]=useState(false) 

    const handleReset = () =>{
        setClientId(false)
        setValueInputClient('')
        setInfoClient(false)
    }
    
    
    const handleSearch = (e,declenchement) => {
        setValueInputClient(e.target.value)
        if(e.target.value!=''){
            if(e.target.value.length>=declenchement){
                dataListDropClient.current.classList.remove("d-none");
    
                if(!lastSearch || e.target.value.substring(0, declenchement) != lastSearch.substring(0, declenchement)){
                    loadClients(e.target.value.substring(0, declenchement))
                    setLastSearch(e.target.value.substring(0, declenchement))
                }
            }else{
                dataListDropClient.current.classList.add("d-none");
                setLastSearch(false)
            }
            if(e.target.value.length!=declenchement){
                let ajour = listSearchClient.filter((value)=>value.name.toLowerCase().includes(e.target.value.toLowerCase()))
                setDatas(ajour)
            }
        }
    }

    const handleCLick = async (id,valeur) => {
        setValueInputClient(valeur)
        dataListDropClient.current.classList.add("d-none");
        setDatas([]) 
        setClientId(id)
        setLoading(true)

        const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/fidelite/get_fidelite_client/"+id
        ).then((response) => {
            let obj = {...response.data.client,total_point_fidelite_client:response.data.total_point_fidelite_client}
            setInfoClient(obj)
            setLoading(false)
        }).catch((error) => {
            if(error=='end_session') return;
                let messageError
                if(error.response==undefined){
                    messageError='unknowError'
                }
                else if(error.response.status==403){
                    messageError='forbiddenGeneral'
                }
                else {
                    messageError='unknowError'
                }
                
                swal.fire(messageError, "", "error");
            setLoading(false)
        })
    }

    const mergeCustomer = async () =>{
        if(clientId){
            setValidButton(true)
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/client/fusion_client/"+props.currentClient+"/"+clientId
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach((interm)=>{
                        let finalMessage=interm.split('/!');
                        if(finalMessage.length==2){
                            messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                        }
                        else{
                            messageError+=t(interm)+'<br/>'
                        }
                    })
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                }else{
                    setSuccess(true)
                    handleReset()
                    props.setShowFusion(false)
                    props.setRelance(Date.now())
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let messageError = (error.response==undefined) ? t('unknowError') : (error.response.status==403) ? t('forbiddenGeneral') : t('unknowError');
                swal.fire(messageError, "", "error");
            })
            setValidButton(false)
            setTimeout(()=>{
                setSuccess(false)
            },1000)
        }else{
            swal.fire(t('allFieldsRequired'), "", "error");
        }
    }

    return (
        <div>
            
            <div className="row mb-2 d-flex justify-content-center align-items-center">
                <div className="col-10">
                    <div className='alert alert-info mb-2'>{t('fusionClientInfo',{clientName: props.client.name+' '+props.client.firstname})}</div>
                </div>
                <div className="col-8 position-relative">
                    <div className='form-group'>
                        <label>{t('selectCustomer')+' :'}</label>
                        <InpDataList
                            placeholder={t('customerPlacehlder')}
                            value={valueInputClient}
                            onClick={handleCLick}
                            onChange={handleSearch}
                            datas={datas}
                            declenchement={3}
                            listRef={dataListDropClient}
                            inputRef={searchInputClient}
                        />
                    </div>
                    {(loadingSearchClient) ? <span><div className="spinner-border spinner-border-sm erpGiftCardPosition"></div></span> : false }
                </div>
                <div className='col-2'>
                    <button disabled={validButton} className="btn btn-sm btn-warning" onClick={()=>handleReset('reset')}>
                        Reset
                    </button>&nbsp;&nbsp;
                    <button disabled={validButton} className="btn btn-sm btn-primary" onClick={()=>mergeCustomer()}>
                        {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                    </button>
                </div>
                <div className="col-10">
                    {(success) ? <div className="alert alert-success mt-3">{t('successOperation')}</div> : false}
                </div>
            </div>
            {(loading) ?
                <span className='d-flex justify-content-center mt-2'><div className="spinner-border spinner-border-sm"></div></span>
            :
                infoClient ?
                    <ClientDetails client={infoClient}/>
                :
                    false
            }
        </div>
    )
};

export default FusionClientForm;