import React, {useState,useRef} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { InpDataList,CustomerRewards,CardPerso} from "@gull";
import axios from 'axios'
import swal from "sweetalert2";
import { useParams } from "react-router-dom";

const useSearchClients = () => {
    const t =useTranslate()
    const [loadingSearchClient,setLoadingSearchClient]=useState(false)
    const [listSearchClient,setListSearchClient]=useState([])
    const [datas,setDatas]=useState([])
    const searchInputClient = useRef('')

    const [clientFideliteInfo,setClientFideliteInfo]=useState(false) 

    const loadClients = async (toSearch) => {
        setLoadingSearchClient(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/client/name/"+toSearch
        ).then((response) => {
            setListSearchClient(response.data.datas)
            let initial = []
            response.data.datas.forEach((value)=>
                initial.push({
                    id: value.id,
                    name: value.name+' '+value.firstname+' - '+value.email+' - '+value.tel
                })
            )
            setListSearchClient(initial)
            let ajour = initial.filter((value)=>value.name.toLowerCase().includes(searchInputClient.current.value.toLowerCase()))
            setDatas(ajour)
            setLoadingSearchClient(false)
        }).catch((error) => {
            if(error=='end_session') return;
            setLoadingSearchClient(false)
            swal.fire(t('unknowError'), "", "error");
        })
    }

    const getFideliteInfo = async (clientId) => {
        setLoadingSearchClient(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/fidelite/get_fidelite_client/"+clientId
        ).then((response) => {
            setLoadingSearchClient(false)
            setClientFideliteInfo(response.data)
            
        }).catch((error) => {
            setLoadingSearchClient(false)
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
    }

    return [loadingSearchClient,loadClients,listSearchClient,datas,setDatas,searchInputClient,getFideliteInfo,clientFideliteInfo]
}

const SearchHistoriqueFidelite = () => {
    const t =useTranslate()
    const { posid } = useParams();
    const [loadingSearchClient,loadClients,listSearchClient,datas,setDatas,searchInputClient,getFideliteInfo,clientFideliteInfo]=useSearchClients()
    const [valueInputClient,setValueInputClient]=useState('')
    const dataListDropClient = useRef('')
   
    const [lastSearch,setLastSearch]=useState(false) 


    const handleSearch = (e,declenchement) => {
        setValueInputClient(e.target.value)
        if(e.target.value.length>=declenchement){
            dataListDropClient.current.classList.remove("d-none");

            if(!lastSearch || e.target.value.substring(0, declenchement) != lastSearch.substring(0, declenchement)){
                loadClients(e.target.value.substring(0, declenchement))
                setLastSearch(e.target.value.substring(0, declenchement))
            }
        }else{
            dataListDropClient.current.classList.add("d-none");
            setLastSearch(false)
        }
        if(e.target.value.length!=declenchement){
            let ajour = listSearchClient.filter((value)=>value.name.toLowerCase().includes(e.target.value.toLowerCase()))
            setDatas(ajour)
        }
    }

    const handleCLick = (id,valeur) => {
        setValueInputClient(valeur)
        dataListDropClient.current.classList.add("d-none");
        setDatas([]) 
        getFideliteInfo(id)
    }

    return(<>
        <div className="row d-flex justify-content-center mt-4 mb-4 position-relative">
            <div className="col-8">
                <InpDataList
                    placeholder={t('customerPlacehlder')}
                    value={valueInputClient}
                    onClick={handleCLick}
                    onChange={handleSearch}
                    datas={datas}
                    declenchement={3}
                    listRef={dataListDropClient}
                    inputRef={searchInputClient}
                />
            </div>
            {(loadingSearchClient) ? <span><div className="spinner-border spinner-border-sm historiqueGiftCardPosition"></div></span> : false }
        </div>
        <CardPerso>
            {!clientFideliteInfo && <div className="text-center fw-bold">{t('customerLoyalty')}</div> }
            {clientFideliteInfo &&
                <>
                    <div className="text-center fw-bold mb-2"> 
                        <span className="fs-3">{t('accumulatedPoints')+' : '}</span>
                        <span className="fs-2">{(clientFideliteInfo) ? clientFideliteInfo.total_point_fidelite_client : ''}</span>
                    </div>
                    <CustomerRewards 
                        clientFideliteInfo={clientFideliteInfo}
                        posid={posid}
                        from='histoClient'
                    />
                </>
            }
        </CardPerso>
        </>
    )
    
}

export default SearchHistoriqueFidelite;