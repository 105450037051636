import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { Accordion } from "react-bootstrap";
import EachNotification from './EachNotification';

const SalesOrderNotifConf = (props) =>{
    const t = useTranslate();

    if(props.moduleAdmin.params.commande_client.active){
        return (
            <Accordion className='mb-2'>
                <Accordion.Item eventKey='salesOrder'>
                    <Accordion.Header>
                        <span style={{color:props.notificationsType.salesOrder.bg, fontWeight:'bold',fontSize:'0.9rem'}}>
                            {t('salesOrder')}&nbsp;
                            <i style={{color:props.notificationsType.salesOrder.bg}} className={`${props.notificationsType.salesOrder.icon} header-icon fs-5`}></i>
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <>
                             <EachNotification  allUsers={props.allUsers} sectionId={props.sectionId} notifState={props.notifState} setNotifState={props.setNotifState} typeNotif="newEcomOrder" bgcolor="#ffffc3" disabled={props.disabled} setDisabled={props.setDisabled}/>
                        </>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        )
    }else{
        return(<></>)
    }
};

export default SalesOrderNotifConf;