import React, {useState,useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";

const useLoad = (relanceEtiquetteTemplates) => {
    const t = useTranslate()
    const [etiquetteTemplates,setEtiquetteTemplates]=useState([])
    const [loading,setLoading] = useState(true)
    
    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/etiquette_template/get_all"
            ).then((response) => {
                if(response.data.valid){   
                    setEtiquetteTemplates(response.data.datas)
                }
                else swal.fire(t('unknowError'));
                setLoading(false)
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
                setLoading(false)
            })
        })()
    }, [relanceEtiquetteTemplates])

    return [etiquetteTemplates,loading]
}

const SelectEtiquette = (props) => {
    const t = useTranslate();
    const [etiquetteTemplates,loading]=useLoad(props.relanceEtiquetteTemplates)

    useEffect(function () {
        if(props.templateEdit){

            if(props.listPrint){
                //Dans le cas d'une impréssion d'une liste d'article, on setArticle au premier article de la liste
                props.setArticle(props.articleListPrint[0].article)
                props.setRelance(Date.now())
            }else if(props.articleSelected){
                //Lorsque ce composant est utilisé dans la modal qui permet d'imprimer des étiquettes, on a au préalable sélectionné un article
                //On renseigne alors cette article dans le choix de l'article à imprimer 
                props.setArticle(props.articleSelected)
                props.setRelance(Date.now())
            }else{
                //Lorsqu'on est dans le module administration
                props.setArticle(props.templateEdit.article)
            }
            
            props.setCanvasCm({
                width:props.templateEdit.width,
                height:props.templateEdit.height,
            })
            props.setElements(props.templateEdit.elements)
            props.setEtiquetteName(props.templateEdit.name)
            props.setValidSize(true)
        }
    }, [props.templateEdit]) 

    return (
        <>
            <div className="row mb-3">
                <div className="col-lg-6 form-group position-relative">
                    <label>{t('selectionTemplate')}</label>
                    <select disabled={loading} className="form-control" value={(props.templateEdit) ? props.templateEdit.id : '0'} onChange={(e) => props.setTemplateEdit(etiquetteTemplates.find(value=>value.id==e.target.value))}>
                        <option value="0">-- {t('selectionTemplate')} --</option>
                        {etiquetteTemplates.map(value=>
                            <option key={value.id} value={value.id}>{value.name+' ('+value.width+'cm X '+value.height+'cm)'}</option>
                        )}
                    </select>
                    {loading ? <span style={{position:'absolute', top:'55%'}}><span>&nbsp;</span><span className="spinner-border spinner-border-sm"></span></span> : false}
                </div>
            </div>
        </>
    )
}

export default SelectEtiquette;