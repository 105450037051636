import swal from "sweetalert2";
import axios from "axios";
import https from "https"

const zebra_zd421 = (ip_addr,hostname,tagFormated,setUrlCertificat,redirectCertificat) =>{
    let url

    if(hostname!=null) {
        url = "https://"+hostname+"/portcom/printEtiquette.php?ip="+ip_addr+'&etiquette='+tagFormated

    }else {
        url = 'http://localhost/portcom/printEtiquette.php?ip='+ip_addr+'&etiquette='+tagFormated
    }

    const response = axios.get(url
    ).then((response) => {
        console.log('good')
    }).catch((error) => {
        console.log(error)
        if (hostname!=null) {
            setUrlCertificat(url)
            setTimeout(() => {
                redirectCertificat.current.click()
            }, 500);
        }
        // if (error.response) {
        //     // la requête a été faite et le code de réponse du serveur n’est pas dans
        //     // la plage 2xx
        //     console.log('1',error.response.data);
        //     console.log('2',error.response.status);
        //     console.log('3',error.response.headers);
        // } else if (error.request) {
        //     // la requête a été faite mais aucune réponse n’a été reçue
        //     // `error.request` est une instance de XMLHttpRequest dans le navigateur
        //     // et une instance de http.ClientRequest avec node.js
        //     console.log('4',error.request);
        // } else {
        //     // quelque chose s’est passé lors de la construction de la requête et cela
        //     // a provoqué une erreur
        //     console.log('Error', error.message);
        // }
        // console.log('5',error.config);
        // console.log('6',error.toJSON());
    })
}

export const imprimeEtiquette = (peripheriqueParam,devise,formatedPrice,t,articleName,articleUpc,quantityTag,from,sizeTag,setUrlCertificat,redirectCertificat) =>{
    let hauteurArticleName = (sizeTag=='300') ? '70' : '40'
    let hauteurUpc = (sizeTag=='300') ? '90' : '55'
    let hauteurPrice = (sizeTag=='300') ? '230' : '180'

    let formatedTag = "^XA^A0,20,15^FT20,"+hauteurArticleName+"^FB280,2,0,C^FH\^FD"+articleName.replace(/[^\w\s]/gi, '')+"^FS^BY1,2,60^FO70,"+hauteurUpc+"^A0,15,28^BC^FD"+articleUpc+"^FS^A0,20,20^FT20,"+hauteurPrice+"^FB280,2,0,C^FH\^FD"+formatedPrice+devise+"^FS^PQ"+quantityTag+"^XZ"
    
    if(from=='wms' || from=='erp'){
        if(peripheriqueParam.model=='zebra_zd421'){
            zebra_zd421(peripheriqueParam.host,peripheriqueParam.hostname,formatedTag,setUrlCertificat,redirectCertificat)
        }

    }else{
        if(peripheriqueParam && peripheriqueParam.imprimante_etiquette && peripheriqueParam.imprimante_etiquette.model=='zebra_zd421'){
            //'192.168.88.130'
            zebra_zd421(peripheriqueParam.imprimante_etiquette.host,peripheriqueParam.imprimante_etiquette.hostname,formatedTag,setUrlCertificat,redirectCertificat)
        }else{
            swal.fire(t('noConfiguredPeripheral'), "", "error");
        }  
    }
}

export const imprimeCaisseIdent = (peripheriqueParam,caisseIdent,from,t) =>{
    let formatedTag =  "^XA^BY1,1,70^FO20,50^A0,20,28^BC^FD"+caisseIdent.replace(/[^\w\s]/gi, '')+"^XZ" 

    if(from=='pos'){
        if(peripheriqueParam && peripheriqueParam.imprimante_etiquette && peripheriqueParam.imprimante_etiquette.model=='zebra_zd421'){
            //'192.168.88.130'
           zebra_zd421(peripheriqueParam.imprimante_etiquette.host,peripheriqueParam.imprimante_etiquette.hostname,formatedTag)
        }else{
            swal.fire(t('noConfiguredPeripheral'), "", "error");
        }  
    }else{
        zebra_zd421(peripheriqueParam,formatedTag)
    }    
}