import React, {useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import Moment from 'react-moment';
import {Accordion} from 'react-bootstrap';
import { useHistory } from "react-router-dom";

const BtnAccordionLien = (props) => {
    const t = useTranslate();
    const history = useHistory();

    //props.selectionIds est une liste d'ids qu'on utilise si on est dans le cas ou l'utilisateur peut faire une sélection sur la liste proposé
    //Si pas de sélleciton possible, le click sur un des boutons listé ferra une redirection sur l'url rensignée
    useEffect(function () {
        //Si on désélectionne tous les bons de commande qu'on avait sélectionné, on reset currentAccordion afin d'afficher les autres accordions
        if(!props.redirection && props.selectionIds.length==0) props.setCurrentAccordion('')
    }, [props.selectionIds])

    const handleClick = (selectedElementId) => {
        if(!props.redirection ){
            if(props.currentAccordion=='') props.setCurrentAccordion(props.title)
        
            if(props.selectionIds.includes(selectedElementId)){
                //Si le bon de commande avait été sélectionné, on le désélectionne
                let newList = props.selectionIds.filter(value=>value!=selectedElementId)
                props.setSelectionIds(newList)
            }else{
                //sinon on l'ajoute
                props.setSelectionIds([...props.selectionIds,selectedElementId])
            }
        }else{
            if(props.url) history.push(props.url+selectedElementId)
        }
    };

    return(
        <div>
            <Accordion>
                <Accordion.Item eventKey={props.index.toString()}>
                    <Accordion.Header><span className="fw-bold">{props.title}</span></Accordion.Header>
                        <Accordion.Body>
                            {props.data.map(value=>{
                                return(
                                    <div  key={value.id} className="row mb-2">
                                        <button 
                                            disabled={props.validButton}
                                            className={`${(props.redirection || (props.selectionIds && props.selectionIds.includes(value.id))) ? 'btn btn-sm btn-info' : 'btn btn-sm btn-outline-info fw-bold'}`} 
                                            onClick={()=>handleClick(value.id)}
                                        >
                                            {value.bar} <br /> <Moment format="DD/MM/YYYY">{value.created_at}</Moment>
                                        </button>
                                    </div>
                                )
                            })}
                        </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
};

export default BtnAccordionLien;