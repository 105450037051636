import React from "react";
import { useTranslate } from 'react-redux-multilingual';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/free-solid-svg-icons';

const  ProfilActionButton = (props) => {
    const t = useTranslate();

    return (
        <button type="button" onClick={() => props.setShow(true)} className="btn btn-sm btn-outline-primary mt-3">
            <FontAwesomeIcon icon={faCogs} /> {t('parameterName')}
        </button>
    )
}

export default ProfilActionButton;