import React from "react";
import { useTranslate } from 'react-redux-multilingual';

const  SubmitFom = (props) => {
    const t = useTranslate();

    return (<>
        {(props.error) ? <div className="alert alert-danger mt-3" dangerouslySetInnerHTML={{ __html: props.error }}></div> : false}
        {(props.success) ? <div className="alert alert-success mt-3">{t('successOperation')}</div> : false}
        <button disabled={props.validButton} className="btn btn-primary mt-3">
            {props.validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
        </button>
    </>)
}

export default SubmitFom;