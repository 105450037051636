import React,{useState} from "react";
import { useTranslate } from 'react-redux-multilingual'
import {constructionDocument} from '@gull'

const CancelDiscount =  (props) =>{
    const t =useTranslate()
    const [validButton,setValidButton]=useState(false)

    const cancelDiscount = () => {
        let obj = {
            cancel_remise:true
        }
        if(props.forGroup) obj.group_detail_id=props.groupId
        else obj.detail_id=props.item.id
        constructionDocument(setValidButton,props.id,callBackConstruct,t,obj,props.setItemsBilling,props.setAllInfos)
    }

    const callBackConstruct = (response) => {
        props.closeModal('price')
    };

    return(
        <>
            <div className="row text-center">
                <button disabled={validButton} className="btn btn-danger" onClick={() => cancelDiscount()}>{t('btnCancelDiscount')}</button>
            </div>
        </>
    )
}

export default CancelDiscount;