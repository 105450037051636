import React, { useState } from "react";
import { useTranslate } from 'react-redux-multilingual'
import { Form } from "react-bootstrap";

const CheckBoxShop = (props) =>{
    const t = useTranslate()
    
    let  checkedShop = new Array;
    if(props.modalEditId){
        (Object.keys(props.promoOne).length!= 0) && props.promoOne.section.map(value=>
            checkedShop.push(value.id)    
        )
    }
    
    let checkedValue = (!props.modalEditId) ? true : (checkedShop.includes(props.section.id)) ? true : false

    const [checked,setChecked]=useState(checkedValue)

    const handleChange = (e) => {
        setChecked(!checked);
    }

    return (
        <Form.Check
            {...props.register("section[]")}
            type="switch"
            id="custom-switch"
            checked={checked}
            value={props.section.id}
            label={props.section.code}
            onChange={(e) => handleChange(e)}
        />
    )
}

export default CheckBoxShop;
