import React, {useState,useRef,useEffect,useContext} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { InpDataList,ViewPdfButton, CardPerso,ModalChangePaymentMethod,ChangePaymentMethodBtn,PrintTicketButton,ReOpenSaleOrderBtn,BtnCopyClipBoard, ColumDataTableReorganize} from "@gull";
import axios from 'axios'
import swal from "sweetalert2";
import { useParams } from "react-router-dom";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Moment from 'react-moment';
import TicketCommande from "../../../app/views/Pdf/TicketCommande"
import { BlobProvider } from '@react-pdf/renderer';
import { Modal } from "react-bootstrap";
import { IsAuthContext,PeripheriqueContext } from '../../../app/App';
import { epson_tm_h6000VOrder } from 'app/imprime';
import { conditionsRetour } from 'app/conditionsRetour';
import { espace_mille } from 'app/espace_mille';
import { modePaiementMustBeTranslate } from 'app/modePaiementDefaultAll';
import { useCookies} from 'react-cookie';
import {scrollXDataTable} from 'app/scrollXDataTable';

const useSearchClients = () => {
    const t =useTranslate()
    const { posid } = useParams();
    const [loadingSearchClient,setLoadingSearchClient]=useState(false)
    const [listSearchClient,setListSearchClient]=useState([])
    const [datas,setDatas]=useState([])
    const searchInputClient = useRef('')

    const [allSections,setAllSections]=useState([]) 
    const [thisSection,setThisSection]=useState({}) 
    const [otherCompanyId,setOtherCompanyId]=useState([]) 

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/section"
            ).then((response) => {
                setAllSections(response.data.datas)

                let actualShop = response.data.datas.find(item=>item.id==posid)
                let arr = []
                if(actualShop.other_company!=null){
                    actualShop.other_company.map(value=>
                        arr.push(value.id)
                    )
                }
                setOtherCompanyId(arr)
                setThisSection(actualShop)
            }).catch((error) => {
                if(error=='end_session') return;
                let messageError
                if(error.response==undefined){
                    messageError='unknowError'
                }else if(error.response.status==403){
                    messageError='forbiddenGeneral'
                }else {
                    messageError='unknowError'
                }
                swal.fire(t(messageError), "", "error");
            })
        })()
    }, [])

    const loadClients = async (toSearch) => {
        setLoadingSearchClient(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/client/name/"+toSearch
        ).then((response) => {
            setListSearchClient(response.data.datas)
            let initial = []
            response.data.datas.forEach((value)=>
                initial.push({
                    id: value.id,
                    name: value.name+' '+value.firstname+' - '+value.email+' - '+value.tel
                })
            )
            setListSearchClient(initial)
            let ajour = initial.filter((value)=>value.name.toLowerCase().includes(searchInputClient.current.value.toLowerCase()))
            setDatas(ajour)
            setLoadingSearchClient(false)
        }).catch((error) => {
            if(error=='end_session') return;
            setLoadingSearchClient(false)
            swal.fire(t('unknowError'), "", "error");
        })
    }

    return [loadingSearchClient,loadClients,listSearchClient,datas,setDatas,searchInputClient,otherCompanyId,allSections,thisSection]
}

const CustomDataTable = (props) => {
    const t = useTranslate();
    
    useEffect(() => {
        scrollXDataTable(window.screen.width,props.columns.length)
    }, [window.screen.width,props.columns.length]);
    
    return (
        <BootstrapTable
            {...props.propst.baseProps}
            bootstrap4
            remote
            pagination={ paginationFactory(props.paginationOptions) }
            onTableChange={ props.handleTableChange }
            noDataIndication={props.load.allTicket ? <div className="spinner-border spinner-border-sm"></div> : t('tableNoData')}
            defaultSorted={props.defaultSorted}
            rowStyle={ props.rowStyle }
        />
    );
};  

const SearchHistoriqueCommande = (props) => {
    const t =useTranslate()
    const [loadingSearchClient,loadClients,listSearchClient,datas,setDatas,searchInputClient,otherCompanyId,allSections,thisSection]=useSearchClients()
    const [valueInputClient,setValueInputClient]=useState('')
    const [allSalesOrder,setAllSalesOrder]=useState([])
    const [load,setLoad]=useState({
        allTicket:false,
    })
    const dataListDropClient = useRef('')
    const { posid } = useParams();
    const [clientId,setClientId]=useState(false)
    const [perPage,setPerPage] = useState(50)
    const [currentPage,setCurrentPage] = useState(1)
    const [sizeDatas,setSizeDatas]=useState(0)

    const [relance,setRelance]=useState(Date.now())
    const [ticketRef,setTicketRef]=useState(false)
    const [ticketPdf,setTicketPdf]=useState({})
    const [raisonPrint,setRaisonPrint]=useState(false)
    const [totalAchat,setTotalAchat]=useState(0)
    const [totalTgc,setTotalTgc]=useState(false)
    const [loadTicket,setLoadTicket]=useState(false)
    const btnPdf = useRef('')

    const [show,setShow]=useState(false)
    const [barTicket,setBarTicket]=useState(false)
    const { authParam } = useContext(IsAuthContext)
    const { peripheriqueParam } = useContext(PeripheriqueContext)  
    const [lastSearch,setLastSearch]=useState(false) 

    const [cookies, setCookie] = useCookies(['search_histo_commande_reorganize']);
    const [columnOrganize,setColumnOrganize]=useState([])
    const [showColumnOrganize,setShowColumnOrganize]=useState(false) 
    const [currentSectionData,setCurrentSectionData]=useState(posid) 

    let current_date = new Date()
    let timestampdate = current_date.setDate(current_date.getDate() + 90)
    let expire_date = new Date(timestampdate)

    let devise = authParam.company.devise;
    let digit = (authParam.company.devise === "XPF") ? 0 : 2;

    let thisShop = authParam.sections.find(shop=>shop.id == posid)

    useEffect(function () {
        (async function() {
            if(ticketRef){
                setLoadTicket(true)
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/commande/get_by_reference/"+ticketRef
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                    }
                    else{ 
                        setTicketPdf(response.data.data)
                        
                        var total = 0
                        let totaltgc = {}
                        response.data.data.ticket.ticket_detail.map(item=>{
                            total+=item.prix_remise
                            if(item.tgc!=0){//on exécute pas cette action sur le taux 0
                                if(totaltgc[item.tgc]){
                                    //si le taux de tgc existe deja on ajoute le pris du produit au total existant
                                    totaltgc={...totaltgc,[item.tgc]:totaltgc[item.tgc]+=item.prix_remise}
                                }else{
                                    //Si aucun taux existe on ajoute la taxe 
                                    totaltgc={...totaltgc,[item.tgc]:item.prix_remise}
                                }
                                
                            }
                        })
                        setTotalAchat(total)
                        setTotalTgc(totaltgc)
                        setLoadTicket(false)

                        if(raisonPrint=='print'){
                            epson_tm_h6000VOrder('print',response.data.data.ticket.section_id,response.data.data,t,props.devise,peripheriqueParam,false,authParam)
                        }
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    swal.fire(t('unknowError'), "", "error");
                })
                setLoadTicket(false)

                if(raisonPrint=='pdf'){
                    setTimeout(()=>{
                        btnPdf.current.click()
                    },1000)
                }
            }
        })()
    }, [ticketRef,relance])    
    
    let tgcForTicketPdf={}
    Object.entries(totalTgc).forEach(([tgcName,montant])=>{
        //fonction qui calcule le montant de tgc à payer par type de tgc
        let coef = +tgcName / 100 + 1
        let totalAmountTgc = +montant - (Math.round(+montant / +coef))
        tgcForTicketPdf[tgcName] = totalAmountTgc
    })
    //on calcule le total de tgc payé sur le ticket
    let tgcTotal = 0 
    Object.entries(tgcForTicketPdf).map(([key,value])=> 
        tgcTotal+=value
    )

    const handleSearch = (e,declenchement) => {
        setValueInputClient(e.target.value)
        if(e.target.value.length>=declenchement){
            dataListDropClient.current.classList.remove("d-none");

            if(!lastSearch || e.target.value.substring(0, declenchement) != lastSearch.substring(0, declenchement)){
                loadClients(e.target.value.substring(0, declenchement))
                setLastSearch(e.target.value.substring(0, declenchement))
            }
        }else{
            dataListDropClient.current.classList.add("d-none");
            setLastSearch(false)
        }
        if(e.target.value.length!=declenchement){
            let ajour = listSearchClient.filter((value)=>value.name.toLowerCase().includes(e.target.value.toLowerCase()))
            setDatas(ajour)
        }
    }

    const handleCLick = (id,valeur) => {
        setValueInputClient(valeur)
        setClientId(id)
        dataListDropClient.current.classList.add("d-none");
        setDatas([]) 
        loadAllTickets(id,currentSectionData)
    }

    const handleChange = (e) =>{
        setCurrentSectionData(e.target.value)
        loadAllTickets(clientId,e.target.value)
    }

    const loadAllTickets = async (clientId,shopId) =>{
        setLoad({...load,allTicket:true})

        const response = await axios.get(process.env.REACT_APP_API_URL+"api/client/get_histo_commande/"+clientId+'/'+currentPage+"/"+perPage+'/'+shopId
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                setAllSalesOrder(response.data.datas)
                setSizeDatas(response.data.count)
                setLoad({...load,allTicket:false})
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoad({...load,allTicket:false})
    }

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={ text }
            role="presentation"
            className="dropdown-item"
            onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
            } }
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'black' } }
            >
                { text }
            </a>
        </li>
    );

    let paginationOptions = {
        page:currentPage,
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        sizePerPageOptionRenderer,
        sizePerPage: perPage,
        showTotal: true,
        totalSize: sizeDatas
    };

    let dataTable = new Array
    allSalesOrder.length!=0 && allSalesOrder.map((value,ind)=>{
        let modeList =''
        value.ticket.paiement_records.length!=0 && value.ticket.paiement_records.map((item,index)=>{
            let modeName = modePaiementMustBeTranslate.includes(item.mode) ? t(item.mode) : item.mode
            if(index==0){
                modeList += modeName+': '+espace_mille(item.montant)+devise
            }else{
                modeList += ' / '+modeName+': '+espace_mille(item.montant)+devise
            }
            
        })
        let obj = {
            ticket: <span className="d-flex"><span>{value.bar}</span>&nbsp;&nbsp;<span style={{width:'100%', textAlign:'end'}}><BtnCopyClipBoard icon='true' textToCopy={value.bar} /></span></span>,
            client: (value.ticket.client!=null) ? value.ticket.client.name+' '+value.ticket.client.firstname : '',
            originShop:value.ticket.section.name,
            collectZone: (value.section_collect!=null  ) ? value.section_collect.name : '',
            dateCreation: <Moment format="DD/MM/YYYY HH:mm">{value.ticket.created_at}</Moment>,
            dateCollect: (value.prevu!=null) ? <Moment format="DD/MM/YYYY HH:mm">{value.prevu}</Moment> : '',
            id: value.id,
            cancel:value.cancel,
            status:value.ticket.status,
            statut: (value.ticket.status>0) ? t('finalized') : (value.cancel>0) ? t('canceled') : t('enCours'),
            mode_pay: modeList,
            action: 
                <span className="d-flex justify-content-between">
                    <PrintTicketButton setRelance={setRelance} setRaisonPrint={setRaisonPrint} setTicketRef={setTicketRef} ticketRef={value.bar}/>
                    <ViewPdfButton title={t('btnSeePdf')} setRelance={setRelance} setRaisonPrint={setRaisonPrint} setTicketRef={setTicketRef} ticketRef={value.bar} />
                    <ChangePaymentMethodBtn setShow={setShow} ticketId={value.bar} setBarTicket={setBarTicket}/>
                    <ReOpenSaleOrderBtn commandeId={value.id} allCommande={allSalesOrder} setAllCommande={setAllSalesOrder} devise={devise} digit={digit} posid={posid} from='histoClient'/>
                </span>,
            index: ind + 1,
        }
        dataTable.push(obj)
    })

    let defaultSorted = [
        {
          dataField: "date",
          order: "desc"
        }
    ];

    let columns = [
        {
            dataField: "action",
            text: 'Action',
            editable: false,
            headerClasses: 'head-action-row',
            classes: 'body-action-row',
        },
        {
            dataField: "masque",
            text: '',
            editable: false,
            formatter: (rowContent, row) => {
                return (    
                    <div style={{display:"block",width:"82px"}}></div>
                )
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    width:'99px',
                    backgroundColor:'white'
                };
            },
        },
        {
            dataField: "ticket",
            text: t('salesOrder')+' n°',
            editable: false,
        },
        {
            dataField: "client",
            text: t('customer'),
            editable: false,
        },
        {
            dataField: "originShop",
            text: t('origin_section'),
            editable: false,
        },
        {
            dataField: "collectZone",
            text: t('collectZone'),
            editable: false,
        },
        {
            dataField: "dateCreation",
            text: t('createdAt'),
            editable: false,
        },
        {
            dataField: "dateCollect",
            text: t('expectedCollectDate'),
            editable: false,
        },
        {
            dataField: "mode_pay",
            text: t('paymentDetail'),
            editable: false,
        },
        {
            dataField: "statut",
            text: t('status'),
            editable: false,
        },
    ];

    useEffect(function () {
        let columnCookie
        let arrCol = []
            
        if(cookies.search_histo_commande_reorganize){
            let newValueToIncludeInCookie
            let cookieLecture = cookies.search_histo_commande_reorganize.split(',')

            let already_done=[];
            cookieLecture.map(value=>{
                columns.map(item=>{
                    if(item.dataField==value){
                        arrCol.push(item)
                        already_done.push(value);
                    }
                })
            })
            columns.map(item=>{
                if(!already_done.includes(item.dataField)){ 
                    //si une nouvelle à été ajouté aux customsField, elle n'est pas ajoutée desuite au cookie de reorganisation si celui ci existe
                    //dans le cas où il n'existerait pas, on va créer un nouveau cookie afin de l'inclure pour ne pas avoir d'erreur sur les export 
                    newValueToIncludeInCookie=true
                    arrCol.push(item);
                }
            })

            columnCookie = arrCol
            if(newValueToIncludeInCookie==true){ 
                let arr = []
                arrCol.map(value=>
                    arr.push(value.dataField)
                )
                setCookie("search_histo_commande_reorganize",arr.join(),{ path: "/", expires: expire_date })
            }
        }else{
            columnCookie = columns
        } 
        setColumnOrganize(columnCookie)
    }, [])

    const handleTableChange = async (type, { page, sizePerPage }) => {
        setPerPage(sizePerPage)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/client/get_histo_commande/"+clientId+'/'+page+"/"+sizePerPage+'/'+currentSectionData,
        ).then((response) => {
            setAllSalesOrder(response.data.datas)
            setSizeDatas(response.data.count)
            setCurrentPage(page)
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
    }

    const rowStyle = (row, rowIndex) => {
        const style = {};

        if(rowIndex!=0){
            if (row.cancel != 0) {
            style.backgroundColor = '#fccfcf';//annulé

            }else if(row.status != 0){
            style.backgroundColor = '#d9fcd2';//finalisé

            }else{
                style.backgroundColor = '#ffffbb';//en cours
            }
        }
        return style;
    };

    return(<>
        <Modal size="md" show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t('editPaymentMethod')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalChangePaymentMethod 
                    barTicket={barTicket}
                    currentShop={thisShop}
                    devise={props.devise}
                    digit={props.digit}
                    posid={posid}
                    dataTicket={allSalesOrder}
                    setDataTicket={setAllSalesOrder}
                    from='commande'
                    module='pos'
                />
            </Modal.Body>
        </Modal>
        <BlobProvider 
            document={(Object.keys(ticketPdf).length!=0 && tgcTotal!=NaN) ?
                <TicketCommande 
                    ticketPdf={ticketPdf}
                    thisShop={thisShop}
                    t={t}
                    devise={props.devise}
                    digit={props.digit}
                    totalAchat={totalAchat}
                    tgcForTicketPdf={tgcForTicketPdf}
                    tgcTotal={tgcTotal}
                />
            : 
                false
            }
        >
            {({ url }) => (
                <a ref={btnPdf} className="d-none" href={url} target="_blank">Print</a>
            )}
        </BlobProvider>
        <div className="row d-flex justify-content-center mt-4 mb-4 position-relative">
            <div className="col-8">
                <InpDataList
                    placeholder={t('customerPlacehlder')}
                    value={valueInputClient}
                    onClick={handleCLick}
                    onChange={handleSearch}
                    datas={datas}
                    declenchement={3}
                    listRef={dataListDropClient}
                    inputRef={searchInputClient}
                />
            </div>
            {(loadingSearchClient) ? <span><div className="spinner-border spinner-border-sm historiqueGiftCardPosition"></div></span> : false }
        </div>
        {(otherCompanyId.length!=0 && clientId) &&
            <div className="row d-flex justify-content-center mt-4 mb-4 position-relative">
                <div className="form-group col-8">
                    <label htmlFor="Reason">{t('shop')}</label>
                    <select disabled={load.allTicket} className="form-control" onChange={(e)=>handleChange(e)}>
                        <option value={thisSection.id}>{thisSection.name}</option>
                        {allSections.map(value=>
                            value.other_company.map(item=>{
                                if(otherCompanyId.includes(item.id) && value.id!=posid){
                                    return(
                                        <option key={value.id} value={value.id} >{value.name}</option>
                                    )
                                }
                            })
                        )}                  
                    </select>
                </div>
            </div>
        }
        <CardPerso saClass='position-relative'>
            <div className="mb-2">
                <ColumDataTableReorganize 
                    columnOrganize={columnOrganize}
                    setColumnOrganize={setColumnOrganize}
                    setCookie={setCookie}
                    cookieName='search_histo_commande_reorganize'
                    showColumnOrganize={showColumnOrganize}
                    setShowColumnOrganize={setShowColumnOrganize}
                    btnSwitch={false}//pour affiche ou non le btn swith
                    initSwith={true}//initialier à false il affichera par défaut le toggle des colonnes, sur true il affichera le drag and drop pour changer la position des colonnes
                />
            </div>
            {(loadTicket) ? 
                <div className="loaderPdfHistorique">
                    <span>{(raisonPrint=='pdf') ? t('downloadingPdf') : t('printingInProgress')}</span>&nbsp;
                    <span>
                        <span className="spinner-grow spinner-grow-sm text-dark sizeDotLoad" role="status"></span> 
                        <span className="spinner-grow spinner-grow-sm text-dark sizeDotLoad" role="status"></span> 
                        <span className="spinner-grow spinner-grow-sm text-dark sizeDotLoad" role="status"></span> 
                    </span>
                </div>
            : 
                false
            }
            {(load.allTicket) ? 
                <div className="d-flex justify-content-center"><span className="spinner-border spinner-border-sm"></span></div> 
            :
                columnOrganize.length!=0 && 
                    <small>
                        <ToolkitProvider
                            keyField="index"
                            data={dataTable}
                            columns={ columnOrganize }
                        >
                            {(propst) => (
                                <div className="position-relative">
                                    <CustomDataTable
                                        propst={propst}
                                        paginationOptions={paginationOptions}
                                        handleTableChange={handleTableChange}
                                        load={load}
                                        defaultSorted={defaultSorted}
                                        rowStyle={rowStyle}
                                        columns={columns}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                    </small>
            }
        </CardPerso>
        </>
    )
    
}

export default SearchHistoriqueCommande;