import React, {useContext,lazy} from 'react';
import { IsAuthContext } from 'app/App';
import {dp_direction} from './dp_direction';

//ici on importe tous les masters
const CardMaster = lazy(() => import("./card/CardMaster"));
const InterdisMaster = lazy(() => import("./interdis/InterdisMaster"));
//end

const DevpersoMaster = (props) =>{
    const { authParam } = useContext(IsAuthContext);
    let redirect=false;

    if(dp_direction[authParam.company.dev_perso_ident]){
        if(dp_direction[authParam.company.dev_perso_ident][props.dp_direction]) redirect=true;
    }

    if(redirect){
        //liste des tous les masters
        let dossier_composant = dp_direction[authParam.company.dev_perso_ident][props.dp_direction].split('/');
        switch (dp_direction[authParam.company.dev_perso_ident].Master){
            case 'CardMaster':
                return(
                    <CardMaster 
                        authParam={authParam}
                        dossier={dossier_composant[0]}
                        composant={dossier_composant[1]}
                        {...props}
                    />
                )
            case 'InterdisMaster':
                return(
                    <InterdisMaster 
                        authParam={authParam}
                        dossier={dossier_composant[0]}
                        composant={dossier_composant[1]}
                        {...props}
                    />
                )
            default:
                return(<></>)
        }
        //end
    }
    else{
        return (<>
            {props.children}
        </>)
    }
};

export default DevpersoMaster;