import React, {useContext} from "react";
import { useTranslate } from 'react-redux-multilingual';
import { openTiroir } from "app/ouvertureTiroir";

const  OpenCashDrawerBtn = ({peripheriqueParam}) => {
    const t = useTranslate();

    return (
        <button 
            onClick={() => openTiroir(peripheriqueParam,t)}
            className='btn btn-sm btn-primary positionOpenCaisse'
        >
           {t('openTiroir')}
        </button>
    )
}

export default OpenCashDrawerBtn;