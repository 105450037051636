import React from "react";
import { useTranslate } from 'react-redux-multilingual';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

const PrintTicketButton = (props) => {
    const t = useTranslate();

    const callback = (ticketRef) =>{
        props.setRaisonPrint('print')
        props.setTicketRef(ticketRef)
        props.setRelance(Date.now())
    }

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip id={`tooltip-${props.index}`}>
                    {t('btnPrint')}
                </Tooltip>
            }
        >
            <span onClick={() => callback(props.ticketRef)} className='pointer text-primary'><FontAwesomeIcon icon={faPrint} /></span>
        </OverlayTrigger>  
    )
}

export default PrintTicketButton;