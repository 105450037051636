import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { Accordion } from "react-bootstrap";
import EachNotification from './EachNotification';

const TransferOrderNotifConf = (props) =>{
    const t = useTranslate();

    if(props.moduleAdmin.params.to.active){
        return (
            <Accordion className='mb-2'>
                <Accordion.Item eventKey='to'>
                    <Accordion.Header>
                        <span style={{color:props.notificationsType.to.bg, fontWeight:'bold',fontSize:'0.9rem'}}>
                            {t('to')}&nbsp;
                            <i style={{color:props.notificationsType.to.bg}} className={`${props.notificationsType.to.icon} header-icon fs-5`}></i>
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <>
                             <EachNotification  allUsers={props.allUsers} sectionId={props.sectionId} notifState={props.notifState} setNotifState={props.setNotifState} typeNotif="newTo" bgcolor="#ffffc3" disabled={props.disabled} setDisabled={props.setDisabled}/>
                        </>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        )
    }else{
        return(<></>)
    }
};

export default TransferOrderNotifConf;