import React,{useState} from "react";
import { useTranslate } from 'react-redux-multilingual';

const  GenerateReassortButton = (props) => {
   
    return (<>
        <button className="btn btn-dark btn-sm mb-2" onClick={()=>props.setShowModalReassort(true)}>{props.title}</button>
    </>)
}

export default GenerateReassortButton;