import React, {useState,useContext,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {ModalTagPrintForm} from "@gull";
import { PeripheriqueContext } from '../../../app/App';
import { Modal } from "react-bootstrap";
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { useCookies} from 'react-cookie';

const TagArticleBtn = (props) => {
    const t = useTranslate();
    const [showTagPrint,setShowTagPrint]=useState(false)
    const { peripheriqueParam } = useContext(PeripheriqueContext)  
    const [cookies] = useCookies(['peripheriqueTagErp']);

    const [paramPrinter,setParamPrinter] = useState(false)

    useEffect(function () {
        (async function() {
            if(props.from=='pos' && peripheriqueParam.hasOwnProperty('imprimante_etiquette')){
                //Si on est sur le pos, la configuration d'imprimante est deja configuré, donc on la renseigne de suite 
                //Sinon on laisse l'utilisateur erp définir l'imprimante sur laquelle imprimer
                setParamPrinter(peripheriqueParam.imprimante_etiquette)
            }
        })()
    }, [])

    //qteToPrint est une valeur passé pour le moment uniquement depuis les po, afin d'imprimer autant d'étiquette qu'il y a de poduit reçu
    let imprimanteType = (peripheriqueParam && peripheriqueParam.imprimante_etiquette) ? peripheriqueParam.imprimante_etiquette.model : paramPrinter ? paramPrinter.model : "image_file"
                                              //Vérification pour le POS                                                                Vérifiation pour l'ERP                                  //Par défaut on veut afficher le formulaire pour l'édition d'étiquette             
    return (<>
            <Modal size={imprimanteType!="image_file" ? "md" : "xl"} show={showTagPrint} onHide={() => setShowTagPrint(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('tagPrint')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalTagPrintForm 
                        articlePrice={props.articlePrice} 
                        articleName={props.articleName} 
                        articleUpc={props.articleUpc} 
                        qteToPrint={props.qteToPrint} 
                        from={props.from}
                        imprimanteType={imprimanteType}
                        articleSelected={props.article}

                        setParamPrinter={setParamPrinter}
                        paramPrinter={paramPrinter}
                    />
                </Modal.Body>
            </Modal>
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id={`tooltip-${props.index}`}>
                        {t('printItemTag')}
                    </Tooltip>
                }
            >
                <span onClick={() => setShowTagPrint(!showTagPrint)} className='pointer text-secondary'><FontAwesomeIcon icon={faTag} /></span>
            </OverlayTrigger> 
        </> 
    )
}

export default TagArticleBtn;