import React, { useState, useEffect } from "react";
import { useTranslate } from 'react-redux-multilingual'
import { useForm } from "react-hook-form";
import { CardPerso,SubmitForm,Loading} from "@gull";
import axios from 'axios'
import swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const useLoad = (module,setValue) => {
    const t = useTranslate();
    const [zones,setZones] = useState([]);
    const [loading,setLoading] = useState(false)
    const [oSection,setOSection]=useState(false)

    useEffect(function () {
        (async function() {
            if(module && module=='listeArticle' && oSection){
                setLoading(true)
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/zone/getall/"+oSection
                ).then((response) => {
                    //on récupère toutes les zones de stockage de type mobil
                    let carZone = response.data.datas.filter(value=>value.type=='mobil')

                    //si il n'y a qu'une seule zone de stockage de type mobil, on affiche pas le select et on passe à l'étape suivante avec la seule zone mobil existante
                    if(carZone.length==1){
                        setValue('zone',carZone[0].id)
                    }
                    setZones(carZone)
                    setLoading(false)
                }).catch((error) => {
                    if(error=='end_session') return;
                    swal.fire(t('unknowError'), "", "error");
                    setLoading(false)
                })
            }
        })()
    }, [oSection])

    return [zones,loading,setOSection];
}

const TransferOrderForm = (props) =>{
    const t = useTranslate();
    const { register, formState: { errors }, handleSubmit, setValue, reset, getValues} = useForm();
    const [zones,loading,setOSection] = useLoad(props.module,setValue)
    const [validButton,setValidButton] = useState(false)
    const [apiError,setApiError] = useState(false)
    const [success,setSuccess] = useState(false)
    const [staff,setStaff]=useState([])
    const history = useHistory();
    const [staffDisabled,setStaffDisabled]=useState(true)

    let editTO = (props.from  =='edit' && props.transferOrders) ? props.transferOrders.find(value=>value.id==props.modalEditId) : props.transferOrder;

    if(props.from==='edit' && props.transferOrders){
        //On détermine l'index à éditer sur la modal edit 
        let refTO = props.transferOrders.map(value=>value.id)
        var indexToEdit = refTO.indexOf(editTO.id)
    }

    useEffect(() => {
        if(props.from=='edit'){//on récupère les default value
            if(props.module=='pos'){
                //Sur le POS, si le lieux de collecte ne correspond pas au magasin dans lequel on est, on autorise pas le changement de staff
                if(editTO.osection.id == props.posid) setStaffDisabled(false)
            }else{
                setStaffDisabled(false)
            }

            const res = axios.get(process.env.REACT_APP_API_URL+"api/rh/get_all_of_section/"+editTO.osection.id
            ).then((response) => {
                setStaff(response.data.datas)
                if(editTO.rh) setValue('rh',editTO.rh_id);
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            })
            
            setValue('expected',editTO.expected.substring(0, 10))
            setValue('osection',editTO.osection.id)
            setValue('fsection',editTO.fsection.id)
            setValue('memo',(editTO.memo) ? editTO.memo.replace( /(<([^>]+)>)/ig, '') : '')
            props.customFields && props.customFields.forEach(value=>{
                setValue(value.name,editTO.custom_fields[value.name])
            })
        }else{
            setValue('expected',props.annee+'-'+props.moisFormat+'-'+props.jourFormat)
            if(props.module && props.module=='listeArticle'){ 
                setValue('osection',props.oSection)
                setOSection(props.oSection)
            }
        }
    }, [])

    const handleChange = (e,name) =>{
        if(name=='osection'){
            if(e.target.value!=''){
                const response = axios.get(process.env.REACT_APP_API_URL+"api/rh/get_all_of_section/"+e.target.value
                ).then((response) => {
                    setStaff(response.data.datas)
                    setStaffDisabled(false)
                }).catch((error) => {
                    if(error=='end_session') return;
                    swal.fire(t('unknowError'), "", "error");
                })
            }else{
                setStaffDisabled(true)
            }
        }
    }

    const onSubmit = async (data) => {
        setValidButton(true)
        setApiError(false)
        setSuccess(false)

        if(props.module && props.module=='listeArticle') data['article_list']=props.listeArticleId

        if(props.from=='edit'){
            const response  = await axios.put(process.env.REACT_APP_API_URL+"api/erp/to"+'/'+props.modalEditId,data
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach((interm)=>{
                        let finalMessage=interm.split('/!');
                        if(finalMessage.length==2){
                            messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                        }
                        else{
                            messageError+=t(interm)+'<br/>'
                        }
                    })
                        messageError=messageError.replace('undefined','');
                        setApiError(messageError)
                }
                else{
                    setSuccess(true)
                    if(props.from==='edit' && props.transferOrders){
                        //ici on édit une ligne du tableau des transfer order 
                        let newData = props.transferOrders.filter(value=>value.id!=props.modalEditId)
                        newData.splice(indexToEdit,0, response.data.to)
                        props.setTransferOrders(newData)

                    }else if(props.from==='edit' && props.transferOrder){
                        //ici on edit le tranfer order depuis sa page 'détail'
                        props.setTransferOrder(response.data.to);
                    }else{
                        //ici on crée un tranfer order
                        if(props.setTransferOrders) props.setTransferOrders([response.data.to,...props.transferOrders])
                        reset()
                        setValue('expected',props.annee+'-'+props.moisFormat+'-'+props.jourFormat)
                    }
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            })
        }else{
            const response  = await axios.post(process.env.REACT_APP_API_URL+"api/erp/to",data
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach((interm)=>{
                        let finalMessage=interm.split('/!');
                        if(finalMessage.length==2){
                            messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                        }
                        else{
                            messageError+=t(interm)+'<br/>'
                        }
                    })
                        messageError=messageError.replace('undefined','');
                        setApiError(messageError)
                }
                else{
                    setSuccess(true)
                    if(props.setTransferOrders) props.setTransferOrders([response.data.to,...props.transferOrders])
                    reset()
                    setValue('expected',props.annee+'-'+props.moisFormat+'-'+props.jourFormat)
                    if(props.module && props.module=='listeArticle'){
                        if(props.from=='erp'){
                            history.push("/erp/transfer-order/details/"+response.data.to.id)

                        }else if(props.from=='wms'){
                            history.push("/wms/"+response.data.to.osection_id+"/list-items")
                        }
                    }
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            })
        }
        setValidButton(false)
        setTimeout(()=>{
            setSuccess(false);
        },1000)
        
    }

    let title = props.from=='edit' ? t('transferOrderEdit') : t('createATransferOrder');

    let show_osection=true;
    let show_fsection=true;
    if(props.from=='edit'){
        if(editTO.picked_status>0 || editTO.zone_id!=null) show_osection=false;
        if(editTO.deposed_status>0 || editTO.fzone_id!=null) show_fsection=false;
    }

    return(
        <CardPerso header={title}>
            {loading ?
                <span className="d-flex justify-content-center"><span className="spinner-border spinner-border-sm"></span></span>
            :
                <form onSubmit={handleSubmit(onSubmit)}>
                    {(!props.module || (props.module && props.module!='listeArticle')) &&
                        <div className="form-group">
                            <label htmlFor="date de transfert">{t('transfert_le')+' :'}</label>
                            <input  type='date' className="form-control" {...register('expected', {required: true})}/>
                            {errors.expected?.type === 'required' && (
                            <div className="text-danger mt-1 ml-2">
                                {t('expectedRequired')}
                            </div>
                            )}
                        </div>
                    }
                    {(show_osection) &&
                        <div className="form-group">
                            <label htmlFor="commerce d'origine">{t('origin_section')+' :'}</label>
                            <select disabled={props.module && (props.module=='pos' || props.module=='listeArticle')} className="form-control" type="text" {...register('osection', {required: true, onChange:(e)=>handleChange(e,'osection')})} >
                                <option value="">-- Select --</option>
                                {(props.section.length!=0) && props.section.map((value)=>{
                                    if(value.type!='ecommerce') return(<option key={value.id} value={value.id}>{value.name}</option>)
                                })}                                    
                            </select>
                            {errors.osection?.type === 'required' && (
                            <div className="text-danger mt-1 ml-2">
                                {t('osectionRequired')}
                            </div>
                            )}
                        </div>
                    }
                    {(show_fsection) &&
                        <div className="form-group">
                            <label htmlFor="commerce de destination">{t('destination_section')+' :'}</label>
                            <select disabled={props.module && props.module=='pos'} className="form-control" type="text" {...register('fsection', {required: true})}>
                                <option value="">-- Select --</option>
                                {(props.section.length!=0) && props.section.map((value)=>{
                                    if(value.type!='ecommerce') return(<option key={value.id} value={value.id}>{value.name}</option>)
                                })}                                    
                            </select>
                            {errors.fsection?.type === 'required' && (
                            <div className="text-danger mt-1 ml-2">
                                {t('fsectionRequired')}
                            </div>
                            )}
                        </div>
                    }
                    {(props.module && props.module=='listeArticle') &&
                        <div className="form-group">
                            <label htmlFor="commerce de destination">{t('chosseMobile')+' :'}</label>
                        <select disabled={zones.length==1} className="form-control" {...register('zone', {required: true})}>
                            <option value="">{t('choisir')}</option>
                            {zones.map(value=>{
                                return(
                                    <option value={value.id} key={value.id}>{value.name}</option>
                                )})
                        }
                        </select>
                        {errors.fsection?.zone === 'required' && (
                            <div className="text-danger mt-1 ml-2">
                                {t('fieldRequired')}
                            </div>
                        )}
                        </div>
                    }
                    <div className="form-group">
                        <label htmlFor="staff">{t('rh')+' :'}</label>
                        <select className="form-control" disabled={staffDisabled} type="text" {...register('rh')}>
                            <option value="">-- Select --</option>
                            {(staff.length!=0) && staff.map((value)=>
                                <option key={value.id} value={value.id}>{value.name+' '+value.firstname}</option>
                            )}                                    
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="memo">Memo :</label>
                        <textarea rows={5} className="form-control" {...register('memo')}/>
                    </div>                        
                    {(props.customFields.length!=0) && props.customFields.map((item)=>(
                        <div className="form-group" key={item.id}>
                            <label htmlFor={item.name}>{item.name}{(item.required) ? '*' : false}</label>
                            {(item.free) ?
                                <>
                                    <input className="form-control" type="text" {...register(item.name,{required:item.required})}/>
                                    {errors[item.name]?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('youMustIndicateField')+' "'+item.name+'"'}
                                    </div>
                                    )}
                                </>
                                :
                                <>
                                    <select className="form-control" {...register(item.name,{required:item.required})} >
                                        <option value="">-- Select --</option>
                                        {item.list.map((value,index)=>
                                            <option key={index} value={value}>{value}</option>
                                        )}
                                    </select>
                                    {errors[item.name]?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('youMustIndicateField')+' "'+item.name+'"'}
                                    </div>
                                    )}
                                </>
                            }
                        </div>
                    ))}
                    <SubmitForm error={apiError} success={success} validButton={validButton}/>
                </form>
            }
        </CardPerso>
    )
}

export default TransferOrderForm;