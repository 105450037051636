import React, {useState} from 'react';
import axios from "axios";
import { useTranslate } from 'react-redux-multilingual'
import { CardPerso,AdjustmentStockModal,ModalStockDetails,ModalRestockToComeDetails,ModalContainOrderDetails} from "@gull";
import swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const ModalContain = (props) => {
    return(<>
        {(props.modalIsFor==='stock') && <ModalStockDetails inSection={props.inSection} articleid={props.articleid} />}
        {(props.modalIsFor==='order') && <ModalContainOrderDetails inSection={props.inSection} articleid={props.articleid} />}
        {(props.modalIsFor==='restock') && <ModalRestockToComeDetails inSection={props.inSection} articleid={props.articleid} from='erp'/>}
    </>)
}

const TableCompanyStockDetail = (props) => {
    const t = useTranslate();
    const [showModal,setShowModal]=useState(false);
    const [modalTitle,setModalTitle]=useState('');
    const [inSection,setInSection]=useState(false)
    const [modalIsFor,setModalIsFor]=useState(false)
    const [showReorganization,setShowReorganization]=useState(false)

    const showDetails = (sectionid,title) => {
        setModalIsFor(false);
        setInSection(false);
        setModalTitle(title)
        setShowModal(true);
        const response = axios.get(process.env.REACT_APP_API_URL+"api/erp/article/stock_in_section/"+props.articleid+"/"+sectionid
        ).then((response) => {
            setInSection(response.data)
            setModalIsFor('stock');
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        });
    }

    const toComeModal = () => {
        setModalIsFor(false);
        setInSection(false);
        setModalTitle(t('onOrder'));
        setShowModal(true);
        const response = axios.get(process.env.REACT_APP_API_URL+"api/erp/article/on_order_detail/"+props.articleid
        ).then((response) => {
            setInSection(response.data.details)
            setModalIsFor('order');
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        });
    }

    const restockToComeModal = (sectionid) => {
        setModalIsFor(false);
        setInSection(false);
        setModalTitle(t('reassortEnCours'));
        setShowModal(true);
        const response = axios.get(process.env.REACT_APP_API_URL+"api/erp/article/current_to/"+props.articleid+'/'+sectionid
        ).then((response) => {
            setInSection(response.data.details_to)
            setModalIsFor('restock');
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        });
    }

    if(props.loadStock){
        return(<div className='mt-2 d-flex justify-content-center'>
            <div className="spinner-border spinner-border-sm"></div>
        </div>)
    }

    return(<>
        <Modal size="lg" show={showReorganization} onHide={() => setShowReorganization(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t('stockAdjustment')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AdjustmentStockModal articleid={props.articleid} setReadjustReload={props.setReadjustReload} from='erp'/>
            </Modal.Body>
        </Modal>
        <Modal size="lg" show={showModal && props.showDetailInModal=='true'} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(!inSection) ?
                    <div className="col-12 text-center"><div className="spinner-border spinner-border-sm"></div></div>
                    :
                    <ModalContain inSection={inSection} articleid={props.articleid} modalIsFor={modalIsFor}/>
                }
            </Modal.Body>
        </Modal>
        <CardPerso header={'Stock'}>
            <div className="row mb-3">
                <div className="col-md-4">
                    <h4>Total: {props.stock.total}</h4>
                </div>
                <div className="col-md-4">
                    <h4>{t('onOrder')}: <span className='lien-quantite' onClick={() => toComeModal()}>{props.stock.sum_toarrive}</span></h4>
                </div>
                <div className="col-md-4 text-end">
                    {(props.article && props.article.parent_name!=null && props.article.parent_name!='') &&
                        <Link className='btn btn-info' style={{marginRight:'1.5em'}} to={'/erp/automatic-restock/parent-item/'+props.articleid}>
                            {t('btnSeeParentProfil')}
                        </Link>
                    }
                    {props.reajustement=='true' &&
                        <button 
                            className='btn btn-dark'
                            onClick={()=>setShowReorganization(true)}
                        >
                            {t('stockAdjustment')}
                        </button>
                    }
                </div>
            </div>
            
            <div className="table-responsive">
                <table
                id="stock_table"
                className="table"
                >
                    <thead>
                        <tr>
                            <th scope="col">{t('shops')}</th>
                            <th scope="col">{t('quantityDisp')}</th>
                            <th scope="col">{t('quantityBloque')}</th>
                            <th scope="col">{t('quantityTotal')}</th>
                            <th scope="col">{t('reassortEnCours')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(props.stock.by_section).map(([keyName, value], ind) =>{
                                return(
                                    <tr key={ind} className={(value.total>0) ? 'alert-success' : (value.total<0) ? 'alert-danger' : '#ffffff'} >
                                        <td>{keyName}</td>
                                        <td>{value.disponible}</td>
                                        <td>{value.non_disponible}</td>
                                        <td>{props.clickQteTotal=='true' ? <span className='lien-quantite' onClick={()=>showDetails(value.sectionid,keyName)}>{value.total}</span> : <span>{value.total}</span>}</td>
                                        <td><span className='lien-quantite' onClick={()=>restockToComeModal(value.sectionid)}>{value.to}</span></td>
                                    </tr>
                                )
                            })
                        }                            
                    </tbody>
                </table>
            </div>
        </CardPerso>
        {showModal && props.showDetailInModal=='false' &&
            <div className='mt-2'>
                <CardPerso header={<span className='d-flex justify-content-between'>{modalTitle} <FontAwesomeIcon className='pointer' icon={faTimesCircle} onClick={()=>setShowModal(false)} /></span>}>
                    {
                        (!inSection) ?
                            <div className="col-12 text-center"><div className="spinner-border spinner-border-sm"></div></div>
                        :
                            <ModalContain inSection={inSection} articleid={props.articleid} modalIsFor={modalIsFor}/>
                    }
                </CardPerso>
            </div>
        }
    </>)
}

export default TableCompanyStockDetail;