import React, { useState, useRef } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {ArticleBarSearch} from "@gull";
import axios from 'axios'
import swal from "sweetalert2";
import arrowUpImg from 'assets/arrowUp.png';
import arrowDownImg from 'assets/arrowDown.png';

const ConfigurationMainEtiquette = (props) => {
    const t = useTranslate();
    const [loadingSearchItem,setLoadingSearchItem]=useState(false)
    const [listSearchItem,setListSearchItem]=useState([])
    const [datasItem,setDatasItem] = useState(listSearchItem)
    const searchInput = useRef('')
    const [inputScan,setInputScan]=useState('')
    
    const arrowUpImage = new window.Image();
    arrowUpImage.src = arrowUpImg; // Chemin de l'image importée
    const arrowDownImage = new window.Image();
    arrowDownImage.src = arrowDownImg; // Chemin de l'image importée

    const loadItems = async (inputValue) => {//pour l'input data des articles
        setLoadingSearchItem(true)
        
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/article/name/"+encodeURIComponent(inputValue)
        ).then((response) => {
            setLoadingSearchItem(false)
            setListSearchItem(response.data.datas)
            let ajour = response.data.datas.filter((value)=>value.name.replace(/\u00A0/g,' ').toLowerCase().includes(searchInput.current.value.replace(/\u00A0/g,' ').toLowerCase()))
            setDatasItem(ajour)
        }).catch((error) => {
            if(error=='end_session') return;
            setLoadingSearchItem(false)
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
    }

    const addNewItem = async (e,articleid,inputname,articleBar) =>{//selection l'article dans l'input data
        setLoadingSearchItem(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/article/one/"+articleid
        ).then((response) => {
            if(response.data.hasOwnProperty('valid') && !response.data.valid){
                swal.fire(t('articleNoReferenceFind'), "", "error");
            }else{ 
                props.setArticle(response.data.data)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
        setLoadingSearchItem(false)
    }

    return (
        <div className="row mb-3">
            <label>{t('tagSize')}</label>
            <div className="row">
                <div className="col-lg-4">
                    <label>{t('width')}</label>
                    <div className="input-group">
                        <input disabled={props.validSize} className="form-control" type="number" value={props.canvasCm.width} onChange={(e) => props.handleEditSize(e,'width')}/>
                        <div className="input-group-append">
                            <span className="input-group-text" id="basic-addon2">
                                cm
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <label>{t('height')}</label>
                    <div className="input-group">
                        <input disabled={props.validSize} className="form-control" type="number" value={props.canvasCm.height} onChange={(e) => props.handleEditSize(e,'height')}/>
                        <div className="input-group-append">
                            <span className="input-group-text" id="basic-addon2">
                                cm
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <label>{t('item')}: {(props.article) ? props.article.name : ''} </label>
                    <ArticleBarSearch
                        loadingSearchItem={loadingSearchItem}
                        loadItems={loadItems}
                        listSearchItem={listSearchItem}
                        datas={datasItem}
                        setDatas={setDatasItem}
                        valueInput={inputScan}
                        setValueInput={setInputScan}
                        handleSubmit={addNewItem}
                        searchInput={searchInput}
                        noBlur={false}
                        disabled={props.validSize}
                        setArticle={props.setArticle}
                        disabledDeleteBtn={props.validSize}
                    />
                </div>
                <div className="col-lg-4">
                    <button disabled={!props.sizeOk} className="btn btn-primary" onClick={() => props.setValidSize(!props.validSize)}>{(props.validSize) ? t('edit') : t('btnValidate')}</button>
                </div>
            </div>
        </div>
    );
};

export default ConfigurationMainEtiquette;
