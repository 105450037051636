import React, {useState,useEffect,lazy,Suspense} from 'react';
import {Loading} from '@gull'
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios';
import swal from "sweetalert2";
import { Modal } from "react-bootstrap";

const ConvertPoIntoInvoiceBuyForm = lazy(() => import('../../../@gull/components/form/ConvertPoIntoInvoiceBuyForm'));

const BtnGenerateInvoiceBuy = (props) => {
    const t = useTranslate();
    const [showForm,setShowForm] = useState(false)

    return(<>
        <Modal show={showForm} onHide={() => setShowForm(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t('convertIntoBillingBuy')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Suspense fallback={<Loading></Loading>}>
                    <ConvertPoIntoInvoiceBuyForm 
                        po={props.po} 
                        allDevises={props.allDevises} 
                        poId={props.poId}
                    />
                </Suspense>
            </Modal.Body>
        </Modal>
        <button className='btn btn-sm btn-info' onClick={()=>setShowForm(true)}>
            {t('convertIntoBillingBuy')}
        </button>
    </>
    )
}

export default BtnGenerateInvoiceBuy;